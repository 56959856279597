.IntegrationsPage {
  .AuthenticationSection,
  .CalendarsSection,
  .MessagingSection,
  .PeopleSection,
  .SearchSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @include up-to-large-size {
      grid-template-columns: 1fr;
    }

    .full-width {
      grid-column-start: 1;
      grid-column-end: -1;
    }

    .btn-narrow {
      height: 2.143rem;
      min-width: 100px;
    }
  }
}
