.DropdownButton {
  .dropdown-menu {
    padding: 0;
    border: none;
    box-shadow: var(--card-box-shadow);
    max-height: 225px; // 4.5 items
    overflow-y: auto;
  }
}

.DropdownItem {
  &.dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;

    &.active, &:active, &:hover {
      color: var(--text-color-primary);
      background-color: var(--dropdown-hover-background-color);
    }
  }
}
