div.JourneyBlueprintEditorPage,
div.JourneyBlueprintListPage {
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .PillTab {
      font-size: 15px;
    }
  }

  .flex-row-responsive-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;

    @include medium-size {
      flex-direction: column;
      align-items: flex-start;
    }

    @include small-size {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .AudienceSummaryText {
    min-height: 28px;
  }

  div.blueprint-empty-list-container {
    box-shadow: var(--admin-header-box-shadow);

    button {
      max-width: 250px;
    }

    .BackpackerIcon {
      svg {
        width: 120px;
        height: auto;
      }
    }
  }

  header.AdminHeader {
    span.EditableContent {
      flex-grow: 1;
      width: 100%;

      input {
        border: 0;
        border-bottom: 1px solid var(--border-color);
        padding: 0;
        font-size: var(--h3-font-size);
        font-weight: 700;
        width: 100%;
      }
    }

    .Toolbar-link {
      height: 2.429rem;
    }
  }

  .JourneyBlueprintsTable {
    table-layout: auto;
    width: 100%;

    tbody {
      tr {
        td:first-child{
          width: 40%;
        }

        td:last-child {
          width: 5%;
        }
      }
    }
  }
}
