.ClearyVideoPlayer {
  .encoding-container {
    background-color: var(--editor-toolbar-background-color);
    border: 1px solid var(--border-color);
    padding-left: 10%;
    padding-right: 10%;
    overflow: hidden;

    &.encodingFailed {
      border-color: var(--danger-color);
    }

    .VideoIcon {
      min-height: 50px;
      min-width: 50px;
      margin-right: 5%;
    }

    .ProgressBar {
      position: absolute;
      bottom: 0;
    }
  }

  video {
    background-color: var(--black);
  }

  &.isEditing {
    video {
      position: relative;
      z-index: $zindex-resize-square;
    }
  }
}
