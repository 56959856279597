.PictureList {
  --profile-picture-list-dimension: 66px;
  --profile-picture-list-dimension-mobile: 100px;

  min-height: var(--profile-picture-list-dimension);

  display: grid;
  grid-column: 1 / span 3;
  grid-row: 4 / span 1;
  justify-content: center;

  & > div {
    display: grid;
    grid-template-columns: repeat(3, var(--profile-picture-list-dimension));
    grid-gap: 12px 12px;
  }

  .CirclesLoadingIndicator {
    min-height: var(--profile-picture-list-dimension);
    top: 60%;
  }

  .Picture {
    & > div {
      width: var(--profile-picture-list-dimension);
      height: var(--profile-picture-list-dimension);
    }

    .RemoveProfilePicture {
      width: 14px;
      height: 14px;
      position: relative;
      top: -10px;
      right: -3px;
    }
  }

  .ViewEditToggle {
    .View {
      svg {
        height: 20px;
        width: 20px;

        path {
          fill: var(--link-color);
          stroke: none;
        }
      }
    }
  }

  @include small-size {
    min-height: var(--profile-picture-list-dimension-mobile);
    grid-column: 1 / span 12;
    grid-row: 6;
    border-top: 1px solid var(--border-color);
    margin: 0 !important;
    padding-top: 2rem;

    & > div {
      grid-template-columns: repeat(3, 1fr);
    }

    .AddProfilePicture {
      height: var(--profile-picture-list-dimension-mobile);

      & > div {
        width: var(--profile-picture-list-dimension-mobile);
        height: var(--profile-picture-list-dimension-mobile);
      }
    }

    .Picture {
      & > div {
        width: var(--profile-picture-list-dimension-mobile);
        height: auto;
      }

      .Image {
        width: var(--profile-picture-list-dimension-mobile);
        height: var(--profile-picture-list-dimension-mobile);
      }
    }
  }
}
