.Navbar {
  color: var(--nav-text-color);
  box-shadow: var(--nav-box-shadow);

  a {
    color: var(--nav-text-color);

    &:hover {
      color: var(--nav-text-active-color);
    }
  }

  nav {
    display: flex;
  }

  @media print {
    display: none;
  }

  &.isHidden {
    z-index: -1;
    height: 60px;
    opacity: 0;
  }
}

.Navbar-companyImage {
  img {
    height: var(--logo-height);
    width: var(--logo-width);
  }
}

.Navbar-companyName {
  color: var(--nav-text-color);
}

.Navbar-primary,
.Navbar-subnav {
  background-color: var(--nav-color);
  height: var(--nav-height);
  display: flex;
  align-items: center;
}

.Navbar-mobile {
  background-color: var(--subnav-color);

  &.custom-logo {
    opacity: 0.9;
  }

  position: absolute;
  z-index: $zindex-popover;
  width: 100%;

  .Navbar-item {
    a {
      color: var(--subnav-text-color) !important;
      &.active {
        color: var(--subnav-text-active-color) !important;
      }
    }
  }

  .Navbar-search {
    width: 100%;
  }
}

.Navbar-item {
  margin-right: 1rem;
  text-transform: var(--nav-text-transform);

  a {
    font-weight: 500;

    &.active {
      color: var(--nav-text-active-color);

      .Navbar-subnav & {
        a {
          color: var(--subnav-text-active-color);
        }
      }
    }
  }
}

.Navbar-personal {
  text-align: right;
  color: var(--nav-text-color);
}

.Navbar-search {
  width: 380px;

  .Select-input {
    height: 43px;
  }

  .Select-placeholder {
    line-height: 43px;
  }
}

.Navbar-subnav {
  background-color: var(--subnav-color);

  a.active {
    color: var(--subnav-text-active-color);
  }

  a {
    color: var(--subnav-text-color);

    &:hover {
      color: var(--subnav-text-active-color);
    }
  }
}

.Navbar-navItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 60px;
  &:hover {
    cursor: pointer;

    /* prettier-ignore */
    background-color: var(--dynamic-color-navbar-item--nav-color); //color(var(--nav-color) contrast(10%) blend(var(--nav-color) 70%));
  }

  svg {
    path {
      fill: var(--nav-text-color) !important;
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--nav-text-active-color) !important;
      }
    }
  }

  .PersonIcon {
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .Navbar-navItemIcon {
    padding: 0.25rem;
    border-radius: 8px;
  }

  &.isActive {
    .Navbar-navItemIcon {
      background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);
    }

    svg {
      path {
        fill: var(--highlight-color) !important;
      }
    }
  }
}

.Navbar-profileImage {
  height: 40px !important;
  width: 40px !important;
  border-radius: var(--profile-image-border-radius);
}

.MobileNavLink-active {
  display: flex;
  align-items: center;
  color: var(--nav-text-color);
  text-transform: var(--nav-text-transform);

  &:hover {
    cursor: pointer;
  }
}

.Navbar-dropdownMenuItem {
  // This width is enough to fit the "Company Update" link in one line in english,
  // for every other language, we will just let it break into multiple lines.
  width: 11em;
  background-color: var(--nav-color);
  display: block;

  &:hover {
    /* prettier-ignore */
    background-color: var(--dynamic-color-navbar-item--nav-color); //color(var(--nav-color) contrast(10%) blend(var(--nav-color) 70%));
  }
}
