.ReactionsWidget {
  .AddReaction {
    @extend .pointer, .bordered;

    padding: 0px 5px;
    border-radius: var(--square-border-radius-large);

    &:hover {
      background-color: var(--secondary-gray);

      svg {
        path {
          fill: var(--highlight-color);
        }
      }
    }
  }

  .hasReacted {
    border: 1px solid var(--highlight-color);
    background-color: var(--lightest-gray);
  }
}
