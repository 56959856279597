.AudienceSelector {
  .AudienceSummary {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .InfoIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-secondary);

      &:hover {
        fill: var(--link-color);
        cursor: pointer;
      }
    }
  }
}

.RuleSelect {
  .PlusIcon {
    svg {
      width: 13px;
    }
  }
}
