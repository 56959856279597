button.ToggleLeftNavButton {
  z-index: $zindex-dropdown !important;
  width: 24px;
  height: 24px;
  // position the toggle nav button in the top left corner of the page
  position: absolute;
  top: 24px; // align with the center of the admin pages header
  left: 10px;
  border: 0;
  border-radius: 12px;
  background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left 0.5s ease-in-out;

  &:focus {
    outline: 0;
  }

  &.isOpen {
    // when the nav is open - move the button the edge of the nav
    left: calc(var(--left-nav-width) - 10px);

    svg {
      transform: none;
    }
  }

  svg {
    fill: var(--link-color);
    height: 16px;
    width: 16px;
    transform: rotate(180deg);
    display: block;
    margin: auto;
  }

  @include small-size {
    display: none;
  }

  @media print {
    display: none;
  }

  &.useNewUi {
    z-index: $zindex-popover !important;
    width: 28px;
    height: 28px;
    border-radius: var(--square-border-radius-large);
    border: 1px solid var(--border-color);
    background-color: var(--white-pure);
    top: 24px;
    left: calc(var(--main-left-navbar-collapsed-width) - 14px);
    transition: left 0.5s ease-in-out, opacity 0.7s ease-in-out;

    &.isOpen {
      left: calc(var(--main-left-navbar-extended-width) - 14px);
    }

    svg {
      fill: var(--black-pure);
      margin: 0;
    }

    &:hover {
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);

      svg {
        fill: var(--btn-primary-color);
      }
    }

    &.hidden {
      opacity: 0;
      transition: opacity 0s;
    }
  }
}
