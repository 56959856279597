.BadgeCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 7px;

  max-width: 440px;
  min-width: 200px;
  height: 200px;

  border: 1px solid var(--light-gray);
  border-radius: 7px;
  background-color: var(--white-pure);

  img {
    max-height: 80px;
  }

  div.BadgeInformation {
    align-items: center;
    padding: 0px;
    gap: 7px;

    text-align: center;
  }

  &:hover {
    box-shadow: 0 0 2px 1px var(--highlight-color) !important;
  }
}
