.comment-slack-source {
  svg {
    width: 15px;
    position: relative;
    top: -1px;
  }
}

.CommentDropdown {
  .PencilIcon {
    svg {
      width: 20px;
      height: 20px;
      fill: var(--text-color-primary);
    }
  }

  .TrashIcon {
    width: 20px;
    height: 20px;
    padding: 1px;
  }
}
