main.PagesTab {
  @include default-page-grid;

  @extend .mb-4;

  grid-column: span 12;
  row-gap: 30px;

  .PagesTables {
    grid-column: span 6;

    @include up-to-medium-size {
      grid-column: span 12;
    }
  }
}
