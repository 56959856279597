.text-smallest {
  /* 11px */
  font-size: 0.733rem;
}

.text-small {
  /* 13px */
  font-size: 0.867rem;
}

.text-small-important {
  font-size: 0.867rem !important;
}

.text-normal {
  /* 15px */
  font-size: 1rem;
}

.text-sm-normal {
  @include small-size {
    font-size: 1rem;
  }
}

.text-large {
  /* 18px */
  font-size: 1.2rem;
}

.text-xl {
  /* 21px */
  font-size: 1.4rem;
}

.text-3xl {
  /* 28px */
  font-size: 1.867rem;
  line-height: normal;
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.word-break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.monospaced {
  font-family: monospace;
}

.ws-pre-wrap {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
}

@for $i from 3 through 7 {
  .font-weight-#{$i}00 {
    font-weight: #{$i}00;
  }
}

.link-color {
  color: var(--link-color) !important;
}

.link-color-on-hover:hover {
  color: var(--link-color) !important;
}

.text-color-primary {
  color: var(--btn-primary-color) !important;
}

.text-color-secondary {
  color: var(--text-color-secondary) !important;
}

.text-color-highlight {
  color: var(--highlight-color) !important;
}

.text-muted-dark {
  color: $mid-gray !important;
}

// Has webkit prefix, but is supported in
// all browsers except IE
@for $i from 2 to 4 {
  .truncate-text-at-#{$i}-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $i;
    overflow: hidden;
  }
}

.truncate-text-at-1-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.searchHighlight {
  font-style: normal;
  background: $search-match-highlight-bg-color;
  color: $search-match-highlight-font-color;
}
