.SocialSharePreviewCTA {
  a {
    @extend .d-flex;

    color: var(--highlight-color);
    font-weight: normal;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
