.BaseItemList {
  min-width: 250px;
  background-color: var(--white-pure);
  border: 1px solid var(--border-color);
  border-radius: var(--square-border-radius);

  .item.is-selected {
    background-color: var(--search-bg-color);
  }
}
