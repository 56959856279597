.WorkflowEditPage__Actions__AddActionNode {
  background-color: transparent;
  width: 280px;
  cursor: pointer;

  span {
    color: var(--link-color);
  }

  &--first {
    padding: 12px 16px;
    border: 1px solid var(--link-color);
    border-radius: 4px;
  }

  .isvg:hover {
    background-color: var(--tile-background-color);
    border-radius: 50%;
    padding: 0px 3px;
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--link-color);
    }
  }
}
