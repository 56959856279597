.GoLinkEditor {
  a .ListToggleIcon {
    fill: var(--link-color);
  }

  svg.rotated {
    transform: rotateZ(90deg);
  }

  .ExternalLinkIcon {
    svg {
      fill: var(--link-color);
      height: 16px;
      width: 16px;
    }
  }
}
