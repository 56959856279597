.StepTemplateFormButtonsContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @include up-to-medium-size {
    flex-direction: column-reverse;
  }

  .right-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .draft-button {
      margin-right: 10px;
      @include up-to-medium-size {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 0px;
      }
    }

    @include up-to-medium-size {
      flex-direction: column;
    }
  }
}
