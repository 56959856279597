.ClearyAdminPage {
  .EmployeeSearch {
    .Select-value-label {
      height: 30px;
    }
  }
}

.ClearyAdminImageSelector > .ImageSelector {
  max-width: none !important;
}

