.UserImportDetail {
  .ReactVirtualized__Table__Grid {
    outline: none;
  }

  .ReactVirtualized__Table__rowColumn {
    a {
      color: var(--highlight-color);
      &:hover {
        color: var(--highlight-color);
      }
    }
  }

}

.ResultRecord-Modal {
  .ResultRecord_Label {
    flex: 1 1 40%;
  }

  .ResultRecord_Value {
    flex: 1 1 60%;
  }
}
