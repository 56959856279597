.AudienceDropdownSelector {
  .dropdown {
    margin-bottom: 2rem;

    label.form-label {
      margin-bottom: 2px !important;
    }

    .Icon {
      width: 18px;
      height: 18px;
      margin-bottom: 10px;
    }
  }

  .Select-control {
    border: none;
    border-bottom: 1px solid var(--border-color);
    border-radius: 0;

    .Select-value {
      padding: 0;
    }
  }

  .AudienceTypeSelect {
    min-width: 160px;

    .Select-value-label {
      color: var(--btn-primary-color) !important;
    }
  }
}
