div#SlackFeedback {
  // hides the widget in small screens
  @include small-size {
    display: none;
  }

  // hides the trigger button icon
  div[class*="Trigger"] svg {
    display: none;
  }

  @media print {
    display: none;
  }
}
