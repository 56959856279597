.Settings-droppableSection {
  width: 15em;

  & > div {
    display: inline-block;
  }
}

.Settings-droppableSection--isDraggingOver {
  opacity: 0.6;
}

.Draggable--dragging {
  background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
}
