.FeatureAnnouncementBadgeWithPopover {
  padding: 24px;
  width: 300px;

  .content {
    padding: 0;

    > * + * {
      margin-top: 5px;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-style: normal;
    margin-bottom: 4px;
    font-weight: 600;
    line-height: 150%;
    padding: 0px;
    background-color: transparent;
    border: 0;
  }

  .body {
    @extend .text-small;
    padding: 0;
    color: var(--mid-gray);
  }
}

.FeatureAnnouncementTrigger {
  @extend .text-smallest;
  background-color: var(--skill-tag-hover-background-color);
  color: #FFF;
  flex-shrink: 0;
  flex-grow: 0;
  width: 45px;
  height: 20px;
  text-transform: uppercase;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}
