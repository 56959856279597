.TiptapToolbarButton {
  height: 40px;
  width: 38px;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;

  button {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-radius: var(--square-border-radius);
    border: none;
    outline: none;

    &:hover {
      background-color: var(--editor-toolbar-hover-background-color);
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--text-color-primary)
    }

    &:disabled {
      cursor: not-allowed;

      svg {
        fill: var(--editor-toolbar-disabled-icon-color);
      }
    }
  }

  &.isActive {
    button {
      background-color: var(--secondary-gray);
      border-radius: var(--square-border-radius);
    }

    svg {
      fill: var(--editor-toolbar-active-icon-color);
    }
  }
}
