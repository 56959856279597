// Delete this file when upgrading to bootstrap v5!

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

@media (min-width: 576px) {
  .gap-sm-3 {
    gap: 1rem !important;
  }
}
