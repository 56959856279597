.GroupPosts {
  grid-column: 4 / span 9;

  @include up-to-medium-size {
    grid-column: 1 / span 12;
  }

  .EmptyFeedCard {
    flex-direction: row;

    @include up-to-medium-size {
      flex-direction: column;
      text-align: center;
    }
  }
}
