.RequestBadgeModal {
  .modal-content {
    margin-top: 150px;
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 35px 20px;
    gap: 35px;

    textarea {
      resize: none;
      width: 100%;
    }

    .ActionButtons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      margin-left: auto;
    }
  }
}
