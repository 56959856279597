.ScrollToModal {
  .modal-body {
    padding: 0;
    overflow-x: hidden;
  }

  .modal-content {
    overflow-y: hidden;
  }

  .modal-header {
    display: none;
    button.close {
      font-size: 2rem;
      font-weight: normal;
    }
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .ScrollToSectionsContainer {
    position: relative;
    & > .modal-header {
      display: flex;
    }
  }

  .SectionActive {
    color: var(--highlight-color);
  }

  .DesktopSectionHeaders {
    background-color: var(--textbox-lightest-gray);
  }

  .ModalActions {
    position: relative;
    border-top: solid 1px var(--text-color-muted);
  }

  // 150 and 250 values are a result
  // of manual testing.
  .ScrollToSections {
    height: calc(100vh - 150px);
    overflow-y: scroll;

    @include small-size {
      height: calc(100vh - 250px);
    }
  }
}
