.RecognitionWidget {
  container-type: inline-size;

  .ShoutoutItem, .AwardedBadgeItem {
    border: 1px solid transparent !important;

    &:hover {
      border: 1px solid var(--highlight-color) !important;
    }

    .ReactionsAndComments {
      margin-bottom: 0 !important;
    }
  }

  .AwardedBadgeItem {
    &__image {
      height: 62px;
      width: 62px;
    }

    .AwardedBadgeTitle {
      text-align: center;
      font-weight: 600;

      .UserLink {
        span {
          font-size: 1rem;
          color: var(--link-color) !important;
        }
      }
    }
  }

  .ShoutoutItem {
    .ShoutoutFrom {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }

    .ShoutoutCompanyValues {
      margin-top: 1rem !important;
      margin-bottom: 0 !important;
    }

    @container (max-width: 550px) {
      .ShoutoutFrom {
        .ShoutoutSentAt {
          display: none;
        }
      }
    }
  }

  .SendShoutoutButton {
    display: flex;
    align-items: center;

    svg {
      fill: var(--btn-primary-color);
    }

    @container (max-width: 400px) {
      min-width: 36px;
      width: 36px;
      min-height: 36px;
      height: 36px;
      padding: 0;

      .ButtonText {
        display: none;
      }
    }
  }

  .ShowMoreText__anchor {
    font-weight: 600;

    &:hover {
      @extend .pointer;
      color: var(--link-color);
    }
  }
}
