table.VersionsTable {
  tbody {
    tr {
      td:first-child {
        width: 17.5%;
      }

      td:nth-child(2) {
        width: 17.5%;
      }

      td:nth-last-child(2) {
        width: 15%;
      }

      td:last-child {
        width: 50%;
      }
    }
  }
}
