.HomePageWidget {
  height: 100%;

  > .card-body {
    @extend .visible-scroll;

    padding: var(--homepage-widget-padding);
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .HomePageWidget--scrollable-section {
    flex: 1;
    overflow-y: auto;

    &.hasOutsideScrollbar {
      width: calc(100% + 24px);

      > :first-child {
        padding-right: var(--homepage-widget-padding);
      }

      .infinite-scroll-component__outerdiv {
        padding: 0;

        .infinite-scroll-component {
          padding-right: 24px !important;
        }
      }
    }
  }

  .ViewAllLink {
    @extend .mt-2;

    svg {
      width: 11px;
      height: 11px;
      fill: var(--link-color);
    }
  }
}
