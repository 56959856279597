main.SearchResultsPage {
  @include default-page-grid;

  padding-top: 19px;
  padding-bottom: 19px;

  .MultipleRefinedFilters {
    & > div::after {
      display: none;
    }

    > div {
      padding-bottom: 24px !important;
    }
  }

  .SearchResultsPage__card {
    grid-column: 1 / span 12;

    .SearchResultsPage__card-body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-left: -16px;
      margin-right: -16px;

      section.SearchResultsPage__main {
        width: 75%;
        padding-left: 16px;
        padding-right: 16px;

        @include small-size {
          width: 100%;
        }

        .SearchBar {
          margin-bottom: 16px;

          > div {
            @include small-size {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            input {
              height: 52px;
            }
          }
        }

        .NewSearchResults {
          @include small-size {
            grid-column: 1 / span 12;
          }
        }
      }

      section.NewSearchFilters {
        width: 25%;
        padding-left: 16px;
        padding-right: 16px;

        @include small-size {
          display: none;
        }
      }
    }
  }

  em {
    font-weight: 700;
    font-style: normal;
  }

  .AsyncSearchInputGolink {
    .AsyncSearchInput.hasIcon {
      padding-left: 60px;
    }
  }

  .TopLevelFilterLink {
    &.isActive {
      svg {
        fill: var(--link-color);
        color: var(--link-color);
      }
    }

    svg {
      fill: var(--text-color-dark-muted);
      color: var(--text-color-dark-muted);
      width: 18px;
      height: 18px;
    }
  }
}
