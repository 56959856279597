.BadgeDetailCard {
  grid-column: span 12;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
  gap: 18px;

  background: var(--white-pure);
  box-shadow: var(--card-box-shadow);
  border-radius: var(--square-border-radius-large);

  .EditableContent-Display {
    @include small-size {
      width: 100%;
    }
  }

  h1 {
    min-width: 540px;
    border-bottom: 1px solid var(--border-color);

    @include small-size {
      width: 100%;
      min-width: 0;
      font-size: 20px;
      overflow: auto;
    }
  }

  .ImageSelector {
    width: auto;
    max-width: 100%;

    img {
      max-height: 100px;
      width: 100%;
      object-fit: contain;
    }
  }

  .ImageSelector-dropzone {
    border: none;
    border-radius: none;
    padding: 10px;

    &:hover {
      background-color: var(--border-color);

      img {
        opacity: 50%;
      }
    }
  }

  .ImageSelector-imagePreview {
    img {
      position: relative;
    }
  }

  .Name {
    width: 540px;
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    padding-bottom: 10px;

    @include small-size {
      width: 100%;
      font-size: 20px;
      overflow: auto;
    }
  }

  .Description {
    width: 540px;
    text-align: center;
    resize: none;

    @include small-size {
      width: 100%;
      overflow: auto;
    }
  }

  img {
    max-height: 100px;
    object-fit: contain;
  }
}
