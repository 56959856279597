.AiAnswersInsights {
  .AiAnswersInsightsThemeCollection {
    max-height: 400px;
    overflow-y: auto;
  }

  .AiAnswersTopic {
    &:hover {
      background-color: var(--highlight-color);

      .AiAnswersTopicTitle {
        color: var(--white-pure) !important;
      }
    }
  }

  .AiInsightsEmptyState {
    .isvg, svg {
      width: 60px;
      height: 60px;
    }
  }
}
