.TemplateItem {
  .TextTemplateItem {
    border: 1px solid transparent;

    .PreviewAndApplyButtons .PreviewApply {
      display: none;
    }

    &:hover {
      border-color: var(--link-color);

      .PreviewAndApplyButtons .PreviewApply {
        display: block;
      }

      .PreviewAndApplyButtons .NewBadgeWrapper {
        display: none;
      }
    }

    .aiIcon {
      fill: var(--highlight-color);
    }
  }

  .CardTemplateItem {
    width: 115px;

    .ArticlePreview, .PreviewAndApplyButtons {
      width: 88px;
      height: 100px;
      border-radius: var(--square-border-radius);
    }

    .ArticlePreview {
      .card {
        pointer-events: none;
        transform: scale(0.125);
        transform-origin: top left;
        width: 800%;
        height: 800%;
        overflow: hidden;
      }

      .PreviewAndApplyButtons {
        visibility: hidden;
      }

      &:hover {
        .PreviewAndApplyButtons {
          border: 1px solid var(--highlight-color);
          background-color: rgba(255, 255, 255, 0.8);
          visibility: visible;

          .PreviewButton, .ApplyButton {
            width: 100%;
            display: flex;
            opacity: 1;
            background-color: var(--highlight-color);
            border-radius: var(--square-border-radius);
            color: var(--white-pure);

            svg {
              width: 14px;
              height: 14px;
              fill: var(--white-pure);

              path {
                fill: var(--white-pure);
              }
            }
          }
        }
      }
    }

    .ai-tooltip-container {
      background-color: var(--highlight-color);
      border-radius: 50%;
      position: absolute;
      top: -7px;
      right: -7px;
      width: 20px;
      height: 20px;
      z-index: $zindex-dropdown;
      outline: 2px solid var(--light-gray);

      .aiIcon {
        svg {
          fill: var(--white-pure);
          height: 15px;
          width: 12px;
        }
      }
    }
  }
}
