.email-detail-user-actions-table {
  > tbody {
    > tr {
      &.is-expanded {
        &, table {
          background: var(--dynamic-color-primary-color-lightest--highlight-color);
        }
      }

      > td {
        vertical-align: top;
      }

      .table {
        td {
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  thead, tbody {
    th, td {
      padding: 10px;
    }
  }
}

.email-detail-user-clicks {
  .chevronIcon {
    svg {
      width: 22.5px;
      height: 22.5px;
      fill: var(--link-color);
    }
  }

  a {
    line-height: 1em;
  }

  .text-truncate {
    max-width: 210px;
    display: inline-block;
  }

  .table {
    thead {
      th {
        color: var(--text-color-secondary);
        font-weight: 400;
      }
    }

    th, td {
      padding: 6px 10px;
    }

    th:first-child, td:first-child {
      padding-left: 0
    }

    th:last-child, td:last-child {
      padding-right: 0
    }
  }
}
