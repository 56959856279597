.EditTemplateContent {
  background-color: var(--white-pure);
  position: relative;
  margin-left: calc(-1 * var(--left-nav-width));
  transition: margin-left 0.5s ease-in-out;
  justify-content: flex-end;
  overflow-x: hidden;

  .PageCoverImage {
    margin-left: 45px;
    &.hasCoverImage {
      margin-left: 0px;
    }
  }

  .EditTemplateTiptapWrapper {
    margin: auto;
    max-width: var(--page-width-narrow);
    word-break: break-word;
    margin-bottom: 3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;

    .TogglePageWidth {
      right: 48px;
    }

    @include min-media-width($bootstrap-md) {
      padding: 1.5rem 3rem 0 3rem;
    }

    &.isEditing {
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;
      height: calc(100% - 52px);
    }

    &.showFullWidth {
      max-width: 100%;
    }
  }

  margin-bottom: 5px;

  &.isDesktopNavOpen {
    margin-left: 0;
  }

  @include small-size {
    width: 100vw;
    padding-bottom: 0;
  }
}

