.CreateStepTemplatePage,
.EditStepTemplatePage {
  main {
    margin: 32px 40px;
  }

  span.EditableContent {
    flex-grow: 1;

    input {
      border: 0;
      border-bottom: 1px solid var(--border-color);
      padding: 0;
      font-size: var(--h3-font-size);
      font-weight: 700;
      width: 100%;
    }
  }
}
