div.EmployeeNode {
  height: 74px;
  width: 234px;
  display: flex;
  align-items: center;

  div.node-card {
    height: 74px;
    width: 222px;
    background-color: var(--white-pure);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid var(--orgchart-border-color);
    border-radius: var(--square-border-radius);

    &.isHighlighted {
      border-color: var(--highlight-color);
    }

    &.isSelected {
      border-width: 3px;
    }
  }

  // we need this container otherwise the position of this element breaks in Safari
  // for some reason, inside the charting library position: absolute or position: relative breaks the layout in Safari
  // to avoid doing that, we set the width of the container to 12px, when the actual circle has a width of 24px
  div.children-circle-container {
    width: 12px;
    margin-left: auto;
  }

  div.children-circle {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    flex-shrink: 0;

    &.open {
      border: 3px solid var(--btn-primary-color);
      background-color: var(--white-pure);
    }

    &.collapsed {
      background-color: var(--btn-primary-color);
      display: flex;
      align-items: center;
      justify-content: center;

      @media print {
        // allow printing of background color in circle
        // without having to check "print background graphics" in the print dialog.
        -webkit-print-color-adjust: exact;
      }

      span {
        color: var(--white-pure);
        font-size: 10px;
      }
    }
  }

  img {
    width: 42px;
    height: 42px;
    margin: 0 0.75rem;
    flex-shrink: 0;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
  }

  &.isOrientationVertical {
    height: 143px;
    width: 147px;
    flex-direction: column;

    div.node-card {
      height: 131px;
      width: 147px;
      flex-direction: column;
    }

    .user-info {
      align-items: center;
      padding: 0 4px;
      gap: 2px;

      span {
        text-align: center;
        line-height: 17px;
      }
    }

    div.children-circle-container {
      width: 24px;
      height: 12px;
      margin-left: 0;
      margin-top: auto;
    }

    img {
      margin: 12px auto 4px auto;
    }
  }
}
