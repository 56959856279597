.FormErrorList {
  border: 1px solid $error-color;
  border-radius: var(--square-border-radius);

  background-color: var(--dynamic-color-form-error-list--error-color); // color($error-color tint(85%));

  color: $error-color;

  div, li {
    color: $error-color;
  }
}