.WorkflowRunPage__Output__ClearyRichTextOutput__Modal {
  .close {
    padding: 6px 10px !important;
  }

  .TiptapEditorContentWrapper {
    margin: 0 auto;
    // 65px from the headers
    height: calc(100vh - 65px);
    width: 756px;
    padding: 24px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;

  }
}
