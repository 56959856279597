.PrivateLabel {
  @extend .bordered;

  display: flex;
  align-items: center;
  padding: 3px 7px;

  .LockIcon {
    svg {
      fill: var(--text-color-secondary);
      width: 11px;
      height: 11px;
      margin-bottom: 2px;
    }
  }
}

.PrivateLabelPopover {
  background-color: var(--black-pure);

  .arrow {
    &::after {
      border-bottom-color: var(--black-pure) !important;
    }
  }
}
