.CoverImageWidget {
  --cover-image-height-large: 175px;
  --cover-image-height-small: 175px;

  // display the cover image fully responsive when its cropped
  // legacy cover images should work with fixed height
  // since they can be in any proportion
  &.isResponsiveCoverImage {
    --cover-image-height-large: auto;
    --cover-image-height-small: auto;
  }

  width: 100%;
  max-height: var(--cover-image-height-large);

  @include small-size {
    max-height: var(--cover-image-height-small);
  }

  &.hasCoverImage {
    height: var(--cover-image-height-large);
    min-height: var(--cover-image-height-large);

    @include small-size {
      height: var(--cover-image-height-small);
      min-height: var(--cover-image-height-small);
    }
  }

  .AddCover {
    max-width: fit-content;
    min-width: fit-content;
    margin-left: auto;
    cursor: pointer;
    color: var(--highlight-color);
    border-radius: var(--square-border-radius) !important;
    padding: 0 5px !important;

    .ImageIcon {
      height: 20px;
      width: 20px;
      svg {
        fill: var(--highlight-color);
      }
    }

    .LoadingIndicator > div {
      height: 20px !important;
      width: 20px !important;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    input {
      display: inline-block;
      height: 0;
      width: 0;
      padding: 0;
    }
  }

  &.isUploading {
    input {
      pointer-events: none !important; // disable mouse clicks with css
    }

    div.btn.btn-primary {
      opacity: 0.65;
    }
  }

  .actions {
    bottom: 0;
    width: 100%; // Make enough room for the child buttons to fit into one line

    input {
      display: inline-block;
      height: 0;
      width: 0;
      padding: 0;
    }

    .btn.btn-primary {
      background: var(--dynamic-color-primary-color-lightest--highlight-color) !important;
      color: var(--highlight-color) !important;
      max-width: fit-content !important;
      min-width: 170px !important;
    }

    @include small-size {
      width: 100%;

      .btn.btn-primary {
        margin-right: 0 !important;
        font-size: 0.786em !important;
        max-width: 140px !important;
        min-width: 140px !important;
        padding: 5px;
      }
    }
  }

  img {
    max-height: var(--cover-image-height-large);
    object-fit: cover;
    border-radius: var(--square-border-radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include small-size {
      max-height: var(--cover-image-height-small);
    }
  }
}
