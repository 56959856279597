.RichTextInlineComments {
  position: relative;
  border-left: 1px solid var(--border-color);
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  transition: width 0.5s ease-in-out;

  &.isExpanded {
    width: 400px;

    svg {
      transform: rotate(180deg);
    }
  }

  .ToggleButton {
    z-index: $zindex-resize-square;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    left: -12px;
    border: 0;
    border-radius: 12px;
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: 0;
    }

    svg {
      fill: var(--link-color);
      height: 16px;
      width: 16px;
      display: block;
      margin: auto;
    }
  }
}
