.WidgetsCategory {
  .TriangleIcon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: var(--text-color-primary);
    }

    &.TriangleIcon-right {
      transform: rotate(-90deg);
    }
  }

  .WidgetOption {
    display: flex;
    flex-direction: column;
    background-color: var(--white-pure);
    align-items: center;
    justify-content: center;
    width: 45%;
    height: 85px;
    padding: 1rem;
    border-radius: var(--square-border-radius);
    cursor: move;

    svg {
      width: 28px;
      height: 28px;
      fill: var(--text-color-primary);
    }
  }
}
