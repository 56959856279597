.VideoAnalytics {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-right-side {
      display: flex;
      align-items: center;
      gap: 30px;

      .data-items {
        display: flex;
        gap: 24px;

        .data-item {
          display: flex;
          align-items: flex-start;
          gap: 11px;

          .icon {
            @extend .p-2;
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: var(--square-border-radius);
            background: var(--light-background-color);

            svg {
              width: 16.368px;
              height: 18.333px;
              flex-shrink: 0;
              color: var(--highlight-color);
            }
          }

          .data-value {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;

            span:nth-of-type(1) {
              @extend .text-small;
              color: var(--text-color-secondary);
              font-weight: 400;
              line-height: 150%; /* 19.5px */
            }

            span:nth-of-type(2) {
              @extend .text-normal;
              color: var(--text-color-primary);
              font-weight: 400;
              line-height: 150%; /* 22.5px */
            }
          }
        }
      }
    }

    img {
      max-width: 130px;
      max-height: 75px;
    }
  }

  .inner-title {
    @extend .text-normal;
    color: var(--text-color-primary);
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 22.5px */

    > span {
      color: var(--text-color-secondary);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 19.5px */
      margin-left: 14px;
    }
  }

  .stats-part {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .stat {
      width: 214px;
    }
  }

  .chart-part {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
  }
}
