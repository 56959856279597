.VideoConferenceLinkDisplay-button-text {
  font-size: 1.25rem;
  color: var(--success-color) !important;
}

.ZoomSettingsPopover {
  z-index: 1000; // Make sure the zoom authorization modal is over it
}

.btn-video-link {
  background-color: white !important;

  &:hover {
    background-color: white !important;
  }

  &:active {
    background-color: white !important;
  }

  &:focus {
    background-color: white !important;
  }
}
