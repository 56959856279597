section.ProfileWhereToFindMe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 35px;

  @include small-size {
    padding: 2rem 0;
    margin-top: 0;
  }

  .OfficeBuildingIcon,
  .HomeIcon,
  .ClockIcon,
  .LocationPinIcon {
    svg {
      width: 20px;
      height: 20px;
      fill: var(--link-color);
      margin-top: -2px;
    }
  }

  header {
    .InfoIcon {
      svg {
        width: 18px;
        height: 18px;
        fill: var(--text-color-secondary);
        margin-top: -2px;

        &:hover {
          fill: var(--link-color);
          cursor: pointer;
        }
      }
    }
  }

  div.CurrentTime {
    margin-top: 14px;
  }

  .EditableContent-Display.canEdit + div.CurrentTime {
    margin-top: 8px;
  }

  // overrides for EditableLocationContent
  .EditableContent-Display {
    color: var(--text-color-primary);
    margin-top: 1rem;

    &.canEdit {
      margin-top: calc(1rem - 8px);

      &.isEmpty {
        color: var(--text-color-secondary);
        font-weight: 500;

        svg {
          fill: var(--text-color-secondary);
        }
      }
    }

    &.canEdit:hover {
      &.isEmpty {
        color: var(--link-color);
        cursor: pointer;

        svg {
          fill: var(--link-color);
        }
      }
    }
  }

  .CurrentLocationForUser {
    .LocationFromBrowser {
      background-color: var(--white-pure);
      cursor: pointer;
      margin: 0 -24px -4px -4px;
      padding: 4px 4px 4px 32px;
      display: none;
      color: var(--link-color);

      &:hover {
        color: var(--dynamic-color-link-text--link-color);
      }
    }

    &:hover {
      .LocationFromBrowser {
        display: block;
      }
    }
  }
}

div.WorkingHoursModal {
  .react-datepicker {
    min-width: 120px;

    .react-datepicker__time-container {
      width: 100%;
    }
  }
}
