.TiptapToolbarWrapper {
  z-index: $zindex-toolbar;

  &.isSticky {
    position: sticky;
    top: 0;
  }

  .TiptapToolbar {
    display: flex;
    position: relative;
    align-items: center;
    z-index: $zindex-toolbar;
    height: 48px;
    background-color: var(--editor-toolbar-background-color);
    border-bottom: 2px solid var(--editor-toolbar-border-color);
    border-radius: var(--square-border-radius);

    .FontFamilySelect {
      width: 116px;
    }

    .ParagraphFormatSelect {
      width: 96px;
    }

    .FontSizeSelect {
      width: 56px;

      .ClearyReactSelect__menu {
        width: 64px;
      }
    }

    .BulletListStyleSelect {
      .ClearyReactSelect__value-container {
        width: 0; // we don't want to show it, but using display: none allows opening multiple react selects at once
      }
    }

    &.TiptapSecondaryToolbar {
      position: relative;
      z-index: $zindex-secondary-toolbar;
      background-color: var(--editor-toolbar-border-color);
      visibility: hidden;
      margin-top: -48px;
      transition: visibility 0.5s step-end, margin-top 0.5s;
      justify-content: center;

      div, span {
        opacity: 0;
        animation: fade-out 0.5s;
      }

      &.showSecondaryToolbar {
        margin-top: 0;
        transition: margin-top 0.5s;
        visibility: visible;

        div, span {
          opacity: 1;
          animation: fade-in 0.5s;
        }
      }
    }
  }
}
