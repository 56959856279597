.GolinkMiniCard {
  font-size: $label-font-size;

  &.Sortable-isDragging {
    list-style: none;
    display: block;
  }

  .GolinkMiniCard-text {
    font-size: 1.1rem; // Increase the font size slightly
    user-select: text;

    a {
      color: var(--link-color); // Make the go/link text blue
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .text-secondary {
    user-select: text;
  }

  .remove-golink.rounded-circle {
    display: none;
    color: white;
    background: crimson;
    height: 20px;
    width: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -4px;
    left: 76%;
    transform: translateX(50px);
    z-index: $zindex-tooltip;

    span {
      position: relative;
      top: -1px;
    }
  }

  &:hover .remove-golink.rounded-circle {
    display: flex;
  }
}

.GolinkMiniCard-owner a {
  color: var(--text-color-secondary);

  &:hover {
    color: var(--link-color);
  }
}
