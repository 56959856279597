.PageBreadcrumbs {
  a, .MorePages {
    &:hover {
      color: var(--link-color) !important;
    }
  }
}

.BreadcrumbsPopover {
  min-width: 300px;

  a {
    &:hover {
      color: var(--link-color) !important;
    }
  }

  .RightCornerArrowIcon {
    svg {
      fill: var(--mid-gray);
      transform: scaleY(-1);
    }
  }
}
