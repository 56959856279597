html#public-apps {
  height: 100%;

  body, #cleary-app-container, .CelebrationApp {
    height: 100%;
  }
}

.CelebrationApp {
  .slide-show-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
  }

  header {
    padding: 21px 0px;

    .HeaderWrapper {
      @extend .container-fluid;

      .CTA {
        a {
          color: white;
          background-color: #5246D7;
          padding: 10px 24px;
          border-radius: 7px;
          font-size: 15px;
          font-weight: 500;
        }
      }

      .CelebrationApp-companyImage {
        display: flex;
        gap: 12px;
        align-items: center;

        @extend .text-normal;

        font-weight: 500;
        line-height: 22.5px;
        color: #000;

        img {
          width: 105px;
          height: auto;
        }
      }
    }
  }

  footer {
    padding-top: 48px;
    padding-bottom: 25px;
    background-color: #E7F0FF;

    .description {
      font-size: 13px;
      color: #0E003C;
    }

    .copyright {
      font-size: 13px;
      color: #7E7B96;

      a {
        font-weight: normal;
        color: #5246D7;
        margin-left: 5px;
      }
    }
  }

  .GreetingCardBannerImage {
    margin-top: 0px !important;
  }

  .CommentsGrid {
    columns: 2;

    div {
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
}

@media (min-width: 992px) {
  .CelebrationApp {
    .CommentsGrid {
      columns: 3;
    }
  }
}
