.SignGreetingCardModal {
  video {
    width: 100%;
    height: 100%;
  }

  .icon {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .remove {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    background: var(--white-pure);
    padding: 10px;
    border-radius: 100%;
    z-index: 9999;

    svg {
      width: 24px;
      height: 24px
    }
  }

  .SignWithUploadedFile {
    position: relative;

    img.preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .FileSelectDropzone {
      color: var(--text-color-secondary);
      width: 100% !important;
      position: relative;
      height: 200px;
      border-width: 2px;
      border-color: var(--border-color);
      border-style: dashed;
      border-radius: 5px;

      .helper-text {
        color: var(--text-color-secondary);
        font-weight: 700;

        svg {
          width: 55px;
          height: 55px;
          color: var(--text-color-secondary);
        }
      }
    }
  }

  .nav-tabs {
    border: 0;
    justify-content: space-between;

    &.card-header-tabs {
      margin: 0;
    }

    .nav-link {
      @extend .bordered, .text-normal;
      position: relative;
      background: var(--pill-tabs-background-color);
      border: 1px solid var(--border-color);
      color: var(--text-color-primary);
      font-weight: 600;
      width: 24%;

      @include up-to-medium-size {
        width: 49%;
        margin-bottom: 4px;
      }

      &.active {
        background: var(--nav-tabs-active-background-color);
        border: 1px solid var(--nav-tabs-active-border-color);
      }
    }

    .dropdown {
      .dropdown-menu {
        @extend .bordered;

        margin-left: -16px !important;
        margin-top: 5px !important;

        .dropdown-item {
          padding: 8px;

          &:active {
            background: none;
            color: inherit;
          }
        }
      }

      .dropdown-toggle.custom {
        font-weight: 600;
        border: 0;
        background: transparent;
        padding: 0;

        &:active, &:focus {
          border: none;
          background: none;
          outline: none;
        }
      }
    }
  }
}
