.BetaTag {
  border: 1px dashed var(--btn-primary-color);
  border-radius: 5px;
  height: 25px;
  width: 50px;
  color: var(--btn-primary-color);
  letter-spacing: 3.5px;
  padding-left: 3.5px !important;
  
  &.small {
    padding-left: 1px !important;
    letter-spacing: 1px;
    height: 18px;
    width: 41px;
  }
}
