.GroupTypePage {
  .GroupTypeLabelForm {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-secondary);

      &:hover {
        fill: var(--link-color);
        cursor: pointer;
      }
    }
  }

  span.AddMoreLink {
    color: var(--text-color-secondary);

    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-secondary);
    }

    &:hover {
      color: var(--link-color);
      cursor: pointer;

      svg {
        fill: var(--link-color);
      }
    }
  }
}
