main.OverviewTab {
  @include default-page-grid;

  @extend .mb-4;

  grid-column: span 12;
  row-gap: 30px;

  .SingleStatTile {
    grid-column: span 3;
  }

  .Adoption {
    grid-column: span 12;
  }
}


.AnalyticsAudienceRulesSelector {
  .RuleSelect {
    margin-top: 0 !important;
  }
}
