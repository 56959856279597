.EmojiCounterDisplay {
  @extend .pointer, .bordered;

  height: 26px;
  padding: 2px 5px;
  border-radius: var(--square-border-radius-large);

  &:hover {
    background-color: var(--secondary-gray);
  }
}

.CustomReactionImage {
  max-height: 20px;
  max-width: 100%;
  height: auto;
  width: auto;
}
