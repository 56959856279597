.GoogleDriveIntegrationPage {
  .CsvSelector-dropzone {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 2em;
    width: 200px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
  }
}
