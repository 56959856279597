.OnboardingBuddiesSettingsModal {
  .AudienceSelectorFields {
    grid-row-gap: 0.75rem;

    label {
      color: var(--text-color-secondary);
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    .Select-control {
      border: 1px solid var(--border-color);
      border-radius: 2px;

      &:hover {
        border: 1px solid var(--highlight-color);
      }
    }

    .ClearyReactSelect__control {
      border: 1px solid var(--border-color);
      border-radius: 2px;

      &:hover {
        border: 1px solid var(--highlight-color);
      }
    }
  }
}
