.LeftNavbar {
  position: sticky;
  top: 0;
  height: calc(100vh - var(--nav-height));
  width: var(--main-left-navbar-collapsed-width);
  transition: width 0.5s ease-in-out, margin-right 0.5s ease-in-out;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--new-ui-nav-color);
  padding: 24px;
  border-right: 1px solid var(--border-color);
  z-index: $zindex-sticky;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .AdminLabel {
    font-size: 10px;
    color: var(--new-ui-nav-text-color);
    position: relative;
    transform: translateX(5px);
    transition: all 0.5s ease-in-out;
  }

  &.isOpen {
    width: var(--main-left-navbar-extended-width);

    .LeftNavItem-label {
      opacity: 1 !important;
    }

    .AdminLabel {
      padding-left: 12px;
      transform: translateX(0);
    }
  }

  &.isHovered {
    width: var(--main-left-navbar-extended-width);
    margin-right: calc(var(--main-left-navbar-collapsed-width) - var(--main-left-navbar-extended-width));
  }

  hr {
    margin: 24px 0px;
    border-bottom: 1px solid var(--subitems-line-color);
  }
}
