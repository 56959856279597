.ArticleStatSocialShare {
  .chart-part {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .bar {
      gap: 4px;

      div {
        flex-grow: 1;
        height: 32px;
        border-radius: 4px;
      }

      .facebook {
        background-color: #8C84E3;
      }

      .twitter {
        background-color: #FA97B1;
      }

      .linkedin {
        background-color: #FFD0AF;
      }

      .copy {
        background-color: #94E0EA;
      }
    }
  }
}
