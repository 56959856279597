.AiPromptComponent {
  width: 100%;
  position: relative;

  &.isInsideAiBlock {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.isDisabled {
    .AiPromptEditor {
      background-color: var(--lightest-gray);
      box-shadow: none;

      .AiPromptTiptapEditor {
        @extend .truncate-text-at-1-lines;
      }
    }
  }

  .ai-template-disclaimer {
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 0px 0px 8px 8px;
    background-color: var(--light-gray);
  }

  .AiPromptEditor {
    min-height: 50px;
    border: 1px solid var(--border-color);
    box-shadow: var(--card-box-shadow);
    border-radius: 8px;

    button {
      svg {
        height: 18px;
        width: 18px;
        fill: var(--white-pure);
      }
    }

    .AiMentionedRecord {
      .x-button {
        vertical-align: text-bottom;
      }
    }
  }
}

.AiPromptComponent:hover {
  .HoverTopMenu {
    opacity: 1;
    pointer-events: all;
  }
}
