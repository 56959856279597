.text-primary {
  color: var(--text-color-primary) !important;
}

.text-secondary {
  color: var(--text-color-secondary) !important;
}

.text-muted {
  color: var(--text-color-muted) !important;
}

.text-dark-muted {
  color: var(--text-color-dark-muted) !important;
}

.text-error {
  color: $error-color !important;
}

.text-success {
  color: $success-color !important;
}

.background-color {
  background-color: var(--background-color);
}

.background-color-white {
  background-color: var(--white-pure);
}

.text-white {
  color: var(--white-pure);
}

.text-inherit {
  @include linkColors(inherit);
}

.text-primary-link {
  color: var(--text-color-primary);

  &:hover {
    color: var(--link-color);
  }
}

.text-secondary-link {
  color: var(--text-color-secondary);

  &:hover {
    color: var(--link-color);
  }
}

.text-inserted {
  color: #008000b5;
}

.text-deleted {
  color: #ff0000b3;
}

.text-highlight {
  color: var(--highlight-color);
}

.text-ai {
  background: linear-gradient(258.12deg, var(--ask-ai-end-color) 10.82%, var(--ask-ai-start-color) 104.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-color-warn {
  background-color: var(--orange-warning-color);
}

.bg-color-success {
  background-color: var(--success-color);
}

.bg-timezone-highlight {
  background-color: var(--timezone-highlight-color);
}

.bg-highlight-color {
  background-color: var(--highlight-color);
}

.bg-highlight-color-tint-90 {
  background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);
}

.bg-surveys-card {
  background-color: var(--surveys-card-background-color);
}

.hover-text-color-primary {
  &:hover {
    color: var(--text-color-primary) !important;
  }
}

.hover-background-color {
  &:hover {
    background-color: var(--background-color);
  }
}

.hover-background-color-darker {
  &:hover {
    background-color: var(--dynamic-color-background-color-darker--background-color);
  }
}

.hover-secondary-gray {
  &:hover {
    background-color: var(--secondary-gray);
  }
}

.semi-circle {
  width: 10px;
  height: 22.5px;
  display: block;
  position: absolute;
  z-index: $zindex-zero;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 22.5px;
  border-bottom-right-radius: 22.5px;
  opacity: 0.15;
}

.semi-circle-gray {
  background-color: var(--gray);
}

.semi-circle-yellow {
  background-color: var(--yellow);
}

.semi-circle-red {
  background-color: var(--red);
}

.semi-circle-blue {
  background-color: var(--blue);
}

.svg-secondary {
  svg {
    fill: var(--text-color-secondary);
  }
}

.svg-highlight {
  svg {
    fill: var(--highlight-color);
  }
}

.svg-white {
  svg {
    fill: white;
  }
}

.svg-link {
  svg {
    fill: var(--link-color);
  }
}

.calendar-link {
  color: var(--calendar-link-color);
}
