.ReactionDetailModal {
  .modal-header {
    @extend .d-none;
  }
  .modal-body {
    @extend .p-0;

    max-height: 80vh;
  }

  .modal-content {
    border: none;
  }

  .EmojiCounterDisplay {
    min-width: 70px;

    p {
      @extend .text-large;
    }
  }
  
  .isSelected {
    background-color: var(--light-mid-gray)
  }
  .LeftEmojiNav {
    background-color: var(--lightest-gray);
    overflow-y: auto;
  }

  .UserList {
    overflow-y: auto;
  }
}
