.AiPlatformDashboardPage {
  &__wrapper {
    max-width: 1150px;
    width: 100%;
  }

  &__sidebar-wrapper {
    width: 25%;

    .company-logo {
      max-width: 24px;
      max-height: 24px;
      width: auto;
      height: auto;
      object-fit: contain;
    }
  }

  &__main-content-wrapper {
    width: 75%;
  }

  @include up-to-medium-size {
    &__wrapper {
      flex-wrap: wrap;
    }

    &__sidebar-wrapper {
      width: 100%;
    }

    &__main-content-wrapper {
      width: 100%;
    }
  }

  &__sidebar {
    h3 {
      font-size: 16px;
      color: var(--text-color-primary);
    }

    &__channel {
      font-size: 14px;

      &--link {
        color: var(--link-color);

        .sync svg {
          animation: infinite-rounding 2s linear infinite;

          path {
            fill: var(--link-color) !important;
          }

          @keyframes infinite-rounding {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    .BookReview {
      h1 {
        @extend .text-normal;
        margin: 0;
        font-weight: 600;
      }

      h3 {
        margin: 0;
      }

      img {
        border-radius: 50%;
        width: 42px;
        height: 42px;
      }

      p {
        margin: 0;
      }
    }

    .KnowledgeSources {
      .btn-outline-primary {
        font-size: 0.733rem !important;
      }

      .btn-outline-primary:disabled {
        @extend .text-secondary;
        border: 1px solid var(--text-color-secondary);
      }
    }
  }

  .Activities {
    tr:last-child {
      td {
        border-bottom: 0;
      }
    }

    &__row {
      td {
        padding: 0.6rem 0;
      }
    }

    &__animated {
      opacity: 0;
      transform: scaleX(0);
      transform-origin: left;
      display: inline-block;
      overflow: hidden;
      animation: activityGrowFadeIn 0.5s ease forwards;
    }
  }

  .FAQActivity {
    &__item {
      flex-shrink: 0;
    }

    &__question {
      flex: 1 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .DashboardBanner {
    background-color: var(--tile-background-color);

    .content {
      background-color: var(--white-pure);
    }

   &.CompletedIngestionBanner {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: var(--square-border-radius);
        background: linear-gradient(45deg, #B830F8, #5246D7);
        z-index: -1;
      }

      img {
        margin-bottom: -1.5rem;
      }
    }
  }

  .Dashboard {
    &__statistics {
      .card {
        flex: 1 1 0;

        .tooltip-icon {
          svg {
            fill: var(--text-color-secondary);
          }
        }

        .icon {
          padding: 6px;
          border-radius: 25%;
        }

        .isvg {
          display: block;
          width: 24px;
          height: 24px;
        }
      }

      .card:nth-child(1) {
        .icon {
          background-color: rgba(61, 213, 152, 0.1); // #3DD598

          svg {
            fill: var(--admin-banner-green-background-color);
          }
        }

      }

      .card:nth-child(2) {
        .icon {
          background-color: rgba(61, 91, 213, 0.1); // #3D5BD5 with 10% opacity

          svg {
            fill: $cleary-purple;
          }
        }

      }

      .card:nth-child(3) {
        .icon {
          background-color: color-mix(in srgb, var(--status-label-yellow-text-color) 10%, transparent);

          svg {
            fill: var(--status-label-yellow-text-color);
          }
        }

      }
    }

    &__charts {
      > div {
        flex: 1 1 0;
      }

      canvas {
        height: 205px !important;
        width: 372px !important;
      }
    }
  }
}

@keyframes activityGrowFadeIn {
  from {
    opacity: 0;
    transform: scaleX(0);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
