.BaseLayout {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';
  height: calc(100vh - var(--nav-height));
  border-top: 1px solid var(--base-layout-border-color);
  background-color: var(--white-pure);

  &.useNewUi {
    border-top: none;
  }
}

.BaseLayout__Main {
  grid-area: main;
  overflow-y: auto;
  position: relative;
}

.BaseLayout__Sidebar {
  grid-area: sidebar;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  width: var(--base-layout-sidebar-width);
  transition: width 0.3s ease-in-out;
  background-color: var(--base-layout-sidebar-background-color);
  border-right: 1px solid var(--base-layout-border-color);

  &.isCollapsed {
    width: 0;
    border: none;
  }

  .isvg {
    line-height: 1;
  }
}

.BaseLayout__Sidebar__Content {
  height: 100%;
  width: var(--base-layout-sidebar-width);
}

.Sidebar__MenuItem, .Sidebar__Button {
  background: none !important;
  border-radius: 8px !important;
  color: var(--base-layout-sidebar-menu-item-color) !important;

  svg {
    position: relative;
    top: -1px;
    fill: var(--base-layout-sidebar-menu-item-color);
  }

  .CloseIcon {
    right: auto;
    fill: var(--base-layout-sidebar-menu-item-color);
    stroke: var(--base-layout-sidebar-menu-item-color);
    width: 13px;
    margin-right: 9px;
    margin-left: 1px;
  }

  .AddPencilIcon {
    width: 13px;
    margin-right: 3px;
  }
}

.Sidebar__MenuItem {
  border: none !important;

  &.active, &:hover {
    background-color: var(--base-layout-sidebar-menu-item-hover-color) !important;
    font-weight: 600;
  }
}

.Sidebar__Button {
  border: 2px solid var(--base-layout-border-color) !important;
  font-weight: 600 !important;
}

.Sidebar__MenuItem__Count {
  color: var(--base-layout-sidebar-menu-item-count-color);
}

.Sidebar__ToggleButton {
  background: none !important;
  border: none !important;
}
