.Entry__WorkflowRun {
  .isvg, svg {
    width: 16px;
    height: 16px;
    display: block;
  }

 &__items {
    position: relative;
    padding-left: 22px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 9px;
      height: calc(100% - 17px);
      width: 1px;
      background-color: var(--border-color);
    }

    &__item {
      position: relative;
      padding-left: 6px;

      svg, isvg {
        width: 14px;
        height: 14px;
        display: block;
      }

      &::before {
        content: '';
        position: absolute;
        width: 13px;
        left: -13px;
        height: 10px;
        top: calc(50% - (10px / 2));
        border-left: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        border-bottom-left-radius: 8px;
        transform: translate(0, -50%);
      }
    }
  }
}
