section.ProfileContactInfo {
  grid-column: 10 / span 3;
  grid-row: 1 / span 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include small-size {
    grid-column: 1 / span 12;
    grid-row: 4 / span 1;
    border-top: 1px solid var(--border-color);
    padding: 2rem 0;
  }

  div.ActionButtons {
    display: flex;

    a {
      margin-right: 10px;
      margin-bottom: 35px;
    }
  }

  span.EmailIcon,
  span.PhoneIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-secondary);
    }
  }

  > span.EditableContent-Display {
    margin-top: 1rem;
    width: 100%;

    span.FieldDisplayText {
      width: 100%;

      a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.canEdit {
      margin-top: calc(1rem - 8px);

      &:first-of-type {
        margin-top: calc(1rem - 4px);
      }

      span.FieldDisplayText {
        width: calc(100% - 30px);
      }
    }
  }

  span.EditableContent.WebsiteLink {
    margin-top: 0.5rem;
  }

  span.WebsiteLink {
    svg {
      width: 19px;
      height: 19px;
      fill: var(--text-color-secondary);
      margin: -1px 0 0 0;
    }

    &.isEmpty:hover {
      path {
        fill: var(--link-color);
      }
    }
  }
}
