main.BadgeListPage {
  @include default-page-grid;

  grid-row-gap: 30px;
  margin-top: 20px;

  header {
    grid-column: span 12;
    border-bottom: 1px solid var(--border-color);

    @include default-page-grid;

    > div {
      grid-column: span 12;
    }

    .SearchAndSort {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;

      @include small-size {
        flex-direction: column;
        gap: 14px;
      }
    }

    .BadgeSearch {
      width: 450px;

      @include up-to-medium-size {
        width: 300px;
      }

      @include small-size {
        width: 100%;
      }
    }
  }

  .BadgeList {
    grid-column: span 12;
    @include default-page-grid;

    grid-row-gap: 30px;

    .BadgeCard {
      grid-column: span 4;

      @include up-to-large-size {
        grid-column: span 6;
      }

      @include small-size {
        grid-column: span 12;
        grid-row-gap: 14px;
      }
    }

    .EmptyState {
      grid-column: span 12;
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 140px;
    }
  }
}
