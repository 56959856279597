.MyAppsListPage {
  @include default-page-grid;

  header {
    grid-column: span 12;

    .Search {
      width: 400px;

      @include small-size {
        width: 100%;
      }
    }
  }


  .AppsTable {
    grid-column: span 12;

    .NotFavorite {
      @extend .d-block;
    }

    // hide the no results message
    td.message {
      display: none;
    }
  }

  .disabled {
    cursor: default;
  }
}
