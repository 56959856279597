.GoogleDrivePickerPage {
  .card {
    max-width: 430px;
    margin: 0 auto;
  }
}

.PickerDialogHelper {
  position: fixed;
  z-index: 9999;
}
