div.Picture {
  &.PrimaryPhoto {
    display: grid;
    grid-column: 1 / span 3;
    grid-row: 1 / span 2;
  }

  --profile-picture-dimension: 198px;
  --profile-picture-dimension-mobile: 180px;

  & > div {
    height: var(--profile-picture-dimension);
    width: var(--profile-picture-dimension);
  }

  .ProgressiveImage {
    border: none;
  }

  .ProgressiveImage,
  img {
    border-radius: var(--profile-image-border-radius) !important;
  }

  .RemoveProfilePicture {
    stroke: var(--light-mid-gray);
  }

  .ProfilePhotos-promote, .ProfilePhotos-reposition {
    bottom: -10px;
  }

  @include small-size {
    &.PrimaryPhoto {
      grid-column: 1 / span 12;
      grid-row: 1;
      align-items: center;
    }

    & > div {
      height: var(--profile-picture-dimension-mobile);
      width: var(--profile-picture-dimension-mobile);
    }
  }
}
