.bordered {
  border: 1px solid var(--border-color);
  border-radius: var(--square-border-radius);
}

.link-color-bordered {
  border-color: var(--link-color);
  border-radius: var(--square-border-radius);
}

.border-radius {
  border-radius: var(--square-border-radius);
}

.border-radius-top {
  border-top-left-radius: var(--square-border-radius);
  border-top-right-radius: var(--square-border-radius);
}

.border-radius-bottom {
  border-bottom-left-radius: var(--square-border-radius);
  border-bottom-right-radius: var(--square-border-radius);
}

.border-radius-left {
  border-top-left-radius: var(--square-border-radius);
  border-bottom-left-radius: var(--square-border-radius);
}

.border-radius-right {
  border-top-right-radius: var(--square-border-radius);
  border-bottom-right-radius: var(--square-border-radius);
}

.bordered-thumbnail {
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: var(--profile-image-border-radius);
  line-height: 0;
}

.border-bottom-highlight {
  border-bottom: solid 4px var(--highlight-color) !important;
}

.bordered-bottom {
  border-bottom: solid 1px var(--border-color);
}

.bordered-top {
  border-top: solid 1px var(--border-color);
}

.bordered-left {
  border-left: solid 1px var(--border-color);
}

.bordered-right {
  border-right: solid 1px var(--border-color);
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border: none;
}

.line-break {
  border-bottom: solid 1px var(--light-gray);
}

.hover-highlight-border {
  &:hover {
    border: 1px solid var(--highlight-color) !important;
  }
}

.border-color-highlight {
  border: 1px solid var(--highlight-color) !important;
}

.border-color-ai {
  background-image: linear-gradient(white, white), linear-gradient(258.12deg, var(--ask-ai-end-color) 10.82%, var(--ask-ai-start-color) 104.37%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 1px solid transparent !important;
  border-radius: var(--ai-border-radius) !important;
}

.border-color-ai-light {
  border: 1px solid var(--ask-ai-border-color);
}
