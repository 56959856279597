.KebabMenu {
  height: 27px;
  width: 27px;

  &:hover {
    border-radius: 100%;
    background-color: var(--kebab-menu-background-color);
  }

  &.lightColor {
    &:hover {
      background-color: var(--kebab-menu-light-background-color);
    }
  }

  &.kebab {
    svg {
      transform: rotate(90deg);
    }
  }
}
