.Sortable {
  user-select: none;
  table-layout: unset;
  border-collapse: separate;

  &.cursorGrab{
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab; /* Firefox 1.5-26 */
    cursor: grab; /* W3C standards syntax, should come least */
  }

  &.isSaving {
    opacity: 0.3;
  }
}

.Sortable-isDragging {
  pointer-events: auto !important;
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
  list-style: none;
  z-index: $zindex-modal-backdrop;
  background-color: var(--background-color);
  border: 1px dashed rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: table;

  .cursorGrab{
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: -webkit-grabbing; /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grabbing; /* Firefox 1.5-26 */
    cursor: grabbing; /* W3C standards syntax, should come least */
  }
}
