.DateTimePicker {
  &.isDisabled {
    background-color: var(--lightest-gray);
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.OnlyDate {
  .react-datepicker {
    min-width: inherit !important;
  }

  .react-datepicker__month-container {
    width: 100% !important;
  }
}
