.SendPreviewSmsModal {
  .modal-body {
    .MobilePhoneIcon {
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}
