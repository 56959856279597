.PaginationContainer {
  .entriesText {
    width: 30%;
    color: var(--text-color-secondary);
  }
  .pagination {
    margin-right: 30%;
    width: 40%;
    .arrow {
      svg {
        height: 18px;
        width: 18px;
        margin-top: -1px;
        fill: var(--text-color-primary);
      }

      &.left{
        margin-right: 15px;
        margin-left: 0px;
      }

      &.right {
        margin-right: 0px;
        margin-left: 15px;

        svg {
          transform: rotate(180deg);
        }
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.28;
        svg {
          fill: var(--text-color-secondary);
        }
      }

      &.double {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    li {
      a {
        color: var(--text-color-secondary);
      }

      &.active, &.active a {
        font-weight: 600;
        color: var(--text-color);
      }
    }
  }

  @include up-to-medium-size {
    justify-content: center;
    .entriesText {
      width: 100%;
      text-align: center;
    }
    .pagination {
      margin-right: 0;
      width: 100%;
    }
  }

  &.CursorPaginationContainer {
    .pagination {
      width: 100%;
      margin-right: 0;
    }
  }
}
