.TypographyPage {
  section {
    > h3 {
      padding-left: 24px;
    }
  }

  dl {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-row-gap: 16px;
    max-width: 905px;

    dt {
      padding: 44px 24px;
      margin: 0;
    }

    dd {
      padding: 44px 44px;
      margin: 0;
    }

    dt:nth-child(4n+3),
    dd:nth-child(4n) {
      background-color: #F5F4FD;
    }
  }
}

.IconsPage {
  table {
    td {
      svg {
        max-height: 100px;
        max-width: 100px;

        &.CloseIcon {
          position: static;
        }
      }
    }
  }
}
