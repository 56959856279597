.ShoutoutCompanyValues {
  .ShoutoutCompanyValueImg {
    svg {
      width: 13px;
      height: 13px;

      g {
        stroke: var(--text-color-primary);
      }
    }
  }

  .ShoutoutCompanyValue {
    @extend .bordered;

    border-radius: var(--square-border-radius-large);
    background-color: var(--card-background-color);
    padding: 3px 10px;

    @include small-size {
      height: 1.5rem;
      padding: 0.5rem;
    }
  }

  .ShowMore {
    @extend .bordered, .d-flex, .pointer;

    padding: 3px;
    border-radius: var(--square-border-radius-large);
  }
}
