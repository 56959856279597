.EditableByLink-addPencilIcon {
  position: relative;
  top: -1px;

  svg {
    width: 12px;
    height: 12px;
    fill: var(--subnav-text-color);
  }
}
