section.ProfileBadges {
  @extend .ProfileCard;
  
  margin-top: 1rem;
  grid-column: 1 / span 3;

  @include small-size {
    grid-row: 2;
    grid-column: 1 / span 12;
    margin-top: 14px;
  }

  .badge-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px 10px;

    div.AwardedBadge {
      border: var(--profile-awarded-badge-border-width) solid var(--border-color);
      border-radius: 6px;

      img {
        width: 100%;
      }
    }
  }

  div.ShowAllBadgesLink {
    @include linkText(--link-color, --link-font-weight);
  }
}

div.BadgePopoverContent {
  max-width: 18rem;
  text-align: center;
  word-break: break-word;
}
