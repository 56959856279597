div.CollapsibleCard {
  box-shadow: var(--card-box-shadow);
  border-radius: 7px;
  background-color: var(--white-pure);

  .chevronIcon {
    svg {
      width: 22.5px;
      height: 22.5px;
      fill: var(--link-color);
    }
  }
}
