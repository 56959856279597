$profile-photos-dropzone-hover-background: #eeeeee;

.ImageSelector {
  max-width: 100px;
  cursor: pointer;
}

.ImageSelector-imagePreview {
  position: relative;
  overflow: hidden;

  > img {
    width: 100%;
    position: absolute;
    top: 0;
  }
}

.ImageSelector.CustomLogo {
  img {
    height: var(--logo-height);
    width: var(--logo-width);
  }
}

.ImageSelector-dropzone {
  border: 1px dashed var(--border-color);
  border-radius: var(--square-border-radius);
  position: relative;
  width: 100%;
  padding-top: 100%;

  .ImageSelector-text {
    color: $placeholder-color;
    font-size: $label-font-size;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: $profile-photos-dropzone-hover-background;
  }
}
