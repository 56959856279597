section.ProfileSchedule {
  @extend .ProfileCard;

  .card-body {
    padding-right: 0.5rem;
  }

  grid-column: 10 / span 3;
  grid-row: 1 / span 3;

  @include small-size {
    grid-column: 1 / span 12;
  }

  .CalendarEventsList {
    overflow-y: scroll;
    max-height: 230px;
  }

  @include small-size {
    grid-row: 1;
    grid-column: 1 / span 12;
  }
}
