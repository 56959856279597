.PreboardingPagesNav {
  .PreboardingPageLinkRow {
    min-width: 100%;
    position: relative;
    height: 32px;

    &.isCurrentPage {
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
    }
  
    .PreboardingPageLink {
      line-height: normal;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      font-weight: 500;
      font-size: 13px;
  
      @include up-to-medium-size {
        font-size: 15px;
      }
    }
  }
}
