.ArticleContentEditor {
  .ArticleTitleInput {
    width: 100%;
    font-size: var(--h3-font-size);
    font-weight: 700;
  }

  .ArticleTitleInput::placeholder {
    color: var(--text-color-secondary);
  }

  .TiptapEditorContent {
    min-height: 150px;
  }

  .ArticleTypeSelect {
    width: 300px;
  }

  .TagsSelect, .ArticleTypeSelect, .ArticleByLineEditor {
    .ClearyReactSelect__menu {
      z-index: $zindex-dropdown;
    }
  }
}
