.MultipleRefinedFilters {
  & > div::after {
    content: '';
    display: block;
    height: 1px;
    // top, margin and width properties
    // have to be in sync with the padding
    // set in the react component
    position: relative;
    top: 2rem;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    background-color: var(--border-color);
  }

  & > div:last-of-type::after {
    display: none;
  }

  & > div:last-of-type {
    padding-bottom: 0 !important;
  }
}
