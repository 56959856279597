.ColorSelect {
  width: 40px;
  height: 40px;

  .ColorSelectPreview {
    width: 40px;
    height: 40px;
  }

  .BlockColorPicker--top {
    bottom: 50px;
  }

  // this is the triangle that points to the box
  .block-picker > div:nth-child(1) {
    left: 20px !important;
  }
}
