.highlighted-event-vertical-rule {
  height: 100%;

  &:before {
    content: '';
    height: 110%;
    width: 1px;
    background: var(--light-gray);
    position: absolute;
    top: -1.25rem; // matches boostraps card top padding.
  }
}

.recent-question-cards {
  .card:not(:first-child) {
    margin-top: 1rem;
  }
}
