.TruncatedFadedContent {
  .Truncation-Overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);

    a {
      top: 4rem;
    }

    &.Expanded {
      position: relative;
      padding: 0;

      a {
        top: 1rem;
      }
    }
  }
}
