div.GroupPostAudienceModal {
  .modal-header {
    z-index: $zindex-tooltip;
  }

  .modal-content {
    padding: 10px;
  }

  .AudienceCount {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    height: calc(80vh - var(--nav-height));
  }

  .ItemList {
    overflow-y: auto;
    height: 100%;
  }

  .GroupItem {
    &:hover {
      background-color: var(--background-color);
    }

    .GroupMiniCardPhoto {
      width: 35px;
      height: 35px;
    }
  }
}
