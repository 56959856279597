.BuddiesTable {
  .CriteriaFilterSelect {
    width: 140px;
  }

  .EmployeeOrCorrespondentLink {
    .CorrespondentIcon {
      svg {
        height: 22px !important;
        width: 22px !important;
      }
    }

    .EmployeeThumbnailPhoto {
      width: 22px !important;
      height: 22px !important;
    }
  }

  .white-bg-table {
    table-layout: auto;
    width: 100%;
  }

  .white-bg-table-available {
    tbody {
      tr {
        td:nth-last-child(3) {
          width: 50px;
        }

        td:nth-last-child(2) {
          width: 80px;
        }

        td:last-child{
          width: 80px;
        }
      }
    }
  }

  .white-bg-table-assigned {
    tbody {
      tr {
        td:last-child{
          width: 100px;
        }
      }
    }
  }

  .white-bg-table-eligible {
    tbody {
      tr {
        td:nth-last-child(2) {
          width: 80px;
        }

        td:last-child{
          width: 190px;
        }
      }
    }
  }

  .white-bg-table-inactive {
    tbody {
      tr {
        td:nth-last-child(2) {
          width: 50px;
        }

        td:last-child{
          width: 80px;
        }
      }
    }
  }
}
