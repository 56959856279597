section.ProfileTeamsAndRelationships {
  grid-column: 4 / span 6;
  grid-row: 2 / span 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid var(--border-color);

  @include small-size {
    grid-column: 1 / span 12;
    grid-row: 5;
    border-right: none;
    padding-bottom: 2rem;
    border-top: 1px solid var(--border-color);
  }

  section.PeopleList {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    span.TeamSize {
      border-radius: 100px;
      font-size: 11px;
      padding: 5px 7px;
      color: #7E7B96;
      background-color: var(--border-color);
      line-height: 100%;
    }
  }
}
