.Profile-contactLink-icon {
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.ProfileField-text {
  font-size: 0.938em;
  flex: 1;
}

.ProfilePronounField {
  width: 8em;
}
