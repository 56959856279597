.CarouselCardsWidget {
  &.fullWidth {
    &:not(.isEditing) {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
    }

    .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div {
      border-radius: 0 !important;
    }

    .CarouselCard {
      border-radius: 0 !important;

      .ProgressiveImage, .TextContainer {
        border-radius: 0 !important;

        img {
          border-radius: 0 !important;
        }
      }
    }
  }

  .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div {
    height: 100%;
    border-radius: var(--square-border-radius);
  }

  .slick-arrow {
    width: 32px;
    height: 32px;
    z-index: $zindex-one;
    background-color: rgba(117, 117, 117, 0.15);

    g, path {
      fill: var(--white-pure);
      stroke: var(--white-pure);
    }
  }

  .slick-next {
    right: 0.25rem;
  }

  .slick-prev {
    left: 0.25rem;
  }
}
