.popover {
  border: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: var(--square-border-radius);
  max-width: unset;
}

.AppModal {
  .modal-header {
    button.close {
      padding: 1rem 1rem 0rem 1rem;
      display: flex;
    }
  }
}

.card {
  background-color: var(--card-background-color);
}

@media print {
  @page {
    size: auto;
  }
}
