div.ResponseDetailPage {
  .profile-image {
    height: 100px;
    width: 100px;
  }

  .AdminContent {
    max-width: 1085px;
  }
}
