div.ToggleSwitch {
  .switch-container {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .switch-slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--light-gray);
      transition: 0.4s;
      border-radius: 14px;
    }

    .switch-slider:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }

    input:checked + .switch-slider {
      background-color: var(--link-color);
    }

    input:checked + .switch-slider:before {
      transform: translateX(15px);
    }
  }
}
