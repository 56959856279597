.PageContentEditor {
  .CoverImageWidget {
    margin: auto;
    max-width: var(--page-width-narrow);

    &.hasCoverImage {
      max-width: 100%;
    }

    &.showFullWidth {
      max-width: 100%;
    }
  }

  .TitleAndHtml {
    transition: all 0.3s ease-in-out;
    width: 100%;

    &.feedbackEnabled {
      width: calc(100% - var(--feedback-counter-width))
    }
  }

  .TiptapEditor {
    min-height: 250px;
  }
}
