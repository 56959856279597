.FeedbackCounter {
  width: var(--feedback-counter-width);

  @include small-size {
    display: none !important;
  }

  svg {
    @extend .pointer;

    width: 18px;
    height: 18px;
    fill: var(--light-mid-gray);
  }

  .ThumbsUp, .ThumbsDown {
    width: 100%;

    svg {
      @extend .ml-2;
    }
  }

  .ThumbsUp {
    svg {
      &:hover {
        path {
          fill: var(--helpful-color);
        }
      }
    }
  }

  .ThumbsUp.votedHelpful {
    svg {
      path {
        fill: var(--helpful-color);
      }
    }
  }

  .ThumbsDown {
    svg {
      &:hover {
        path {
          fill: var(--not-helpful-color);
        }
      }
    }
  }

  .isAdditionalInformationModalOpen,
  .ThumbsDown.votedNotHelpful {
    svg {
      path {
        fill: var(--not-helpful-color);
      }
    }
  }

  .Divider {
    position: relative;
    height: 1px;
    width: 100%;
  }

  .Divider::after {
    content: '';
    display: block;
    position: absolute;
    left: 40%;
    top: 0;
    width: 50%;
    border-bottom: 1px solid var(--light-mid-gray);
  }
}

.FeedbackCounterPopover {
  background-color: var(--black);

  .arrow {
    &::after {
      border-right-color: var(--black) !important;
    }
  }
}
