@keyframes new-card-item-fade {
  0% {
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
  }

  50% {
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
  }

  100% {
    background-color: var(--card-background-color);
  }
}


@keyframes table-row-fade {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    position: fixed;
    opacity: 0;
    top: -100px;
    left: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes grow-from-center {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bounce {
	0% {
    transform: translateY(+50px);
  }

  20% {
    transform: translateY(-10px);
  }

	40% {
    transform: translateY(+30px);
  }

	60% {
    transform: translateY(-5px);
  }

	80% {
    transform: translateY(+5px);
  }

	100% {
    transform: translateY(0);
  }
}

@keyframes mirror-bounce {
	0% {
    transform: translateY(+50px) scale(-1, 1);
  }

  20% {
    transform: translateY(-10px) scale(-1, 1);
  }

	40% {
    transform: translateY(+30px) scale(-1, 1);
  }

	60% {
    transform: translateY(-5px) scale(-1, 1);
  }

	80% {
    transform: translateY(+5px) scale(-1, 1);
  }

	100% {
    transform: translateY(0) scale(-1, 1);
  }
}

@keyframes float-away {
  0% {
    transform: translateY(+110vh) rotate(0deg);
    z-index: $zindex-base;
  }

  25% {
    transform: translateY(+75vh) rotate(25deg);
  }

  50% {
    transform: translateY(+50vh) rotate(0deg);
  }

  75% {
    transform: translateY(+25vh) rotate(-25deg);
  }

  100% {
    transform: translateY(0) rotate(0deg);
    z-index: $zindex-base;
  }
}
