.TiptapMoreDropdownButton {
  position: relative;

  .ThreeDotsIcon {
    svg {
      rotate: 90deg;
      width: 16px;
      height: 16px;
    }
  }
}
