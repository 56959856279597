.TableWithFixedColumns {
  overflow-x: auto;
  width: 100%;

  table {
    border-right: 1px solid var(--border-color);

    thead {
      border-bottom: 1px solid var(--border-color);
    }

    &.scrollable-table {
      border-right: none;
    }
  }
}
