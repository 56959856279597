.FullPageEditor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-fixed;
  display: grid;
  grid-template-rows: auto 1fr;

  &--sidebar-left {
    grid-template-areas:
      "header header"
      "sidebar main";

    grid-template-columns: auto 1fr;
  }

  &--sidebar-right {
    grid-template-areas:
      "header header"
      "main sidebar";

    grid-template-columns: 1fr auto;
  }

  .FullPageEditor__Header {
    grid-area: header;
  }

  .FullPageEditor__Sidebar {
    grid-area: sidebar;
    overflow-y: auto;
  }

  .FullPageEditor__Main {
    grid-area: main;
    overflow-y: auto;
  }
}
