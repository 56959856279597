main.GroupPage {
  section.group-info-grid {
    @include default-page-grid;

    grid-auto-flow: column;
    background-color: var(--white-pure);
    box-shadow: var(--card-box-shadow);
    padding-top: 35px;
    padding-bottom: 35px;

    div.GroupPrimaryPhoto {
      grid-column: 1 / span 3;
      grid-row: 1 / span 3;

      @include up-to-medium-size {
        grid-column: 1 / span 12;
      }
    }

    div.PictureList {
      grid-column: 1 / span 3;
      grid-row: 5 / span 1;

      @include up-to-medium-size {
        grid-column: 1 / span 12;
        grid-row: 9 / span 1;
      }
    }

    /* TODO: Extract */
    div.AddCoverImageDropzone {
      grid-column: 1 / span 3;
      justify-self: center;
      grid-row: 4 / span 1;

      svg {
        width: 14px;
        height: 14px;
        vertical-align: baseline;
        fill: var(--btn-primary-color);
      }

      @include up-to-medium-size {
        grid-column: 1 / span 12;
      }
    }
  }

  section.group-card-grid {
    @include default-page-grid;

    padding-top: 35px;
    padding-bottom: 40px;

    section.ContactFollowersAndKeyLinks {
      grid-column: 1 / span 3;
      grid-row: 1 / span 3;

      @include up-to-medium-size {
        grid-column: 1 / span 12;
        grid-row: 2 / span 1;
      }
    }
  }
}
