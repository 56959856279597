.PreboardingFeedWidget {
  .confetti-background:before {
    width: 40%;
    right: 0;
    left: unset;
    opacity: 1;
  }

  .ManWithOkHand {
    position: relative;
    top: 1.25rem;
  }
}
