.ColumnsSelector {
  .form-check label {
    font-weight: 400;
  }

  .Checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 5px;
  }
}
