.EditFollowersModal {
  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .GroupFollowerCount {
    background-color: var(--dynamic-color-link-color-lightest--link-color);
    color: var(--link-color);
    font-weight: 600;
    border-radius: var(--square-border-radius);
    font-size: 13px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    max-height: 28px;

    svg {
      height: 16px;
      width: 16px;
      fill: var(--link-color);
    }
  }
}
