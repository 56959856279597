.TemplatesNav {
  width: var(--right-nav-width);
  transition: right 0.5s ease-in-out;
  background: var(--left-navbar-background-color);
  z-index: $zindex-popover;

  .SeeMoreTemplates {
    color: var(--link-color);
  }

  .TemplateListItem {
    background-color: white;
    border: 1px solid var(--light-gray);
    border-radius: 5px;
    height: 40px;

    .PreviewText{
      display: none;
    }

    &:hover {
      .PreviewText {
          display: block;
      }
    }

    &.Selected, &:hover {
      border-color: var(--link-color);
    }
  }

  &.modalView {
    width: var(--templates-nav-modal-width);
  }

  *:focus {
      // remove default blue outline when any child has focus
      outline: none;
  }

  .CloseIcon {
    transform: scale(0.8);
    stroke: var(--mid-gray);

    &:hover {
      stroke: var(--dark-gray);
    }
  }

  @include small-size {
    display: none !important;
  }
}
