div.OrgChartPage {
  background-color: var(--background-color);

  button.ToggleLeftNavButton {
    z-index: $zindex-base !important;

    @media print {
      display: none !important;
    }

    &.isOpen {
      // when the nav is open - move the button the edge of the nav
      left: calc(var(--org-chart-panel-width) - 10px);
    }
  }

  .OrgChartContainer {
    margin-left: calc(-1 * var(--org-chart-panel-width));
    transition: margin-left 0.5s ease-in-out;
    width: 100vw;
    height: calc(100vh - (3 * var(--nav-height)));

    @include small-size {
      margin-left: 0;
      height: calc(100vh - var(--nav-height));
    }

    @media print {
      height: 100vh;
    }

    &.isProfilePreviewOpen {
      margin-left: 0;
      width: calc(100vw - var(--org-chart-panel-width));

      @include small-size {
        width: 100vw;
      }

      @media print {
        width: 100vw;
      }
    }

    path {
      stroke: var(--orgchart-border-color);
      stroke-width: 1px;
      stroke-linejoin: round;
    }

    path.link-active {
      stroke: var(--highlight-color) !important;
      stroke-width: 1px !important;
    }
  }
}
