.isvg {
  &.w-16 {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.w-18 {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
