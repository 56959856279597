.tribute-container {
  bottom: unset !important;
  border-radius: var(--square-border-radius);
  border: 1px solid var(--border-color) !important;
  margin-left: 0.5em;

  -webkit-box-shadow: 0px 1px 4px $mid-gray;
  -moz-box-shadow: 0px 1px 4px $mid-gray;
  box-shadow: 0px 1px 4px $mid-gray;

  overflow: hidden; // hides the scrollbar

  &:hover {
    overflow-y: scroll; // displays the scrollbar on hover
  }

  ul {
    margin: 0;
  }

  li {
    padding: 0 !important;

    .EmployeeMiniCard {
      border-radius: 0;
      min-width: 200px;
      img {
        height: 2.5em;
        width: 2.5em;
      }
    }
  }
  li:hover,
  li.highlight {
    background: transparent !important;

    .EmployeeMiniCard {
      background: var(--search-bg-color) !important;
    }
  }

  ul {
    background: var(--background-color);
  }
}
