.IcebreakerQuestionModal {
  max-width: 700px !important;

  .Icebreaker-template-type {
    min-width: 120px;
    height: 100px;
    flex: 1 1 0;

    &.Icebreaker-template-type--not-selected {
      opacity: 0.5;
    }

    // For specificty, Icebreaker-template-type--selected
    // should be after Icebreaker-template-type--not-selected
    &:hover,
    &.Icebreaker-template-type--selected {
      opacity: 1;
      color: var(--icebreaker-label-color);
      border-color: var(--icebreaker-label-color);
      svg > g > g {
        fill: var(--icebreaker-label-color);
      }
      svg > path {
        fill: var(--icebreaker-label-color);
      }
    }
  }

  .Icebreaker-template-list {
    max-height: 250px;
    overflow-y: auto;
  }

  .Icebreaker-template-list > div:last-of-type {
    border: none;
  }

  .Icebreaker-question {
    border-bottom: solid 1px var(--card-border-gray);

    .Icebreaker-question--select {
      visibility: hidden;
      min-width: inherit;
    }
  }

  .Icebreaker-question {
    &:hover {
      background-color: var(
        --dynamic-color-card-dropdown-item--card-background-color
      ); //color(var(--card-background-color) shade(5%));

      .Icebreaker-question--select {
        visibility: visible;
      }
    }
  }
}
