.ArticleEditorNavbar, .ArticleTemplateEditorNavbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  .BackAndStatusButtons {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .ExitButton {
      button {
        height: 2.143rem; // small button
        min-width: 100px;
      }
    }
  }

  .ArticleTitle {
    flex: 1 1 0;
    text-align: center;
  }

  .ActionButtons {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    button {
      height: 2.143rem; // small button
    }
  }

  .DropdownItem {
    justify-content: flex-start !important;
    gap: 0.5rem;

    svg {
      width: 20px;
    }
  }

  .CircledCrossIcon {
    svg {
      fill: var(--danger-color);
    }
  }

  .CircledPlusIcon {
    .isvg, svg {
      width: 20px;
      height: 20px;
      fill: var(--highlight-color);
    }
  }
}

.AlertTriangleIcon {
  svg {
    background-color: var(--yellow-warning-color);
    fill: var(--yellow-warning-color);
    border-radius: var(--square-border-radius);
  }
}
