.ShoutoutsList {
  @include default-page-grid;

  .ShoutoutsListHeader, .ShoutoutsListPillTabs, .ShoutoutsListInfiniteScroller {
    grid-column: 3 / span 8;

    @include large-size {
      grid-column: 2 / span 10;
    }

    @include up-to-medium-size {
      grid-column: 1 / span 12;
    }
  }

  .PillTab.isLoading {
    pointer-events: none;
    opacity: 0.8;
  }
}
