.AskAiNavbarButton {
  font-size: 1rem;
  padding: 0 14px;
  min-width: auto;
  border-radius: var(--square-border-radius) !important;
  padding: 0 !important;
}

.AskAiModal {
  position: fixed;
  top: 0;
  right: 20px;
  width: 100%;
  max-width: 650px;
  height: calc(100vh - var(--nav-height) - 40px);
  max-height: 1100px;
  z-index: 1000;
  box-shadow: var(--card-box-shadow);
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  .AskAiModal-header {
    background: linear-gradient(258.12deg, var(--ask-ai-end-color) 10.82%, var(--ask-ai-start-color) 104.37%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 1px solid var(--ask-ai-border-color);
    border-bottom: none;
    box-shadow: var(--card-box-shadow);
    position: relative;

    h4 {
      font-size: 16px;

      .title-text {
        position: relative;
        top: 1px;
      }
    }

    button {
      background: transparent;
      border: none;
    }

    .clock-icon {
      svg {
        width: 24px;
      }
    }

    .CloseIcon {
      height: auto;
      width: 18px;
      position: initial;

      &, &:hover {
        stroke: $white-pure;
        fill: $white-pure;
      }
    }
  }

  .AskAiModal-subheader {
    background: linear-gradient(258.12deg, var(--ask-ai-end-color) 10.82%, var(--ask-ai-start-color) 104.37%);
    border: 1px solid var(--ask-ai-border-color);
    border-bottom: none;
    border-top: none;

    .greeting-small {
      font-size: 18px;
    }

    .greeting-normal {
      font-size: 24px;
    }
  }

  .AskAiModal-body {
    overflow-y: auto;
    background: white;
    min-height: 200px;
    border: 1px solid var(--ask-ai-border-color);
    border-bottom: none;
  }

  .AskAiModal-footer {
    display: block;
    background: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid var(--ask-ai-border-color);
    border-top: none;
  }

  .AskAiModal-input-group {
    position: relative;

    input {
      padding-right: 50px;
      height: 39px;
      font-size: 13px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.25);
      }

      &:focus {
        border-color: var(--link-color);
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      padding: 5px;
    }

    svg {
      fill: var(--highlight-color);
    }
  }

  .AiOverview__container {
    max-width: 80%;
  }

  .AiOverview {
    .card-body {
      .TiptapView {
        &, * {
          font-size: 13px;
        }
      }
    }
  }

  .collapsable-greeting {
    transition: height 0.2s ease-in-out;
    overflow: hidden;
  }
}

.UserMessage {
  max-width: 80%;
  margin-left: auto;

  .UserMessage-content {
    word-break: break-word;
    background-color: var(--highlight-color);
    font-size: 13px;
  }
}

.AiGreetingMessage-button {
  color: var(--text-color-primary);
  border-radius: 8px !important;
  font-weight: 400 !important;
  padding: 7px 14px;
  height: auto;
  width: auto;
}

.AiMessage .CopyButtonSmallNarrow {
  position: relative;
  left: 10px;
  min-width: initial !important;
}
