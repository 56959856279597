.ToggleMenuButton {
  z-index: $zindex-toolbar;
  position: absolute;
  left: 10px;
  top: 22px;
  transition: left 0.5s ease-in-out;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--square-border-radius-small);

  &:hover {
    cursor: pointer;
    background-color: var(--dynamic-color-secondary-color-lightest--secondary-gray);

    svg {
      path {
        fill: var(--text-color-primary);
      }
    }
  }

  &.isOpen {
    left: calc(var(--main-left-navbar-extended-width) + 10px);
  }

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: var(--text-color-primary);
    }
  }
}
