section.ProfileAboutMe {
  margin-top: 20px;
  width: 100%;
  background-color: var(--white-pure);
  border-radius: 8px;
  padding: 20px 20px 24px 20px;
  box-shadow: var(--card-box-shadow);
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include small-size {
    align-items: center;
    border-right: none;
    padding-bottom: 2rem;
  }

  div.CustomField {
    width: 100%;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  .PlusIcon {
    svg {
      width: 18px;
      height: 18px;
      vertical-align: text-top;
    }
  }

  div.UserSkillType {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }
}
