div.RespondSurveyPage,
div.FormPreviewPage {
  .buttons-container {
    width: 100%;

    .back-button-container {
      width: 33%;
    }

    .progress-bar-container {
      width: 33%;
    }

    .next-button-container {
      display: flex;
      justify-content: flex-end;
      width: 33%;
    }

    @include up-to-medium-size {
      .progress-bar-container {
        display: none;
      }

      .next-button-container {
        width: 50%;
      }

      .back-button-container {
        width: 50%;
      }
    }
  }
}


div.FormPreviewPage {
  .preview-container {
    max-width: 1085px;
  }
}
