.ShoutoutModal {
  .ShoutoutIcon {
    svg {
      height: 40px;
      width: 40px;
      path {
        fill: var(--btn-primary-color);
      }
    }
  }

  span.ShoutoutCompanyValue {
    border: solid 1px transparent;
    box-shadow: 1px 3px 6px 0 rgb(0 0 0 / 12%);

    @include hover-device {
      &:hover {
        border: solid 1px var(--highlight-color) !important;
      }
    }

    &.isSelected {
      border: solid 1px var(--highlight-color) !important;
    }

    &.isNonSelectable {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .GifSelector--Wrapper {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
