span.UserSkillTag {
  display: inline-block;
  border: 0.5px solid var(--skill-tag-border-color);
  border-radius: var(--square-border-radius);
  background-color: var(--skill-tag-background-color);
  padding: 3px 10px;
  color: var(--skill-tag-text-color);
  margin: 5px 10px 5px 0;

  svg {
    margin-left: 12px;

    path {
      fill: var(--tag-text-color);
    }
  }

  &:hover {
    background-color: var(--skill-tag-hover-background-color);
    color: var(--skill-tag-hover-text-color);
    cursor: pointer;

    svg {
      path {
        fill: var(--tag-highlight-text-color);
      }
    }
  }
}
