.MobileLeftNav {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: $zindex-popover;
  background-color: var(--nav-color);
  border-top: 1px solid var(--border-color);
  gap: 32px;
  padding-bottom: 80px;

  .GlobalSearch {
    width: 100%;
    max-width: 100%;
  }

  .Navbar-item {
    margin-right: 1rem;

    a {
      font-weight: 600;
      color: var(--text-color-primary);

      &.active {
        color: var(--nav-text-active-color);
      }
    }
  }

  .Mobile-subnav {
    @extend .mt-3;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 25px;
    row-gap: 16px;

    .Navbar-item {
      a {
        font-weight: 400;
        color: var(--text-color-secondary);

        &.active {
          color: var(--nav-text-active-color);
        }
      }
    }
  }
}
