.EventNotificationWidget {
  &.isDraft {
    .card-body {
      @extend .p-0;
    }
  }

  .btnZoomArticle {
    color: var(--success-color) !important;
    background: var(--white);

    &:focus,
    &:hover,
    &:active {
      box-shadow: none;
      background: var(--white) !important;
      opacity: 0.9 !important;
    }
  }

  .btnZoomArticleArchived {
    color: var(--orange-warning-color) !important;
    background: var(--white);

    &:focus,
    &:hover,
    &:active {
      box-shadow: none;
      background: var(--white) !important;
      opacity: 0.9 !important;
    }
  }

  .DraftPublishAlert {
    .AlertCircleIcon {
      height: 50px;
    }

    svg {
      fill: white !important;
    }

    button.btn-primary {
      color: var(--orange-warning-color) !important;
      background: var(--white);

      &:focus,
      &:hover,
      &:active {
        box-shadow: none;
        background: var(--white) !important;
        opacity: 0.9 !important;
      }
    }
  }
}
