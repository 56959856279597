.VersionHistoryNav {
  width: var(--right-nav-width);
  transition: right 0.5s ease-in-out;
  background: var(--left-navbar-background-color);
  overflow-y: auto;
  z-index: $zindex-modal;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  .SnapshotItem {
    background: var(--white-pure);
    box-shadow: var(--card-box-shadow);
    border-left: 5px solid var(--secondary-gray);
    margin-left: 20px;

    &:hover {
      border-width: 10px;
      margin-left: 15px;
    }

    &.isSelected {
      border-left: 10px solid var(--link-color);
      margin-left: 0px !important;
    }
  }
}

.RestoredStatusLabelTooltip {
  .tooltip-inner {
    font-size: 0.867rem; // text-small
  }
}

.VersionHistoryNavContent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white-pure);
  width: calc(100% - var(--right-nav-width));
  height: 100%;
  z-index: $zindex-modal;

  .version-title {
    width: 100%;
    border-bottom: 1px solid var(--secondary-gray);
  }
}
