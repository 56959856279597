.RichTextInlineComment {
  background-color: var(--editor-toolbar-background-color);
  border-radius: var(--square-border-radius);
  animation: inline-comment-fade-in 1s ease-in-out;
}

@keyframes inline-comment-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
