.AlertCircleIcon {
  &-circle {
    fill: white;
    fill-opacity: 0.5;
  }
}

.AddPencilIcon {
  fill: var(--kebab-menu-icon-color);
  width: 16px;
  height: 16px;
}

.CommentIcon {
  &-rect {
    fill: #000;
    fill-rule: nonzero;
    opacity: 0;
  }

  &-path {
    fill: var(--text-color-primary);
  }
}
