.PageExpirationText {
  color: var(--text-color-secondary);

  &.isExpiringSoon {
    color: var(--danger-color);
  }

  &.isEditing {
    &:hover {
      color: var(--link-color);
    }
  }
}
