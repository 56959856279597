/*  import everything other than _variables.scss and _tiptapView.css files here
other stylesheets should import their own customized variables, then import this file */
@import "bootstrap/dist/css/bootstrap.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "react-toastify/dist/ReactToastify.min.css";
@import "@uppy/core/dist/style.css";
@import "@uppy/dashboard/dist/style.css";
@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";
@import "@xyflow/react/dist/style.css";
@import "plyr/dist/plyr.css";
@import "font-awesome/css/fonts/*";
@import "_mixins.scss";
@import "vendor/*";
@import "global/*";
@import "utils/*";
@import "components/**";

/* include scss files that are co-located alongside components, pages */
@import "../components/**";
@import "../pages/**";
@import "../publicApps/**";
