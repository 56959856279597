.AdditionalInformationModal {
  width: 595px;

  .FeedbackForm {
    gap: 1rem;

    .FeedbackCheckboxes {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0.5rem;

      input[type=checkbox] {
        accent-color: var(--btn-primary-color);
      }
    }

    .Comments {
      resize: none;
    }
  }
}
