.SayThanksModal {
  .confetti-background:before {
    background-size: 220px 150px;
  }

  .ThankYouImage {
    margin-bottom: -3rem;
  }

  .TiptapEditor {
    border: 1px solid var(--border-color);
    background-color: var(--white-pure);
  }

  .your-message {
    background-color: #eee;
    color: var(--text-color-secondary);
  }

  .text-helper {
    span {
      font-weight: 600;
    }
  }
}
