.MicIcon {
  fill: var(--text-color-secondary);
}

.AudioIcon {
  fill: var(--text-color-secondary);

  svg {
    height: 24px;
    width: 24px;
  }
}
