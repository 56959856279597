.BetaRibbon {
  position: absolute;
  margin: 0;
  padding: 0;
  top:0;
  right:0;
  transform: translateX(25%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  background-color: var(--btn-primary-color);
  letter-spacing: 3.5px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    width: 100%;
    height: 100%;
    background-color: var(--btn-primary-color);
  }

  &:before {
    right:100%;
  }

  &:after {
    left:100%;
  }
}

