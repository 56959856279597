.create-question-icon, .create-run-icon, .create-dataset-icon {
  width: 15px;

  svg {
    fill: white;
  }
}

.comparison-answer-evaluation {
  div {
    font-size: 12px !important;
  }
}
