.ArticleByLine {
  .bullet-separator:not(:first-child)::before {
    content: '•';
    margin: 0 4px;
  }

  .bullet-separator-secondary:not(:first-child)::before {
    content: '•';
    margin: 0 4px;
    color: var(--text-color-secondary);
  }
}

.ArticleByLineEditor__modal {
  .modal-title {
    color: var(--text-color-primary);
    font-size: 21px;
    text-align: center;
  }

  .btn-link {
    color: var(--text-color-secondary);

    &:hover {
      color: var(--text-color-secondary);
      text-decoration: none;
    }
  }
}
