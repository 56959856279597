.CarouselCard {
  height: 100%;

  .ProgressiveImage {
    border-radius: var(--square-border-radius);

    img {
      transition: all 0.3s ease-in-out;
      border-radius: var(--square-border-radius);
      object-fit: cover;
    }
  }


  &:hover {
    .ProgressiveImage {
      img {
        transform: scale(1.1);
      }
    }

    .TextContainer {
      padding-bottom: 1.5rem;
    }
  }

  .TextContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 50%;
    background-image: linear-gradient(0deg, var(--black-pure), transparent);
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: var(--square-border-radius);
    transition: all 0.3s ease-in-out;

    > h2, > div {
      line-height: 1.2;
      max-width: 600px;
    }

    h2:hover {
      text-decoration: underline;
    }
  }
}

.CreateCarouselCardPage, .EditCarouselCardPage {
  .CarouselCard {
    height: 330px;
    width: 850px;
    pointer-events: none;
  }
}
