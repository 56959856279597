.PagePage {
  @media print {
    .FeedbackCounter {
      display: none !important;
    }

    .PageContent {
      overflow-y: visible !important;
    }
  }
}
