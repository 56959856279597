.PeopleModal {
  // react-select overrides
  .ClearyReactSelect__menu,
  .ClearyReactSelect__menu-list {
    max-height: 500px;
    @extend .mb-2;
  }

  .EmployeeMiniCard {
    background-color: inherit;
  }
}
