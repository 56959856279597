.GlobalSearch {
  text-transform: none;
  position: relative;
  z-index: $zindex-modal;

  .Golink_Url {
    height: 40px;
  }

  .Select-option.is-focused {
    background-color: var(--search-bg-color) !important;
  }

  // react-select overrides
  .Select {
    a {
      &:hover {
        color: var(--text-color-primary);
      }
    }
  }

  .Select-option.is-selected {
    background-color: transparent;
  }

  .Select-option.is-selected.is-focused {
    background-color: #ebf5ff;
  }

  .Select-input-all-results {
    height: 43px;
    z-index: $zindex-dropdown;
  }

  .Select-loading {
    margin: 10px;
  }

  .Select-arrow-zone {
    display: none;
  }

  .Select-menu-outer {
    max-height: 400px;
    z-index: $zindex-dropdown;
  }

  .Select-menu {
    max-height: 398px;
  }

  .Select-option {
    padding: 0;
    max-width: 380px;
  }

  .EmployeeMiniCard {
    background-color: inherit;
  }
}

.GlobalSearch__ErrorBoundary {
  background: white;
  border-radius: var(--square-border-radius);

  h2 {
    display: none;
  }

  span {
    font-size: 1em;
  }

  .ErrorBoundary-links {
    margin: 0;
    a {
      color: var(--link-color);
    }
  }
}
