.VideoUploader {
  .uppy-Dashboard-files {
    overflow-y: hidden;

    .uppy-Dashboard-Item {
      padding-bottom: 5px;
      padding-top: 7px;

      .uppy-Dashboard-Item-statusSize {
        font-size: 11px;
      }
    }
  }

  .uppy-Dashboard-AddFiles-title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 15px;
  }

  .uppy-StatusBar-statusPrimary {
    font-size: 13px;
  }

  .uppy-DashboardContent-back,
  .uppy-Dashboard-browse {
    color: var(--link-color);
  }

  .uppy-StatusBar-progress {
    background-color: var(--link-color);
  }

  .uppy-StatusBar-spinner {
    fill: var(--link-color);
  }
}
