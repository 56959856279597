.AcknowledgementBanner {
  color: var(--link-color);
  background-color: var(--dynamic-color-link-color-lightest--link-color);
  border-radius: var(--square-border-radius);

  svg {
    fill: var(--link-color)
  }

  &.acknowledged {
    color: var(--success-color);
    background-color: var(--dynamic-color-success-color-lightest--success-color);

    svg {
      fill: var(--success-color)
    }
  }

  &.overdue {
    color: var(--danger-color);
    background-color: var(--dynamic-color-danger-color-lightest--danger-color);

    svg {
      fill: var(--danger-color)
    }
  }
}
