.AddGoLinkModal {
  margin-top: var(--nav-height);

  .modal-header {
    margin: 0.5rem 0.5rem 0;
    padding-bottom: 0;
  }

  .modal-body {
    margin: 0 0.5rem 0.5rem;
  }

  .AddGoLinkToggleGroup {
    .toggle {
      border: solid 1px var(--border-color);
      border-radius: var(--square-border-radius);
      cursor: pointer;

      .ToggleIcon {
        width: 24px;
        height: 24px;

        svg {
          fill: var(--text-color-secondary)
        }
      }

      &.selected {
        border-color: var(--highlight-color);
        color: var(--highlight-color);

        svg {
          fill: var(--highlight-color)
        }
      }
    }
  }

  .add-link-select {
    .ClearyReactSelect__menu-list {
      padding: 0;
    }

    .ClearyReactSelect__option {
      padding: 1rem;
      cursor: pointer;
    }

    .ClearyReactSelect__option--is-selected {
      background-color: var(--dropdown-hover-background-color) !important;
    }

    .ClearyReactSelect__input {
      height: 38px;
    }
  }

  .GoLinkOption {
    .MainContent {
      display: -webkit-box !important;

      em {
        font-weight: 500;
      }
    }
  }
}
