.MobileViewToolbar {
  display: none;

  @include small-size {
    display: block;
    z-index: $zindex-dropdown;
    position: sticky;
    top: 0;
    height: 50px;
    background-color: var(--background-color);
    color: var(--text-color-primary);

    svg {
      fill: var(--text-color-primary);
    }
  }
}
