.OnboardingBuddiesWidget {
  overflow: hidden;

  &--Title {
    padding: 24px 0px 12px 24px;
    position: relative;
    background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);;
    overflow: hidden;

    h5 {
      margin-bottom: 0;
    }

    .PeoplePreparingToCheer {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }
  }

  .UserCard {
    .EmployeeThumbnailPhoto {
      width: 40px !important;
      height: 40px !important;
      flex-basis: 40px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}
