.CustomHomePage {
  padding: 10px;

  &.hasHeroWidgetAtTop {
    padding-top: 0;

    .CarouselCardsWidget {
      margin-top: -10px;
      height: calc(100% + 10px) !important;
    }
  }

  .react-grid-item{
    transition: none;
  }
}
