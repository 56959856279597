.LinksWidgetSettingsSidebar {
  .PencilIcon, .XIcon {
    display: none;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--text-color-primary);

      &:hover {
        fill: var(--link-color);
      }
    }
  }

  .LinkPreview {
    &:hover {
      .PencilIcon, .XIcon {
        display: block;
      }
    }
  }

  .LinkForm {
    .ClearyReactSelect__input-container {
      // library default is 0 min-content, but this is causing overflowing in the sidebar
      // 1fr will allow the input to stretch to the full width of the container without overflowing
      grid-template-columns: 0 1fr;
    }
  }
}

.isDraggingLink {
  z-index: $zindex-modal-backdrop;
  // width will be left nav - the padding on the left and right
  width: calc(var(--left-nav-width) - 2rem) !important;
}
