// FORMS
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

@mixin disabledFormField() {
  opacity: 0.8;
  cursor: not-allowed;

  &:hover {
    border-color: var(--border-color);
  }
}

input {
  display: block;
  height: 38px;
  margin: 0;
  padding: 0 1em;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;

  &:focus,
  &:hover {
    border-color: var(--highlight-color);
  }

  &:invalid {
    border-color: $error-color;
  }
}

input,
textarea {
  &.error,
  &.error:focus {
    border: 1px solid $error-color;
    background-color: var(--dynamic-color-input-textarea-error--error-color);
  }

  &::placeholder {
    color: var(--text-color-muted);
  }

  &:disabled {
    @include disabledFormField;
  }
}

textarea {
  display: block;
  padding: 1em;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;

  &:focus,
  &:hover {
    border-color: var(--highlight-color);
  }
}

.form-group {
  margin-bottom: 1rem;

  input,
  textarea {
    width: 100%;
  }
}

.form-group-inline {
  margin-bottom: 1rem;

  input {
    display: inline-block;
  }

  label,
  input,
  button {
    margin-right: 1rem;
  }
}

.form-inline-check {
  input {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
  }

  font-weight: 600;
}

.form-group-inline-radio {
  input {
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
    margin-right: 6px;
  }

  input:first-of-type {
    margin-left: 0;
  }
}

.form-check {
  display: flex;
  align-items: center;
  padding-left: 0;

  label {
    margin-bottom: 0;
    font-weight: bold;
  }

  input[type='checkbox'],
  input[type='radio'] {
    width: auto;
    height: auto;
    margin-right: 8px;
  }
}

.required-form-label {
  &:after {
    color: red;
    content: ' *';
  }
}

.btn-primary {
  @include buttonPrimary(--btn-primary-color);
}

.btn-primary-disabled {
  background-color: var(--dynamic-color-button-primary-disabled--btn-primary-color);
  opacity: 0.65;
  cursor: not-allowed !important;

  &:hover {
    background-color: var(--dynamic-color-button-primary-disabled--btn-primary-color) !important;
  }
}

.btn-lg {
  height: 3.429rem !important;
}

.pill-button,
.pill-button-active {
  @extend .pointer, .p-2, .badge, .text-primary, .text-small, .font-weight-normal;
}

.pill-button-active {
  background-color: $secondary-gray;
}

.btn-primary-sm {
  @include buttonPrimary(--btn-primary-color);
  @include buttonSmall();
}

.btn-secondary {
  @include buttonSecondary(--btn-primary-color);
}

.btn-secondary-danger {
  @include buttonSecondary(--danger-color);
  @include linkText(--danger-color, --font-weight-inherit);
}

.btn-secondary-danger-sm {
  @include buttonSecondary(--danger-color);
  @include linkText(--danger-color, --font-weight-inherit);
  @include buttonSmall();
}


.btn-secondary-sm {
  @include buttonSecondary(--btn-primary-color);
  @include buttonSmall();
}

.btn-muted {
  @include buttonMuted(--btn-muted-color);
}

.btn-danger {
  @include buttonPrimary(--danger-color);
}

.btn-text-danger {
  @include linkText(--danger-color, --link-font-weight);
}

.btn-dark {
  @include buttonPrimary(--dark-gray);
}

.btn-dark-sm {
  @include buttonPrimary(--dark-gray);
  @include buttonSmall();
}

// react select overrides, now necessary due to the lack of silvertip overrides.

.Select {
  &.is-focused:not(.is-open) > .Select-control {
    border-color: var(--highlight-color);
    box-shadow: none;
  }

  .Select-input > input {
    padding: 0;
  }

  &.is-open > .Select-control {
    border-color: var(--highlight-color);
  }

  .Select-control {
    border-color: var(--border-color);

    &:hover {
      box-shadow: none;
      border-color: var(--highlight-color);
    }
  }

  &.is-disabled {
    .Select-control {
      @include disabledFormField;
    }
  }

  .Select-menu-outer {
    border-color: var(--highlight-color);
    border-top-color: var(--border-color);
  }

  .Select-placeholder,
  .Select-noresults {
    color: var(--text-color-muted);
  }

  .Select-clear-zone {
    color: var(--text-color-secondary);
  }
}

// react datepicker overrides... annoyingly need to do deeply nested classes to get overrides working

.react-datepicker {
  border: 1px solid var(--border-color);
  min-width: 340px;

  .react-datepicker-children {
    border-top: 1px solid var(--border-color);
    clear: both;
  }
}

.react-datepicker__header {
  border-bottom: 1px solid var(--border-color);
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: auto;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 115px;
}

.react-datepicker__month-container {
  width: 70%;
}

.react-datepicker-popper {
  &.onlyDate {
    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker {
      min-width: initial;
    }
  }
}

.react-datepicker__header.react-datepicker__header--time {
  border-top-left-radius: 0;
}

.react-datepicker__month-container .react-datepicker__header {
  border-top-right-radius: 0;
}

.react-datepicker__time-container {
  width: 30%;
  border-left: 1px solid var(--border-color);

  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
    font-weight: inherit;
  }


}

.react-datepicker__close-icon {
  &::after {
    font-size: 18px;
    background-color: inherit;
    margin: -11px auto 0;
    color: var(--text-color-secondary);
  }

  &:hover::after {
    color: var(--danger-color);
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  width: auto;
  padding: 0;
}

.react-datepicker__close-icon {
  display: block;
}

.FormCheck {
  label {
    font-weight: normal;
  }

  .form-check-input {
    position: relative !important;
    margin: 0 !important;
    margin-right: 1rem !important;
  }

  .EnanbleModerationTooltip {
    svg {
      height: 16px;
      path {
        fill: var(--gray);
      }
    }
  }
}
