.CcCorrespondentSelector {
  width: 100%;

  .CcSelect {
    width: 75%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1rem;

      @include small-size {
        width: 100%;
        display: block;
      }
  
      .ClearyReactSelect__menu {
        z-index: $zindex-dropdown !important;
      }
  }

  .PlusIcon {
    svg {
      width: 13px;
    }
  }
}
