body {
  background-color: var(--background-color);

  #cleary-app-container {
    z-index: $zindex-base;
    position: relative;

    &.position-initial {
      position: initial;
    }
  }
}

.confetti-background:before {
  content: ' ';
  display: block;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-image: url('../../../public/images/confettiImage.svg');
}
