.NumberPercentageToggle {
  border-radius: var(--square-border-radius);
  border: 1px solid var(--border-color);

  .ToggleOption {
    width: 30px;
    height: 30px;
    color: var(--text-color-secondary);

    &.isSelected {
      color: var(--highlight-color);
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
    }
  }
}
