@keyframes slowBounce {
  0%, 40%, 80%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-30px);
  }
}

.CelebrationPage__background {
  @include celebration-header-bg;

  padding-bottom: 0 !important;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: -1;
  filter: blur(50px);

  .illustration {
    width: 100%;

    svg {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.CelebrationOpenCardPage {
  @extend .d-flex, .align-items-center, .justify-content-center;

  height: calc(100vh - 60px);
  padding: 16px;

  .CelebratedPerson {
    max-width: 160px;
  }



  &__card {
    @extend .w-100;

    animation: slowBounce 2.5s infinite;
    margin: 0 auto;
    margin-top: -100px;
    max-width: 1005px;
    padding: 18px;
    background: #5246D7;
    border-radius: 5px;

    &__wrapper {
      border: 5px solid var(--white-pure);
      border-radius: 5px;
      text-align: center;
      padding: 23px 0px 46px 0px;

      h1 {
        font-size: 36px;
        color: var(--white-pure);
        margin-bottom: 32px;
      }

      button {
        color: var(--white-pure);
        font-size: 24px !important;

        &:hover {
          color: var(--white-pure);
          text-decoration: none;
        }
      }
    }
  }
}
