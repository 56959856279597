.ToggleGroup {
  .toggle-group {
    div.toggle {
      border: 1px solid var(--border-color);
      border-radius: var(--square-border-radius);
      background-color: var(--white-pure);
      flex: 1 1 0;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }

      @include up-to-medium-size {
        margin-right: 0;
      }

      svg {
        overflow: visible;
      }

      .Icon {
        height: 24px !important;
        width: 24px !important;

        svg {
          fill: var(--text-color-secondary);
        }

        &.isHighlighted {
          svg {
            fill: var(--highlight-color);
          }
        }
      }

      span.title {
        font-size: 1rem;
        color: var(--text-color-primary);
      }

      &.isHighlighted {
        border-color: var(--highlight-color);

        span.title {
          color: var(--btn-primary-color);
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
