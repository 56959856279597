.SuggestedBuddies {
  @include default-page-grid;
  grid-row-gap: 30px;

  h5 {
    grid-column: span 12;
  }

  .CirclesLoadingIndicator {
    grid-column: span 12;
  }

  .UserCard {
    grid-column: span 3;

    @include medium-size {
      grid-column: span 6;
    }

    @include small-size {
      grid-column: span 12;
    }

    .EmployeeThumbnailPhoto {
      width: 60px !important;
      height: 60px !important;
      flex-basis: 60px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .UserPhotoAndInfo {
      flex-direction: column;
      align-items: center;

      .UserInfo {
        text-align: center;
        margin-top: 0.5rem;
      }
    }

    .SelectBuddyButton {
      border: none;

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}
