.AddProfilePicture {
  min-height: var(--profile-picture-list-dimension);
  background-color: var(--lightest-gray);
  border-radius: var(--profile-image-border-radius);

  & > div {
    min-height: var(--profile-picture-list-dimension);
  }

  span.PlusIcon svg * {
    fill: var(--text-color-secondary);
  }

  .ImageSelector,
  .ImageSelector-image {
    height: 100% !important;
    width: 100% !important;
  }

  .ImageSelector-imagePreview.ImageSelector-dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    padding: 0 !important;
    border: none;
    border-radius: var(--profile-image-border-radius);
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
  }
}
