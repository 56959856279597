.EditableTitle {
  .InlineEditInput {
    height: 4rem;
    max-width: 100%;
  }
}

.CharacterCount {
  top: 55px;
  right: 40px;

  @media print {
    display: none;
  }
}
