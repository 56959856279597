.ProgressBar {
  p {
    font-size: $label-font-size;
  }

  .bar-container {
    height: 5px;
    width: 100%;
    background-color: var(--secondary-gray);
    border-radius: 69px;
  }

  .bar-completed {
    background-color: var(--highlight-color);
    height: 5px;
    border-radius: inherit;
    transition: width 0.5s ease;
  }
}
