.CelebrationHeadlineWrapper {
  max-width: $container-width;
  margin: 0 auto;
}

.mobileWithCustomImage {
  .CelebrationHeadlineWrapper {
    padding: 1rem;
    padding-bottom: 2rem;
  }
}

.share-this-card-separator {
  height: 24px;
}

.GreetingCardBannerImage {
  @include celebration-header-bg;

  grid-column: 1 / -1;
  width: 100%;
  max-width: $full-hd-width;
  margin: 0 auto;
  border-bottom-left-radius: var(--square-border-radius);
  border-bottom-right-radius: var(--square-border-radius);
  height: 370px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @include up-to-medium-size {
    height: 233px;
  }

  .GreetingCardBannerImage--legacy {
    @extend .mt-3;
    max-width: $container-width;
    border-radius: var(--square-border-radius);
    height: 233px;
  }

  .GreetingCardBannerImage__customize-button {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &:hover {
    .GreetingCardBannerImage__customize-button {
      display: block;
    }
  }

  &.repositioning {
    height: auto;

    &:before {
      display: none;
    }
  }

  .CelebrationHeadlineWrapper {
    z-index: $zindex-positive;
    max-width: $container-width;
    margin: 0 auto;

    .TiptapView {
      h1, h2, h3, h4, h5, h6, p {
        span {
          border-radius: 8px;
          padding: 8px;
          display: inline-block;
        }
      }
    }
  }

  &__BannerWrapper {
    grid-column: span 12;
    position: relative;
  }

  &.withCustomImage {
    display: block;
    background-size: cover;
    background-position: center;
    background-color: initial !important;

    @include up-to-medium-size {
      padding-bottom: 0 !important;
    }
  }

  &:hover {
    .actions {
      display: block;
    }
  }

  .actions {
    position: absolute;
    top: 0;
    right: 0;
    display: none;

    @include up-to-large-size {
      display: none;
    }

    .ImageIcon {
      svg {
        width: 16px;
        fill: white;
      }
    }

    .TrashIcon {
      svg {
        width: 16px;
        fill: white;
      }
    }
  }

  .ImageSelector {
    max-width: initial;
    display: inline-block;
  }

  .dropzone-wrapper {
    height: auto;
    padding: 0;
    overflow: visible;
    border: none;
    background: none;
  }

  &.showBanner {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.CelebrationGreetingCardPage {
  .CelebrationInputs {
    grid-column: 1 / -1;
  }
}

.SignGreetingCardBanner {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background-color: var(--white-pure);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--square-border-radius);
  border-bottom-right-radius: var(--square-border-radius);
}

.ShowAppreciation {
  display: flex;
  justify-content: center;
  margin-top: -19px;

  .actions {
    display: flex;
    gap: 16px;
    z-index: $zindex-tooltip;
  }
}

.ShowAppreciation--legacy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / -1;
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  border-radius: var(--square-border-radius);
  padding: 16px 32px;
  background-color: white;
  box-shadow: var(--card-box-shadow);

  .actions {
    display: flex;
    gap: 16px;
  }

  p {
    @extend .text-large;
    margin: 0;
  }

  @include up-to-medium-size {
    flex-direction: column;
    align-items: center;

    .actions {
      margin-top: 16px;
    }
  }
}
