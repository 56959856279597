.ConfigureNavbarContainer {
  position: relative;
  display: flex;

  .ConfigureNavbar {
    z-index: $zindex-toolbar;
    width: 0;
    padding: 0;
    overflow: auto;
    transition: all 0.5s ease-in-out;
    border: none;

    &.isOpen {
      width: var(--main-left-navbar-extended-width);
      padding: 24px;
      border-right: 1px solid var(--border-color);
    }

    .GearIcon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 18px;
        width: 18px;
        fill: var(--text-color-primary);
      }
    }
  }
}
