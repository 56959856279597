.CarouselCardForm {
  .EditCoverImageButtons {
    display: flex;
    gap: 1rem;

    .ImageSelector {
      max-width: unset;

      &-dropzone {
        height: auto;
        padding: 0;
        overflow: visible;
        border: none;
        background: none;
      }
    }

    .ImageIcon {
      height: 20px;
      width: 20px;
      svg {
        fill: var(--highlight-color);
      }
    }
  }
}
