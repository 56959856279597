.TiptapEmbedDropdownButton {
  .DropdownMenu-content {
    width: 300px;
  }
}


.TiptapToolbarButton {
  .ZoomTextIcon {
    svg {
      rect {
        fill: var(--text-color-primary);
      }
    }
  }

  &.isActive {
    .ZoomTextIcon {
      svg {
        rect {
          fill: unset;
        }
      }
    }
  }
}
