.ButtonWidget {
  display: flex;
  justify-content: center;
  container-type: inline-size;

  &:hover {
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3) !important;
    opacity: 0.8;
  }

  &--link {
    height: 100%;
    padding: 0 27px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "icon text"
      "icon secondary-text";
    align-items: center;
    gap: 0 10px;
    text-decoration: none;
  }

  &--icon {
    grid-area: icon;
    font-size: 21px;
  }

  &--text {
    @extend .truncate-text-at-1-lines;
    grid-area: text;
    font-size: 21px;
    font-weight: 600;
    align-self: end;
  }

  &--secondaryText {
    @extend .truncate-text-at-1-lines;
    grid-area: secondary-text;
    font-size: 13px;
    align-self: baseline;
  }

  @container (max-width: 200px) {
    .ButtonWidget--link {
      padding: 0 14px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .ButtonWidget--icon {
      font-size: 16px;
      align-self: unset;
    }

    .ButtonWidget--text {
      font-size: 16px;
      align-self: unset;
    }

    .ButtonWidget--secondaryText {
      align-self: unset;
    }
  }
}
