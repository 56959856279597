.LoadingSpinner {
  display: flex;
  justify-content: center;
}

.LoadingSpinner:before {
  content: '';
  display: block;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 3px solid var(--highlight-color);
  border-right: 3px solid transparent;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.MiniLoadingSpinner {
  position: relative;
}

.MiniLoadingSpinner:before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  border-top: 3px solid var(--dynamic-color-primary-color-light--highlight-color);
  border-right: 3px solid transparent;
  animation: spinner 0.8s linear infinite;
}
