.PageEditorNavbar {
  .ViewableByLinkIcon {
    width: 18px;
    margin-top: -2px;
  }

  .Toolbar-link {
    cursor: pointer;
    color: var(--text-color-primary);

    svg {
      fill: var(--text-color-primary);
    }

    &:hover {
      color: var(--link-color);

      svg {
        fill: var(--link-color);
      }
    }
  }
}
