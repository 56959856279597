.EventPage-questionListItem.AnimateNewlyAddedQuestionBackground {
  animation: new-card-item-fade 3000ms;
}

.QuestionForm {
  .TiptapEditor {
    min-height: 150px;
  }
}

.QuestionForm,
.QuestionContent {
  .TiptapView {
    font-size: 1.25rem !important;

    li {
      font-size: 1.25rem !important;
    }

    p {
      font-size: 1.25rem !important;
    }
  }
}

.AnswerList {
  & > div > div:last-of-type {
    margin-bottom: 0 !important;
  }
}

.EventNotificationWidget * {
  color: white !important;
}

.EventNotificationWidget h5 {
  @extend .font-weight-bold;
}

.AgendaItemInput {
  padding: 0;
  min-width: 100%;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $secondary-gray;
  resize: none;

  &:hover {
    border-color: $secondary-gray;
  }
  &:focus {
    border-color: $secondary-gray;
  }
}

.EventChatChannels {
  .ClearyReactSelect {
    min-width: 150px;
  }
}

.AgendaItemEditable {
  &:focus {
    outline: none;
    width: 100%;
    border-bottom: 1px solid $secondary-gray;
  }
  &:hover {
    background-color: none;
  }
}

.EventPageTabs {
  .nav-link {
    &:hover {
      border: 0;
    }
  }

  .badge {
    font-weight: 500;
    height: 20px;
    min-width: 20px;
    position: relative;
    bottom: 2px;
  }
}

.RejectionTextArea {
  width: 100%;
  resize: none;
  background-color: var(--textbox-lightest-gray);

  &:focus {
    border-color: var(--comments-input-background-color);
  }
  &:hover {
    border-color: var(--comments-input-background-color);
  }
}

.ApprovalBadge {
  background-color: var(--text-color-secondary);
  color: var(--white-pure);
  border-radius: 0.5rem;
}

.badge-review {
  background-color: var(--like-highlight-color) !important;
  color: var(--white-pure);
}

.ParticipationImage {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: -3px;
}

.plus-participants {
  color: var(--white-pure);
  width: 25px;
  height: 25px;
  background: var(--highlight-color);
  margin-left: -4px;
  text-align: center;
  line-height: 1.875rem;
}

.border-error {
  border-color: $error-color !important;

  &:hover {
    border-color: $error-color !important;
  }

  &:focus {
    border-color: $error-color !important;
  }
}

// Small devices (landscape phones, less than 768px)
@include small-size {
  .EventsListPageFilters,
  .EventPageFilters {
    .PillTab,
    button {
      font-size: 1rem;
    }
  }

  .VideoIcon {
    width: 16px;
  }

  .EventCardQuestionCount {
    display: flex;
    align-items: center;
  }

  .HighlightedEvent {
    div.EventTimeStatus p.text-sm-normal,
    div.EventParticipants .text-sm-normal span {
      @extend .text-small;
    }

    .EventParticipants .text-small span:first-of-type {
      font-weight: bold;
      color: var(--text-color-primary);
    }

    .ParticipantsWrapper {
      padding-right: 0 !important;
    }
  }
}

