.TiptapResizeOverlay {
  display: inline-block;
  position: relative;
  width: fit-content;
  height: fit-content;
  line-height: 0;
  max-width: calc(100% - 2px);

  iframe {
    pointer-events: none;
  }

  .resize-square {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: move;

    &.isSelected {
      border: 1px solid var(--link-color);
    }

    &.isDragging {
      z-index: $zindex-resize-square;
      border: 1px solid var(--link-color);
    }

    .resize-handle {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: var(--link-color);
      z-index: $zindex-resize-square;
    }

    .resize-handle-top-left {
      top: -4px;
      left: -4px;
      cursor: nwse-resize;
    }

    .resize-handle-top {
      top: -4px;
      left: calc(50% - 4px);
      right: calc(50% - 4px);
      cursor: ns-resize;
    }

    .resize-handle-top-right {
      top: -4px;
      right: -4px;
      cursor: nesw-resize;
    }

    .resize-handle-right {
      right: -4px;
      top: calc(50% - 4px);
      bottom: calc(50% - 4px);
      cursor: ew-resize;
    }

    .resize-handle-bottom-right {
      bottom: -4px;
      right: -4px;
      cursor: nwse-resize;
    }

    .resize-handle-bottom {
      bottom: -4px;
      left: calc(50% - 4px);
      right: calc(50% - 4px);
      cursor: ns-resize;
    }

    .resize-handle-bottom-left {
      bottom: -4px;
      left: -4px;
      cursor: nesw-resize;
    }

    .resize-handle-left {
      left: -4px;
      top: calc(50% - 4px);
      bottom: calc(50% - 4px);
      cursor: ew-resize;
    }
  }
}
