.CollapsableItem {
  &__CloseIcon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: var(--text-color-primary);
    }

    &--right {
      transform: rotate(-90deg);
    }
  }
}
