.CustomStyles {
  .ColorPicker,
  .LogoDimensions,
  .DimensionControl {
    flex: 0 1;
    min-width: calc(50% - 20px);
  }

  div.border {
    border-width: 3px !important;
  }

  pre code div {
    font-family: monospace;
  }

  // This is used if we want to break a component into a new line.
  // cardBackgroundColor and navTextActiveColor won't have anything on their right
  .cardBackgroundColor,
  .navTextActiveColor {
    margin-right: 50%;
  }

  .searchBgColor.isColorPickerVisible {
    .ChromePicker {
      z-index: $zindex-popover !important;
    }
  }

  .searchBgColor {
    .GlobalSearch {
      .Select-option.is-focused {
        min-width: 100%;
      }
    }
  }

  .NavbarVariables,
  .SubNavVariables {
    flex: 0 1;
    min-width: calc(50% - 20px);

    .ColorPicker {
      flex: 1;
      min-width: 100%;
    }
  }

  .ImageSelector {
    max-width: 100%;
  }

  .ImageSelector-image {
    img {
      height: var(--logo-height);
      width: var(--logo-width);
    }
  }
}
