nav.OrgChartToolbar {
  width: 100%;
  background-color: var(--white-pure);
  height: var(--nav-height);
  box-shadow: var(--nav-box-shadow);
  display: flex;
  align-items: center;
  position: relative;
  z-index: $zindex-toolbar;

  @media print {
    display: none;
  }

  .orientation-buttons {
    display: flex;
    align-items: center;
    border-right: solid 1px var(--border-color);

    @include small-size {
      border-right: none;
    }

    svg {
      height: 24px;
      width: 24px;

      path {
        fill: var(--text-color-muted);
      }

      &:hover {
        cursor: pointer;

        path {
          fill: var(--highlight-color);
        }
      }
    }

    .active {
      svg {
        path {
          fill: var(--btn-primary-color);
        }
      }
    }

    span.horizontal {
      svg {
        transform: rotate(270deg);
      }
    }
  }

  .OrgChartSearch {
    min-width: 300px;
    margin: 0 20px;

    @include small-size {
      min-width: initial;
      flex-grow: 2;
      margin: 0 4px;
    }
  }
}
