.AsyncSearchInputGroup {
  position: relative;

  .AsyncSearchInputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;

    svg {
      fill: var(--text-color-secondary);
      width: 22px;
      height: 22px;
    }
  }

  .AsyncSearchInput {
    &.hasIcon {
      padding-left: 40px;
    }
  }
}
