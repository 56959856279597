section.ProfileTabbedContent {
  grid-column: 4 / span 6;
  grid-row: 1 / span 3;
  align-self: start;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include small-size {
    grid-column: 1 / span 12;
    grid-row: 4 / span 1;

    div.PillTab {
      margin-top: 2rem;
    }
  }

  @extend .ProfileCard;
}
