span.StatusLabel {
  padding: 3px 7px;
  border-radius: var(--square-border-radius);

  &.status-gray {
    color: var(--status-label-gray-text-color);
    background-color: var(--status-label-gray-background-color);
  }

  &.status-red {
    color: var(--status-label-red-text-color);
    background-color: var(--status-label-red-background-color);
  }

  &.status-red-secondary {
    color: var(--status-label-red-text-color);
    background-color: var(--white-pure);
    border: 1px solid var(--status-label-red-text-color);
  }

  &.status-green {
    color: var(--status-label-green-text-color);
    background-color: var(--status-label-green-background-color);
  }

  &.status-yellow {
    color: var(--status-label-yellow-text-color);
    background-color: var(--status-label-yellow-background-color);
  }

  &.status-blue {
    color: var(--status-label-blue-text-color);
    background-color: var(--status-label-blue-background-color);
  }

  &.status-orange {
    color: var(--status-label-orange-text-color);
    background-color: var(--status-label-orange-background-color);
  }
}
