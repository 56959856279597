.SocialShareModal {
  .modal-content {
    background-color: var(--btn-primary-color);
  }

  .modal-header {
    .close {
      color: $white;
      opacity: 1;
      font-size: 2rem;
      font-weight: 300;
    }
  }

  .modal-body {
    padding: 0;
  }

  header {
    h2 {
      font-size: 1.5rem;
      font-weight: normal;
    }

    h2,
    span {
      color: $white;
    }

    svg {
      width: 50px !important;
      height: 50px !important;
    }
  }

  main {
    background-color: $white;
    overflow: hidden;

    .paragraph {
      @extend .p-3, .pt-5;

      font-size: 2rem;
      font-weight: 600;
    }
  }

  .illustration {
    svg {
      @extend .w-100;
      margin-bottom: -2rem;
    }
  }
}
