.SourcePage {
  @include default-page-grid;

  > div:first-child {
    grid-column: 1 / -1;
  }

  .SourcePageIcon {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .DocumentIcon {
    display: block;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ExternalLinkIcon {
    display: block;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--link-color);
    }
  }

  .Breadcrumb {
    .chevron-icon {
      transform: rotate(180deg);
      width: 9px;
      height: auto;
      margin-left: 6px;
      margin-right: 5px;

      &, path {
        fill: var(--primary-color);
      }
    }
  }

  .resync-tooltip {
    svg {
      width: 18px;
      height: initial;
      position: relative;
      top: -1px;
      margin-left: 4px;

      path {
        fill: var(--link-color);
      }
    }
  }
}

.ParentsPopoverList {
  padding: 20px;
  margin: 0;
}


.SyncingIndicator {
  &.importing {
    svg {
      animation: infinite-rounding 2s linear infinite;

      path {
        fill: var(--btn-primary-color) !important;
      }

      @keyframes infinite-rounding {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.imported {
    svg {
      width: 20px;
      height: auto;

      path {
        fill: #54C981 !important;
      }
    }
  }
}
