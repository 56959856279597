.AssignTicketPopover {
  .UnassignedIcon {
    padding: 2px;
    background-color: var(--light-gray);
    border-radius: var(--profile-image-border-radius);

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.AssignTicketPopover-Popover {
  width: 300px;
}
