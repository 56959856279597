div.MyTasksDirectReportsHeaderTile {
  border-radius: var(--square-border-radius);
  background-size: cover;

  &.overdueTasks {
    background-color: var(--danger-color);

    .view-details-button {
      background-color: inherit;
      border-color: var(--white-pure);
      min-width: auto;

      &:hover, &:focus, &:active {
        background-color: inherit !important;
        color: var(--white-pure) !important;
        border-color: var(--white-pure) !important;
      }
    }
  }

  &.onTrack, &.noOpenTasks, &.loading {
    background-color: var(--task-header-direct-reports-tile-background-color);
  }

  &.onTrack {
    background-image: url('../../../public/images/confettiImage.svg');
  }

  &.noOpenTasks {
    .personsIcon {
      fill: var(--white-pure);
    }
  }
}
