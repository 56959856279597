.WorkflowRunPage {
  &__Content {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.CustomBreadcrumb {
  ol.breadcrumb {
    background-color: transparent;
    padding: 0;

    a {
      font-weight: normal;
      color: var(--text-color-secondary);
    }

    .breadcrumb-item.active {
      color: var(--link-color);
    }
  }
}
