.AuthorizeZoomIntegrationModal {
  background: rgba(0, 0, 0, 0.5);
  max-width: unset !important;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
  transition: all 0.1s ease-in-out !important;
  transform: translate(0px, 0px) !important;

  .modal-content {
    margin-top: 10px;
    max-width: 800px;
    height: fit-content;
  }

  .ZoomIcon {
    svg {
      height: 36px;
      width: 36px;
    }
  }

  .btn-success {
    min-width: 140px;
  }

  .stepPreviewImage {
    height: 300px;
    width: 330px;
  }
}
