.CelebrationInputs {
  .CalendarIcon {
    svg {
      width: 15px;
      fill: var(--text-color-dark-muted);
      margin-top: -2px;
    }
  }

  .ClearyReactSelect,
  .ClearyReactSelect__control {
    height: 100%;
  }

  .DatePickerLabel {
    > div, .react-datepicker-wrapper, .react-datepicker__input-container, input {
      width: 100%;
      height: 100%;
    }

    input {
      padding-left: 30px;
    }
  }

  .CalendarIcon {
    position: absolute;
    top: 50%;
    left: 10px;
    pointer-events: none;
    z-index: 1;
    margin-top: -12px;

    svg {
      fill: var(--text-color-muted);
    }

    &.hasValue {
      svg {
        fill: var(--black-pure);
      }
    }
  }

  &__Label {
    border-radius: var(--square-border-radius);
    position: relative;
  }

  &__FlexWrapper {
    margin: 0 auto;
    max-width: 800px;
    margin-top: -24px;
    position: relative;
    z-index: $zindex-two;

    @include up-to-medium-size {
      @extend .mt-3;
    }
  }

  &__Label {
    background: var(--white-pure);
    border: 1px solid #C6C6C7;
    height: 48px;
  }

  .EmployeeWrapper {
    border: 1px solid #C6C6C7;
    border-radius: var(--square-border-radius);
  }
}
