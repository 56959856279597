main.MyTasksPage {
  @include default-page-grid;

  margin-top: 50px;

  @include small-size {
    margin-top: 10px;
  }

  div.MyTasksHeaderTile {
    grid-column: 1 / -1;

    &.hasDirectReports {
      grid-column: 1 / 10;
    }

    @include up-to-medium-size {
      grid-column: 1 / -1 !important;
    }
  }

  div.MyTasksDirectReportsHeaderTile {
    grid-column: 10 / -1;

    @include up-to-medium-size {
      display: none !important;
    }
  }

  section.person-tabs {
    grid-column: span 12;
  }

  section.state-tabs {
    display: grid;
    grid-column: span 12;

    .PillTabSelector {
      grid-column: 1 / 10;
    }

    @include up-to-medium-size {
      grid-row-gap: 20px;

      .PillTabSelector {
        grid-column: 1 / -1;
      }
    }
  }

  section.search-and-filter {
    display: grid;
    grid-column: span 12;

    .direct-report-filter{
      grid-column: 10 / 12;
      z-index: $zindex-toolbar;

      .ClearyReactSelect {
        min-width: 200px;
      }
    }

    @include up-to-medium-size {
      grid-row-gap: 20px;

      .direct-report-filter{
        grid-column: 1 / -1;
      }
    }
  }

  section.tasks-table {
    grid-column: span 12;
  }
}
