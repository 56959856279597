section.GroupMembers {
  grid-column: 4 / span 9;

  @include up-to-medium-size {
    grid-column: 1 / span 12;
    grid-row: 1;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 14px;
  align-content: start;
  justify-content: center;

  @include xlarge-size {
    grid-column-gap: 24px;
  }

  @include large-size {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
  }

  @include medium-size {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }

  @include small-size {
    grid-template-columns: 1fr;
    grid-column-gap: 8px;
  }

  header.title {
    grid-column: 1 / -1;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--link-color);
    }

    .ManageLink {
      &:hover {
        cursor: pointer;
      }
    }
  }

  section.ManageMembers {
    grid-column: 1 / -1;
  }

  .FilterByLabel {
    grid-column: 1 / -1;

    .ClearyReactSelect {
      min-width: 190px;
    }
  }

  .SeeMoreLink {
    grid-column: 1 / -1;
  }

  .SeeMoreMembersLoading {
    grid-column: 1 / -1;
  }

  .EmptyFeedCard {
    grid-column: 1 / -1;

    display: flex;
    flex-direction: row;

    @include up-to-medium-size {
      flex-direction: column;
      text-align: center;
    }

    button {
      min-width: 200px;

      svg {
        width: 18px;
        height: 18px;
        fill: var(--btn-primary-color);
      }
    }
  }
}
