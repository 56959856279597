.AppModal {
  .modal-content {
    .modal-header {
      border-bottom: none;
    }

    .modal-body {
      padding-top: 0;
    }
  }

  &.cleary-modal-md {
    max-width: 600px;
  }

  &.cleary-modal-fullscreen {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    margin: 0;

    .modal-content {
      border: none;
      border-radius: 0;

      .modal-header {
        button.close {
          font-size: 50px;
          font-weight: 300;
        }
      }
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.modal-enter {
  animation: slideIn 0.5s forwards;
}

.modal-exit {
  animation: slideOut 0.5s forwards;
}
