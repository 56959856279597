div.TaskableDetailsModal {
  .modal-content {
    border-radius: 12px;
  }
}

div.TaskableDetailsButtonAndModal {
  .FormCheck {
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }
  }

  .CommentsIcon {
    svg {
      width: 12px;
      height: 12px;

      path {
        fill: var(--link-color);
      }
    }
  }

  &.IconDisplay {
    container-type: inline-size;
    border-radius: 8px;

    .CheckMarkIconContainer {
      width: 24px;
      height: 24px;
    }

    .CheckMarkIcon {
      svg {
        width: 24px;
        height: 24px;
        fill: var(--text-color-secondary);
      }

      &.checked {
        svg {
          fill: var(--success-color);
        }
      }
    }

    .DueAtTextComponent {
      width: 145px;
      color: var(--success-color);
      text-align: right;
      flex-shrink: 0;

      &.isOverdue {
        color: var(--danger-color);
      }
    }

    .NameAndDueAt {
      @container (min-width: 500px) {
        display: flex;
        gap: 0.5rem;
        width: 100%;
        justify-content: space-between;
      }
    }

    &:hover {
      box-shadow: var(--card-box-shadow);
    }
  }
}
