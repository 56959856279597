.TiptapToolbarDropdownButton {
  height: 100%;

  .DropdownMenu-content {
    overflow: hidden;
    background-color: var(--white-pure);
    right: auto;

    &.direction-top {
      bottom: 0;
    }
  }
}
