.GreetingCardBannerNewCelebrationDisplay {
  .CelebrationHeadlineText {
    color: white;
    font-size: 24px;
    font-weight: 300;

    b {
      font-size: 36px;
      font-weight: 700;
    }

    @include small-size {
      font-size: 16px;

      b {
        font-size: 26px;
      }
    }
  }
}
