
.EditRestrictedAudienceConfigurationPage {

  .form-check {
    label {
      font-weight: 400;
    }
  }

  .CompanySettingsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

