.AudiencePreferredChannelModal {
  .channels-column {
    width: 150px;
    display: flex;
    gap: 0.5rem;
  }
}

.PreferredNotificationTypeIcon {
  svg {
    fill: var(--text-color-primary);
    width: 16px;
    height: 16px;
  }
}
