.ArticleTemplatesEmptyState {
  display: block;
  padding: 10rem 0;

  ol {
    list-style: none;
    counter-reset: list-counter;
    padding: 0;

    li {
      counter-increment: list-counter;

      &::before {
        content: counter(list-counter);
        margin-right: 15px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: var(--tile-background-color);
        color: var(--text-color-primary);
        text-align: center;
        border-radius: 50%;
      }
    }
  }
}
