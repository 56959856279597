.CsvAudienceUploader {
  & .CsvSelector-dropzone{
    width: 100%;
    position: relative;
    border-width: 1px;
    border-color: var(--border-color);
    border-style: solid;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100px;
    background-color: rgba(14, 0, 60, 0.04);
  }

  & .remove{
    float: none;
    font-size: initial;
    margin-left: 5px;
    cursor: pointer;
  }
}
