div.TaskDetail {
  background-color: $white-pure;

  .CorrespondentIcon {
    svg {
      height: 22px;
      width: 22px;
    }
  }

  .EmployeeThumbnailPhoto {
    width: 22px !important;
    height: 22px !important;
  }
}
