.Moderator-navbar {
  // z-index and position required to fix a conflict
  // with the navbar's bottom box shadow.
  z-index: $zindex-dropdown;
  position: relative;
  background-color: var(--subnav-color);
  height: 50px;
  color: var(--white-pure);

  a:first-child {
    background-color: var(--btn-primary-color);
    color: var(--white-pure);
  }

  &.participant {
    button {
      border-color: var(--white-pure);
    }
  }

  &.moderator {
    a {
      color: var(--white-pure);
      border-color: var(--white-pure);
    }

    .Settings-button {
      margin-right: 1.4rem;

      &:hover {
        cursor: pointer;
      }

      span {
        color: var(--subnav-text-color);
      }

      @include small-size {
        margin-right: 0.3rem;

        span {
          display: none;
        }
      }
    }
  }

  .EyeOpenIcon svg {
    width: 18px;
    height: 18px;

    path {
      fill: var(--white-pure);
    }
  }
}
