.UserSkillPage {
  @include default-page-grid;

  .UserSkillPage-backbutton {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }

  .UserSkillPage-header {
    grid-column: 1 / span 12;
    grid-row: 2 / span 1;
  }

  .UsersList {
    grid-column: 1 / span 12;
    @include default-page-grid;

    .CirclesLoadingIndicator {
      grid-column: 1 / span 12;
    }

    .PersonCard {
      grid-column: span 3;
      margin-bottom: 1rem;
    }

    @include xlarge-size {
      .PersonCard {
        grid-column: span 4;
      }
    }

    @include large-size {
      .PersonCard {
        grid-column: span 6;
      }
    }

    @include medium-size {
      .PersonCard {
        grid-column: span 6;
      }
    }

    @include small-size {
      .PersonCard {
        grid-column: span 12;
      }
    }
  }
}
