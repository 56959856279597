.LayoutCollectionTemplatePreview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  .img-container {
    max-height: 500px;
    overflow: auto;

    .ProgressiveImage {
      img {
        width: 100%;
        height: auto !important;
        object-fit: cover;
      }
    }
  }
}
