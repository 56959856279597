.ClearyBubbleMenu {
  @extend .bordered;
  box-shadow: var(--card-box-shadow);

  .BubbleMenuOptions {
    border-radius: var(--square-border-radius);
    background-color: var(--white-pure);

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
