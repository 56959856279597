.ArticleThumbnailImage {
  position: relative;
  display: inline-block;
  width: 126px;
  height: 126px;
  flex-shrink: 0;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  &.random-image {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
      background-blend-mode: luminosity;
      opacity: 0.5;
      background-color: var(--btn-primary-color);
    }

    &-1 {
      &:before {
        background-image: url('../../../public/images/illustrations/manWithMultipleDevices.svg');
      }
    }

    &-2 {
      &:before {
        background-image: url('../../../public/images/illustrations/manAtDesk.svg');
      }
    }

    &-3 {
      &:before {
        background-image: url('../../../public/images/illustrations/manWithGiantTablet.svg');
      }
    }
  }

}
