.PageHeader {
  margin-left: 0;
  transition: margin-left 0.5s ease-in-out;

  @include small-size {
    display: none !important;
  }

  .EditButtons {
    a {
      @include linkText(--link-color, --font-weight-inherit);
      @extend .text-small;

      svg {
        fill: var(--link-color);
      }

      .QuestionIcon,
      .ViewableByLinkIcon,
      .PencilIcon {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      &:hover {
        svg {
          fill: var(--dynamic-color-link-text--link-color);
        }
      }
    }

    .disabled, .disabled:hover {
      color: var(--dynamic-color-link-color-lighter--link-color);

      svg {
        path {
          fill: var(--dynamic-color-link-color-lighter--link-color)
        }
      }
    }
  }

  @media print {
    display: none !important;
  }
}
