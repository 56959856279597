.AiPlaygroundPage {
  .CopyIcon {
    svg {
      height: 15px;
      width: 15px;
      fill: var(--gray);
    }
  }

  .react-datepicker-wrapper {
    input {
      min-width: 250px;
    }
  }

  .function-call-container {
    background-color: var(--lightest-gray);
    border-radius: 5px;
  }
}
