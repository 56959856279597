section.GroupMainInfo {
  grid-column: 4 / span 6;
  display: flex;
  flex-direction: column;

  input.group-name {
    @include header(--h2-font-size);

    // h2 line-height + 4px vertical padding to match EditableContent height
    height: 50px;
  }

  .secondary-text {
    color: var(--text-color-secondary);
    font-weight: 500;
  }
}

section.GroupMembershipActions {
  grid-column: 10 / span 3;
}

div.description-hierarchy-container {
  grid-column: 4 / span 9;
  grid-row: 2 / span 12;
}

section.GroupMainInfo,
div.description-hierarchy-container {
  @include small-size {
    grid-column: 1 / span 12;
    grid-row: auto;
    align-items: center;
  }
}
