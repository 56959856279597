.PageArchiveLink {
  svg {
    width: 22px;
    height: 18px;
    margin-bottom: 1px;
  }

  &:hover {
    svg {
      g, path {
        fill: var(--highlight-color);
      }
    }
  }

  &.isActive {
    svg {
      g, path {
        fill: var(--highlight-color);
      }
    }
  }
}

.PagesNavSortableTree {
  .PageArchiveLink {
    color: var(--text-color);
    font-weight: 500 !important;
    @extend .text-small, .d-none, .d-md-flex, .h-100, .pl-4, .py-2, .align-items-center;

    .ArchiveBoxIcon {
      margin-left: 11px; // To align it with root level of the tree
    }

    &:hover {
      color: var(--highlight-color);
    }

    &.isActive {
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
      color: var(--highlight-color);
    }
  }
}
