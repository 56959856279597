// STYLEGUIDE https://invis.io/2WYGKPEC7BS#/428876527_Cleary_UI_Styleguide

// COLOR PALETTE
$black-pure: #000000;
$white-pure: #FFFFFF;
$white: $white-pure;
$black: #1A1C1F;
$dark-gray: #343A40;
$gray: #808284;
$mid-gray: #8D8E8F;
$light-mid-gray: #D1D1D1;
$light-gray: #E4E4E4;
$lightest-gray: #F9F9F9;
// SEMANTIC COLOR
$green: #1FC463;
// SEMANTIC COLOR
$teal: #1BADBC;
// SEMANTIC COLOR
$red: #EF4444;
// OTHER
$yellow: rgb(223, 182, 0);
$orange: #FF8559;
$purple: #BF5BB3;
$teal-color: rgb(69, 218, 190);
$accent-blue: #2996CC;
$placeholder-color: $mid-gray;
$grey-background: #F7F8FC;
$light-purple-background: #F3F6FF;
$secondary-gray: #E8E8E9;
$card-border-gray: #F6F7F8;
$orange-warning: #FF9F43;
$highlight-color: #3078F4;
$dark-blue: #34299F;
$cleary-purple: #5246D7;

// ALERT MESSAGES
$success-color: $green;
$info-color: $accent-blue;
$warning-color: $yellow;
$error-color: $red;
$muted-color: $mid-gray;

// GLOBAL SEARCH
$search-match-highlight-font-color: var(--text-color-primary);
$search-match-highlight-bg-color: #FFE168;

// TYPOGRAPHY
$base-font-size: 15px;
$line-height: 1.5;
$header-line-height: 1.5;
$label-font-size: 13px;
$base-font-family: "Inter", "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Layers
$zindex-positive: +1 !default;
$zindex-negative: -1 !default;
$zindex-zero: 0 !default;
$zindex-one: 1 !default;
$zindex-two: 2 !default;
$zindex-base: 100 !default;
$zindex-resize-square: 500 !default;
$zindex-secondary-toolbar: 989 !default;
$zindex-toolbar: 990 !default;
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-replace-navbar: 1035 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-celebrations-effect: 1080 !default;

// Breakpoints
$bootstrap-xs: 0;
$bootstrap-sm: 576px;
$bootstrap-md: 768px;
$bootstrap-lg: 992px;
$bootstrap-xl: 1200px;

// 500 designs page grid width
$page-grid-width-large: 1290px;

$full-hd-width: 1920px;
$container-width: 1440px;

:root {
  // Dynamic variables used to store computed color variations

  // Logic to generate computed color values
  // values can be found appColorFunctions.js

  // Convetion for naming:
  // Start with '--dynamic-color' as namespace.
  // Follow with unique identifier, e.g 'link-color-lightest',
  // end with custom css property that serves as base color, e.g '--link-color'.

  // LINKS
  --dynamic-color-link-text--link-color: '';
  --dynamic-color-link-text--danger-color: '';
  --dynamic-color-link-text--highlight-color: '';
  --dynamic-color-link-text--text-color-secondary: '';

  // PRIMARY BUTTON
  --dynamic-color-button-primary-active--btn-primary-color: '';
  --dynamic-color-button-primary-hover--btn-primary-color: '';
  --dynamic-color-button-primary-disabled--btn-primary-color: '';
  --dynamic-color-button-primary-show-toggle--btn-primary-color: '';

  // PRIMARY BUTTON DANGER COLOR
  --dynamic-color-button-primary-active--danger-color: '';
  --dynamic-color-button-primary-hover--danger-color: '';
  --dynamic-color-button-primary-disabled--danger-color: '';
  --dynamic-color-button-primary-show-toggle--danger-color: '';

  // PRIMARY BUTTON GRAY
  --dynamic-color-button-primary-active--dark-gray: '';
  --dynamic-color-button-primary-hover--dark-gray: '';
  --dynamic-color-button-primary-disabled--dark-gray: '';
  --dynamic-color-button-primary-show-toggle--dark-gray: '';

  // SECONDARY BUTTON BORDER
  --dynamic-color-button-secondary-hover-border--btn-primary-color: '';
  --dynamic-color-button-secondary-active-border--btn-primary-color: '';
  --dynamic-color-button-secondary-disabled-border--btn-primary-color: '';

  // SECONDARY BUTTON DANGER BORDER
  --dynamic-color-button-secondary-hover-border--danger-color: '';
  --dynamic-color-button-secondary-active-border--danger-color: '';
  --dynamic-color-button-secondary-disabled-border--danger-color: '';

  // SECONDARY BUTTON COLOR
  --dynamic-color-button-secondary-hover-color--btn-primary-color: '';
  --dynamic-color-button-secondary-active-color--btn-primary-color: '';
  --dynamic-color-button-secondary-disabled-color--btn-primary-color: '';

  // SECONDARY BUTTON DANGER COLOR
  --dynamic-color-button-secondary-hover-color--danger-color: '';
  --dynamic-color-button-secondary-active-color--danger-color: '';
  --dynamic-color-button-secondary-disabled-color--danger-color: '';

  // SECONDARY BUTTON MUTED COLOR
  --dynamic-color-button-muted-hover--btn-muted-color: '';
  --dynamic-color-button-muted-active--btn-muted-color: '';
  --dynamic-color-button-muted-disabled--btn-muted-color: '';

  // ERRORS
  --dynamic-color-input-textarea-error--error-color: '';
  --dynamic-color-form-error-list--error-color: '';

  // COMPONENTS
  --dynamic-color-close-icon--border-color: '';
  --dynamic-color-group-current-link--link-color: '';
  --dynamic-color-card-dropdown-item--card-background-color: '';
  --dynamic-color-navbar-item--nav-color: '';
  --dynamic-color-banner-hover-text--banner-text-color: '';

  // PROFILE PHOTOS
  --dynamic-color-profile-photos--profile-photo-destroy-color: '';
  --dynamic-color-profile-photos--profile-photo-promote-color: '';

  // ARTICLE SOCIAL
  --dynamic-color-article-social--text-color-muted: '';

  // PRIMARY COLORS
  // BASE COLOR --highlight-color
  --dynamic-color-primary-color-darker--highlight-color: ''; // shade 15%
  --dynamic-color-primary-color-darkest--highlight-color: ''; // shade 10%
  --dynamic-color-primary-color-light--highlight-color: ''; // tint 15%
  --dynamic-color-primary-color-lighter--highlight-color: ''; // tint 30%
  --dynamic-color-primary-color-lightest--highlight-color: ''; // tint 85%
  --dynamic-color-primary-color-tint-90--highlight-color: ''; // tint 90%

  // BASE LINK COLOR --link-color
  --dynamic-color-link-color-lighter--link-color: '';
  --dynamic-color-link-color-lightest--link-color: '';

  // SUCCESS COLOR --success-color
  --dyanmic-color-success-color-lightest--success-color: '';

  // NEUTRAL COLORS
  // BASE COLOR --black
  --dynamic-color-neutral-color-light--black: ''; // tint 50%
  --dynamic-color-neutral-color-lighter--black: ''; // tint 75%
  --dynamic-color-neutral-color-lightest--black: ''; // tint 90%

  // SECONDARY COLORS
  // BASE COLOR --secondary-gray
  --dynamic-color-secondary-color-dark--secondary-gray: ''; // shade 10%
  --dynamic-color-secondary-color-lighter--secondary-gray: ''; // tint 15%
  --dynamic-color-secondary-color-lightest--secondary-gray: ''; // tint 30%

  // COLORS
  --error-color: $red;
  --success-color: $success-color;
  --warning-color: $warning-color;
  --light-gray: $light-gray;
  --light-mid-gray: $light-mid-gray;
  --mid-gray: $mid-gray;
  --gray: $gray;
  --card-border-gray: $card-border-gray;
  --black-pure: $black-pure;
  --white-pure: $white-pure;
  --black: $black;
  --dark-gray: $dark-gray;
  --lightest-gray: $lightest-gray;
  --secondary-gray: $secondary-gray;
  --comments-input-background-color: #EDEFF1;
  --kebab-menu-background-color: var(--comments-input-background-color);
  --kebab-menu-light-background-color: #FFFCFC3B;
  --kebab-menu-light-color: #B2BAC3;
  --kebab-menu-icon-color: #C6C6C7;
  --orange-warning-color: $orange-warning;
  --red-profile-inactive-badge: #FF6162;
  --editor-toolbar-background-color: #FBFCFF;
  --editor-toolbar-hover-background-color: #EBEBEB;
  --editor-toolbar-border-color: #F5F5F5;
  --editor-toolbar-active-icon-color: #009BF7;
  --editor-toolbar-disabled-icon-color: #C6C6C7;
  --tile-background-color: #F5F4FD;
  --yellow-warning-color: #F7B731;
  --light-background-color: rgba(82, 70, 215, 0.05);
  --lighter-background-color: #FBFCFF;
  --code-color: #E83E8C;
  --medium-separator-color: #C6C6C7;


  // NAVBAR
  --nav-color: #2B333A;
  --nav-text-color: #A0A5A8;
  --nav-text-active-color: $white-pure;
  --subnav-color: #40474E;
  --subnav-text-color: rgba(255, 255, 255, 0.56);
  --subnav-text-active-color: $white-pure;
  --nav-text-transform: none;
  --nav-box-shadow: 0 2px 4px 1px #D9DBDC;
  --logo-height: 3em;
  --logo-width: auto;
  --nav-height: 60px;
  --left-navbar-background-color: #F6F6FA;
  --left-navbar-hover-color: #F2F2F6;
  --main-left-navbar-extended-width: 256px;
  --main-left-navbar-collapsed-width: 92px;
  --subitems-line-color: #F6F6F6;

  // NAV TABS
  --nav-tabs-background-color: #F8F9FB;
  --nav-tabs-active-background-color: $light-gray;
  --nav-tabs-active-border-color: #c6c6c7;
  --nav-tabs-active-color: $cleary-purple;

  // LINKS
  --link-font-weight: 600;
  --highlight-color: $highlight-color;
  --link-color: var(--highlight-color);
  --background-color: $light-purple-background;
  --border-color: $light-gray;

  --danger-color: $red;
  --calendar-link-color: var(--text-color-primary);
  --card-background-color: $white;
  --orgchart-border-color: #BBB8C8;

  // CARDS
  --awarded-badge-card-hero-color: $teal;
  --shoutout-card-background-color: $orange;
  --surveys-card-background-color: var(--shoutout-card-background-color);
  --farewell-card-background-color: #9743f5;
  --achievement-card-background-color: #d531f1;
  --new-parent-card-background-color: #42b11c;
  --birthday-card-background-color: $purple;
  --birthday-card-label-color: $purple;
  --upcoming-birthday-color: $purple;
  --work-anniversary-background-color: $purple;
  --work-anniversary-label-color: $purple;
  --upcoming-work-anniversary-color: $purple;
  --new-employee-welcome-color: $dark-blue;
  --new-employee-welcome-label-color: $dark-blue;
  --upcoming-new-employee-welcome-color: $dark-blue;
  --custom-celebration-label-color: #2b35a6;
  --card-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);

  // BANNERS
  --banner-background-color: var(--highlight-color);
  --banner-text-color: $white;
  --secondary-color: var(--banner-background-color);

  // TAGS
  --tag-background-color: #707070;
  --skill-tag-background-color: #fff;
  --skill-tag-border-color: #c6c6c7;
  --skill-tag-text-color: #444f69;
  --skill-tag-hover-background-color: #5246d7;
  --skill-tag-hover-text-color: #fff;
  --status-label-gray-text-color: $mid-gray;
  --status-label-gray-background-color: #E8E8E9;
  --status-label-red-text-color: #FF6162;
  --status-label-red-background-color: #FAEAEB;
  --status-label-green-text-color: #33B766;
  --status-label-green-background-color: #E8F5EF;
  --status-label-yellow-text-color: #EC863B;
  --status-label-yellow-background-color: #FBF2EB;
  --status-label-blue-text-color: #3078F4;
  --status-label-blue-background-color: #E8F0FF;
  --status-label-orange-text-color: #FFA25E;
  --status-label-orange-background-color: rgba(255, 162, 94, 0.15);
  --admin-banner-green-background-color: #54C981;
  --admin-banner-red-background-color: #FF6162;
  --admin-banner-orange-background-color: #FFA25E;
  --admin-banner-blue-background-color: #4F79F0;
  --admin-banner-yellow-background-color: #fff3cd;
  --admin-banner-yellow-color: #856404;

  // LIKES
  --like-highlight-color: $red;
  --like-widget-width: 40px;

  // TYPOGRAPHY
  --h1-font-size: 36px;
  --h2-font-size: 28px;
  --h3-font-size: 24px;
  --h4-font-size: 21px;
  --h5-font-size: 18px;
  --h6-font-size: 15px;

  --base-font-family: $base-font-family;

  --text-color-primary: $black;
  --text-color-secondary: $mid-gray;
  --text-color-muted: #C2C7CC;
  --text-color-dark-muted: #7E7B96;
  --font-weight-inherit: inherit;
  --base-font-size: $base-font-size;
  --line-height: $line-height;
  --empty-state-text: rgba(14, 0, 60, 0.28);

  // BUTTONS
  --btn-primary-color: var(--highlight-color);
  --btn-muted-color: $secondary-gray;
  --button-border-radius: 24px;
  --btn-background-color: #F6F6FA;
  --btn-hover-background-color: #DEDEF8;

  // DROPDOWN
  --dropdown-hover-background-color: #F6F6FA;

  // GLOBAL SEARCH
  --search-bg-color: #EBF5FF;

  // TIMEZONE WIDGET
  --timezone-highlight-color: #EAF1FE;
  --timezone-sun-color: #FFD980;
  --timezone-moon-color: #183459;


  // ASK AI
  --ask-ai-start-color: #B830F8;
  --ask-ai-end-color: $cleary-purple;
  --ask-ai-background-color: rgba(82, 70, 215, 0.17);

  // BORDERS
  --square-border-radius: 5px;
  --square-border-radius-small: 3px;
  --square-border-radius-large: 8px;
  --square-border-radius-xlarge: 12px;
  --profile-image-border-radius: 100%;
  --profile-awarded-badge-border-width: 1px;
  --editable-content-hover-radius: 4px;
  --profile-photos-border-radius: 3px;

  // PROFILE PHOTOS
  --profile-photo-destroy-color: rgba(200, 200, 200, 0.75);
  --profile-photo-promote-color: rgba(0, 0, 0, 0.5);

  // QNA
  --icebreaker-label-color: $highlight-color; // Is intended for this color to be fixed and not change with --highlight-color
  --textbox-lightest-gray: $lightest-gray;
  --draft-event-banner-color: #E8E8E9;

  // JOURNEYS / ADMIN
  --journey-step-form-help-card-background-color: $dark-blue;
  --journeys-table-with-steps-header-background-color: #7E7B96;

  // JOURNEYS / TASKS
  --task-header-tile-background-color: var(--upcoming-birthday-color);
  --task-header-direct-reports-tile-background-color: var(--shoutout-card-background-color);

  // JOURNEYS / ONBOARDING BUDDIES
  --onboarding-buddies-card-background-color: var(--upcoming-birthday-color);

  // PAGES
  --page-width-narrow: 930px;
  --page-expiration-warning-modal-info-header-bg-color: #EEBB34;
  --page-workspace-text-color: #514D76;
  --pages-nav-width: 320px;

  // LEFT NAVBAR
  // This represents the width of the left sidebar nav.
  // It's meant to be the larger of: 233px or 17% of the viewport width.
  --left-nav-width: max(233px, 17vw);
  --org-chart-panel-width: max(295px, 17vw);

  // ADMIN
  --admin-header-box-shadow: 0px 1px 8px 0px #3944680A;
  --admin-content-y-margin: 32px;
  --admin-content-x-margin: 40px;

  // HOME
  --welcome-card-module-hover-background-color: #5246d70d;
  --homepage-widget-padding: 24px;
  --feed-widget-separator-color: $light-mid-gray;

// RIGHT NAVS
  --right-nav-width: 340px;

  // TEMPLATES
  --templates-nav-modal-width: 253px;

  // FEEDBACK
  --helpful-color: #71C789;
  --not-helpful-color: #FF6162;
  --feedback-counter-width: 50px;

  // TIP TAP EDITOR
  --editor-comment-background-color: #FFE4AA;

  // MODAL
  --preview-template-footer-background-color: #FBFCFF;

  // ARTICLES
  // 850px comes from being 8 columns of our default grid (80px), and 7 column gaps (30px)
  --article-content-width: 850px;

  // BASE LAYOUT
  --base-layout-border-color: #E5E7EB;
  --base-layout-sidebar-width: 250px;
  --base-layout-sidebar-background-color: #F7F7F7;
  --base-layout-sidebar-menu-item-color: #0E003C;
  --base-layout-sidebar-menu-item-hover-color: #E8E8E8;
  --base-layout-sidebar-menu-item-count-color: #7E7B96;

  // AI
  --ask-ai-border-color: #ECEBEF;
  --ai-border-radius: 20px;

  // TICKETING
  --ticket-comment-background-color: #E8F5FF;
  --ticket-private-comment-background-color: #FFF8E8;
}
