div.QuestionDetailPage {
  table {
    box-shadow: none;

    // First and last columns should not have padding left and right
    td:first-child,
    th:first-child {
      padding-left: 0;
    }

    td:last-child,
    th:last-child {
      padding-right: 0;
    }
  }

  .question-option-filter {
    min-width: 200px;
    z-index: $zindex-dropdown;
  }

  .AdminContent {
    max-width: 1085px;
  }

  .AnswerValue {
    max-width: 400px;
  }
}
