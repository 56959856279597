.slick-slider {
  .slick-slide {
    display: flex;
    justify-content: center;
  }

  .slick-prev,
  .slick-next {
    top: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    path,
    g {
      stroke: var(--border-color);
      fill: var(--border-color);
    }

    &:hover {
      path,
      g {
        stroke: var(--text-color-secondary);
        fill: var(--text-color-secondary);
      }
    }
  }

  .slick-prev {
    left: -1rem;
    -webkit-transform: translate(0, -1em);
    -ms-transform: translate(0, -1em);
    transform: translate(0, -1em);
  }

  .slick-next {
    right: -1rem;
    -webkit-transform: translate(0, -1em) rotate(180deg);
    -ms-transform: translate(0, -1em) rotate(180deg);
    transform: translate(0, -1em) rotate(180deg);
  }

  ul.pagination {
    //override default slick style attribute
    position: static;
    display: flex !important;
    justify-content: center;
    margin-top: 1.5em;

    li {
      border-radius: 100%;
      width: 8px;
      height: 8px;
      background-color: var(--text-color-muted);

      &.slick-active {
        background-color: var(--highlight-color);
      }

      .default-dot {
        width: 8px;
        height: 8px;
      }
    }
  }
}

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-prev {
  left: -20px;
}
.slick-next {
  right: -20px;
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: 'â€¢';
  text-align: center;
  opacity: 0.25;
  color: #000;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}
