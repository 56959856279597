.GreetingCardComments {
  display: flex;
  gap: 1rem;

  .ThanksMessage {
    box-shadow: var(--card-box-shadow);
    background-color: var(--tile-background-color);

    .ThankYou {
      font-weight: bold;
    }
  }

  .CommentsColumn {
    flex: 1 1 0;
    width: 0;
  }
}
