.TaskFilters {
  .AdvancedFilter {
    position: relative;

    .FunnelIcon {
      width: 33px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 28px;
        height: 28px;
      }

      &.isOpen {
        border: 1px solid var(--link-color);
        border-radius: var(--square-border-radius-small);
        background-color: var(--dynamic-color-link-color-lightest--link-color);
      }

      &.isInUse, &.isOpen {
        svg {
          path {
            fill: var(--link-color);
          }
        }
      }
    }

    .AdvancedFilter__popover {
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: 5px;
      z-index: $zindex-popover;
      box-shadow: var(--card-box-shadow);
      background-color: var(--white-pure);
      width: 800px;
      padding: 1rem;
    }
  }
}
