.Card-subtext {
  .UserLink {
    font-weight: var(--link-font-weight);
  }
}

.Card-dropdownMenu {
  top: 1.5em;
  right: 1.5em;
}

.Card-dropdownMenuItem {
  background-color: var(--card-background-color);

  &:hover {
    background-color: var(--dynamic-color-card-dropdown-item--card-background-color); //color(var(--card-background-color) shade(5%));
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.Card--scrollable {
  // Non-standard
  // ::-webkit-scrollbar and ::-webkit-scrollbar-thumb  are non-standard and is not on a standards track.
  // Do not use it on production sites facing the Web: it will not work for every user.
  // There may also be large incompatibilities between implementations and the behavior may change in the future.
  // ::-webkit-scrollbar is only available in Blink- and WebKit-based browsers (e.g., Chrome, Edge, Opera, Safari, all browsers on iOS, and others).

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--light-gray);
    border-radius: 6px;
  }

  .Card--scrollableSection {
    overflow-y: scroll;
    max-height: 400px;
    width: 104%;
  }

  @include max-media-width($bootstrap-md) {
    .Card--scrollableSection {
      overflow-y: scroll;
      max-height: 150px;
      width: 102%;
    }
  }
}
