.CommunicationContent {
  .ChannelTab {
    display: flex;
    align-items: center;
    color: var(--text-color-secondary);
    padding: 6px 8px;
    border-radius: var(--square-border-radius-small);

    .ChannelTab__icon {
      svg {
        height: 18px;
        width: 18px;
        fill: var(--text-color-secondary);
      }
    }

    &.isSelected {
      color: var(--link-color);
      background-color: var(--dynamic-color-link-color-lightest--link-color);

      .ChannelTab__icon {
        svg {
          fill: var(--link-color);
        }
      }
    }

    .XIcon {
      svg {
        height: 14px;
        width: 14px;
        fill: var(--text-color-secondary);

        &:hover {
          fill: var(--link-color);
        }
      }
    }

    &:hover {
      @extend .pointer;

      background-color: var(--dynamic-color-link-color-lightest--link-color);
    }
  }

  .SyncIcon {
    svg {
      height: 20px;
      width: 20px;

      path {
        fill: var(--link-color)
      }
    }
  }
}

.AddChannel-popover {
  svg {
    height: 18px;
    width: 18px;
  }
}
