.GoLinksList {
  .react-reveal {
    z-index: $zindex-base;
    position: relative;
  }

  .GolinksList_Grid {
    z-index: $zindex-one;
  }

  .GoLinksList_Url {
    font-weight: unset !important;
  }

  .GoLinksList_Name {
    max-width: 200px;
  }

  .GoLinksList_Description {
    max-width: 200px;
  }

  .white-bg-table {
    tbody {
      th {
        padding: 20px 0 20px 30px;

        &:last-of-type {
          padding: 20px 30px;
        }
      }

      td {
        padding: 14px 0 14px 30px;

        &:last-of-type {
          padding: 14px 30px;
        }
      }
    }
  }

  .GoLinksList_CreateAlias {
    transform: rotate(270deg);
  }

  .AdminButtonContainer-OnMobile {
    float: right;
    margin-top: -90px;
  }

  tr.with-aliases {
    td {
      vertical-align: top !important;
    }
  }
}
