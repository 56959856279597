.SearchResultsPage {
  .MobileFilterButton {
    display: none;

    @include small-size {
      display: flex;
      cursor: pointer;
      width: 50px;
      color: var(--link-color);
      font-weight: 600;

      svg {
        fill: var(--text-color-primary);
        width: 24px;
        height: 24px;
      }
    }
  }
}
