.Sidebar {
  @extend .h-100;

  flex-grow: 0;
  flex-shrink: 0;
  width: 375px;
  background-color: var(--left-navbar-background-color);

  &--floating {
    position: fixed !important;
    top: 0;
    right: 0;
    z-index: $zindex-fixed;
    box-shadow: var(--card-box-shadow);
    overflow: auto;
  }

  &__header {
    h5 { margin: 0; }

    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-primary);
    }
  }
}

.RootSidebar {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-modal;

  .Sidebar {
    transform: translateX(100%);
    transition: transform 0.3s linear;
    overflow-y: auto;
    background-color: var(--white-pure);
    width: 726px;
    box-shadow: var(--card-box-shadow);
    display: flex;
    flex-direction: column;
  }

  .RootSidebarContent {
    flex-grow: 1;
    overflow: auto;
    // adding these so that the scrollbar doesn't overlap the content
    margin-right: -1rem;
    padding-right: 1rem;
  }

  &.opening, &.visible {
    .Sidebar {
      transform: translateX(0);
    }
  }
}

.RootSidebarBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $zindex-modal-backdrop;
}

.hide-main-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hide-main-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}
