.CelebrationsWidget {
  container-type: inline-size;

  .CelebrationsWidget-Title {
    padding: 24px 0px 12px 24px;
  }

  .CelebrationListItem {
    border: 1px solid transparent !important;
    overflow: hidden;

    &:hover {
      @extend .bg-highlight-color-tint-90;
      border: 1px solid var(--highlight-color) !important;

      .CelebrationSignStatus {
        transform: none;
      }
    }

    .EmployeeThumbnailPhoto {
      border-radius: var(--profile-image-border-radius);
    }

    .CelebrationWithCakeIcon {
      svg {
        width: 140px;
      }
    }

    @container (max-width: 250px) {
      .CelebrationWithCakeIcon {
        display: none
      }
    }

    .CelebrationEmoji {
      position: absolute;
      height: 28px;
      width: 28px;
      bottom: -5px;
      right: -5px;
      background-color: var(--white-pure);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      box-shadow: var(--card-box-shadow);
    }

    @container (max-width: 400px) {
      .CelebrationListItem {
        .card-body {
          padding: 24px 11px;
        }
      }

      .CelebrationEmoji {
        @extend .text-small;
        height: 22px;
        width: 22px;
        bottom: -8px;
        right: -8px;
      }
    }

    .CelebrationSignStatus {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(calc(100% - 1rem - 10px)); // 1rem is padding + 10px is the width of the icon
      background-color: var(--highlight-color);
      border-radius: 0 var(--square-border-radius);
      transition: transform 0.3s ease-in-out;
    }

    .CelebrationText {
      .birthday {
        color: var(--birthday-card-label-color);
      }

      .work_anniversary {
        color: var(--work-anniversary-label-color);
      }

      .new_employee_welcome {
        color: var(--new-employee-welcome-label-color);
      }

      .custom_celebration {
        color: var(--custom-celebration-label-color);
      }
    }

    .UserInformation {
      overflow-wrap: anywhere;
    }
  }


  .BalloonsWithGiftImage {
    height: 165px;
  }
}
