.EditLayoutCollectionPage {
  display: flex;;
  top: 0;
  left: 0;
  margin: 0 -1.5rem -4rem -1.5rem;
  min-width: 100vw;
  min-height: calc(100vh - var(--nav-height));

  .EditableGridContainer {
    background-color: var(--light-gray);
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0 1rem 3rem 1rem;

    &.isSidebarOpen {
      margin-left: var(--left-nav-width);
    }
  }

  .EditableGridLayout {
    border-radius: var(--square-border-radius);
    background-color: var(--background-color);
    min-height: 100%;
    padding: 1.5rem;

    .react-grid-layout {
      min-height: 100%;
    }
  }
}
