.GoLinksList {
  .FeaturedGoLinks {
    background-color: var(--white-pure);
    box-shadow: var(--card-box-shadow);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;

    .label {
      font-size: 14px;
      font-weight: bold;
    }

    .AddFeaturedGoLink {
      .btn-link {
        background: none; // Remove background from "+ Featured Link" button
        border: none;
        color: var(--text-color-secondary);
        font-weight: normal;
        padding: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
