
.SocialShare {
  width: 100%;

  .border-container {
    border: 1px solid var(--border-color);
  }
}

.NetworksList {
  .social-network-list {
    list-style: none;

    .social-share-button:hover {
      opacity: 0.8;
    }

    li > span {
      display: inline-block;
    }
  }
}

.ShareContent {
  width: 100vw;

  &--large {
    width: 480px;
  }

  .suggested-text-container {
    border: 1px solid var(--border-color);
  }

  .copy-button {
    background-color: transparent;
    border: 0;

    &:active {
      outline: none;
    }

    .wrapper {
      border-radius: 100%;
      padding: 8px;

      &:hover {
        background-color: var(--background-color);
      }
    }

    svg {
      fill: var(--text-color-primary);
      position: relative;
      top: -2px;
      width: 16px;
      height: 16px;
    }
  }
}
