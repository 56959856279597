.TiptapEditor {
  .ProseMirror-focused {
    outline: none;
  }

  &.caret-highlight-color {
    caret-color: var(--highlight-color);
  }

  .collaboration-cursor__caret {
    border-left: 1px solid;
    border-right: 1px solid;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }
  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: var(--white-pure);
    font-size: 0.867rem; // text-small
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1em 0.3em;
    position: absolute;
    top: -1.4em;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    white-space: nowrap;
    animation: shrinkLabel 2s;
    animation-fill-mode: forwards;
    animation-timing-function: steps(1, end);
    z-index: $zindex-sticky;
  }

  @keyframes shrinkLabel {
    to {
      width: 5px;
      height: 5px;
      font-size: 0;
    }
  }

  .TiptapEditorSourceCodeView {
    min-height: 350px;
  }

  table {
    .column-resize-handle {
      background-color: var(--highlight-color);
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
      z-index: $zindex-base;
    }
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  span[data-inline-comment-id] {
    background: var(--editor-comment-background-color);
  }

  p.is-editor-empty:first-child::before {
    color: var(--text-color-secondary);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  &.RoundedTiptapEditor {
    background-color: var(--white-pure);
    border: 1px solid var(--border-color);
    border-radius: var(--square-border-radius-large);
    padding: 2px 12px 16px;

    .TiptapToolbar {
      background-color: var(--white-pure);
    }
  }

  &.straight-borders {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    min-height: 175px;

    .TiptapToolbar {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  summary::marker {
    content: none;
  }

  .details {
    div {
      margin-left: 0 !important;
    }
  }
}

.TiptapView {
  // We import this css file so that the styles we use for emails and the
  // styles in the frontend are all in one place and we don't have to remember
  // to update both places when we make changes.
  // If you are adding a style that only impacts views in the frontend, add it here.
  // If it also impacts emails, add it in tiptapView.css
  @import '../../../styles/_tiptapView.css';

  .ProseMirror {
    overflow-wrap: anywhere;
  }

  .video-player-container {
    display: inline-block;
  }

  &.isSourceCodeViewEnabled {
    display: none;
  }

  table {
    td, th {
      &.selectedCell {
        outline: 1px solid var(--highlight-color) !important;
      }
    }
  }

  li, li::marker {
    font-size: inherit;
    color: inherit;
  }
}
