.PagesNavComplexTree {
  .ItemArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex: none;

    .ChevronDownIcon {
      svg {
        width: 16px;
        height: 16px;
        transform: rotate(-90deg);
      }
    }

    &.isExpanded {
      .ChevronDownIcon {
        svg {
          transform: none;
        }
      }
    }
  }

  .Item {
    &.highlighted {
      > .ItemTitle-row {
        background-color: var(--dynamic-color-primary-color-lightest--highlight-color);

        &:hover {
          .AddSubpageButton {
            display: block;
          }
        }

        .AddSubpageButton {
          .IconButtonWithPopover {
            &:hover {
              background-color: var(--left-navbar-background-color);
            }
          }
        }
      }
    }

    &.isDraggedOver {
      > .ItemTitle-row {
        .ItemTitle-content {
          border: 2px dashed var(--highlight-color);
        }
      }
    }
  }

  .ItemArrowPlaceholder {
    width: 32px;
    height: 32px;
    flex: none;
  }

  .ItemTitle-content {
    height: 32px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  @for $i from 0 to 6 {
    .ItemTitle-content[data-depth="#{$i}"] {
      padding-left: calc(16px + (#{$i} * 16px));
    }
  }

  .ItemTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    color: var(--text-color-primary);

    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: var(--highlight-color);
    }

    &.isCurrentNodeActive {
      color: var(--link-color);
    }

    @include up-to-medium-size {
      font-size: 15px;
    }
  }

  .DragBetweenLine {
    border: 2px solid var(--highlight-color);
    height: 0;
    width: 100%;
  }

  .AddSubpageButton {
    display: none;

    .IconButtonWithPopover {
      width: 24px;
      height: 24px;
      border-radius: var(--square-border-radius);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 14px;
        height: 14px;
      }

      &:hover {
        background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
      }
    }
  }
}

.AddSubpageTooltip {
  background-color: var(--btn-primary-color);

  .arrow::after {
    border-bottom-color: var(--btn-primary-color);
  }
}
