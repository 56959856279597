.PreviewTemplateModal {
  min-width: calc(var(--article-content-width) + 50px);
  width: calc(var(--article-content-width) + 50px);

  .modal-body {
    padding-bottom: 0;
  }

  .PreviewTemplateModalContent {
    // 206 means modal margins, header and footer spacing
    max-height: calc(100vh - 206px);
    width: var(--article-content-width);
  }

  .PreviewTemplateModalFooter {
    margin-left: -30px;
    margin-right: -30px;
    padding: 30px;
    background: var(--preview-template-footer-background-color);
    box-shadow: 0px -3px 13px 0px rgba(0, 0, 0, 0.08);
    position: relative;
  }
}
