.replace-navbar {
  z-index: $zindex-replace-navbar;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: var(--nav-height);
  border-bottom: 1px solid var(--secondary-gray);
  background-color: var(--white-pure) !important;
}
