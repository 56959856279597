
.LeftNavItem {
  // Defining some variables used only in this component to avoid magic numbers
  --icon-size: 20px;
  --subitem-height: 32px;
  --curved-corner-height: 8px;
  --item-x-padding: 12px;

  .Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px var(--item-x-padding);
    border-radius: var(--square-border-radius-large);
    height: 40px;
    width: 100%;

    &:hover {
      background-color: var(--dynamic-color-secondary-color-lightest--secondary-gray);
    }

    &.active {
      background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);

      .LeftNavItem-content {
        color: var(--new-ui-nav-text-active-color);
      }
    }

    .LeftNavItem-content {
      display: flex;
      align-items: center;
      gap: 8px;

      font-size: 14px;
      font-weight: 500;
      color: var(--new-ui-nav-text-color);
      white-space: nowrap;

      svg {
        width: var(--icon-size);
        height: var(--icon-size);
      }

      .LeftNavItem-label {
        transition: opacity 0.5s ease-in-out;
        opacity: 0;

        &.isOpen {
          opacity: 1;
        }
      }
    }

    &.SubItem {
      position: relative;
      height: var(--subitem-height);
      font-size: 12px;
      font-weight: 500;
      color: var(--new-ui-nav-text-color);
      margin-left: auto;

      &.active {
        color: var(--new-ui-nav-text-active-color);
      }

      .SubItem-label {
        flex-shrink: 0;
      }
    }

    .ToggleSubItemsButton {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      &:hover {
        background-color: var(--dynamic-color-secondary-color-dark--secondary-gray);
      }

      svg {
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
        display: flex;
        align-items: center;
      }

      &.isItemOpen svg {
        transform: rotate(270deg);
      }
    }
  }

  .SubItems-container {
    position: relative;
    padding-top: 4px;
    padding-left: calc(var(--item-x-padding) + (var(--icon-size) / 2));
    margin-left: var(--item-x-padding);

    // vertical line
    &::before {
      content: '';
      position: absolute;
      left: calc(var(--icon-size) / 2); // Half of the icon size to center it
      top: 0;
      height: calc(100% - var(--subitem-height) + var(--curved-corner-height));
      width: 1px;
      background-color: var(--subitems-line-color);
    }

    // Each subitem has a curved corner
    .SubItem {
      &::after {
        content: '';
        position: absolute;
        left: calc(-1 * var(--item-x-padding));
        width: 13px;
        height: var(--curved-corner-height);
        top: calc(50% - (var(--curved-corner-height) / 2));
        border-left: 1px solid var(--subitems-line-color);
        border-bottom: 1px solid var(--subitems-line-color);
        border-bottom-left-radius: 8px;
        transform: translate(0, -50%);
      }
    }
  }
}


