.Banner-container {
  min-height: 60px;
  background-color: var(--banner-background-color);
  color: var(--banner-text-color);

  &.BelowAdminHeader {
    margin-top: -20px;
  }
}

.Banner-close {
  right: 1em;

  .CloseIcon {
    top: unset;
    right: unset;
    position: unset;

    stroke: var(--banner-text-color);
    fill: var(--banner-text-color);

    &:hover {
      stroke: var(--dynamic-color-banner-hover-text--banner-text-color);
      fill: var(--dynamic-color-banner-hover-text--banner-text-color);
    }
  }
}

.Banner-text {
  color: var(--banner-text-color);
}

a .Banner-text {
  &:hover {
    color: var(--dynamic-color-banner-hover-text--banner-text-color);
  }
}
