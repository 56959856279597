.analytics-card {
  @extend .d-flex, .flex-column, .align-items-start, .background-color-white;

  padding: 28px 30px 25px;
  gap: 14px;
  box-shadow: var(--card-box-shadow);
  border-radius: var(--square-border-radius-large);

  header {
    @extend .w-100, .bordered-bottom;
  }

  footer {
    @extend .w-100, .bordered-top;
  }

  .analytics-graph {
    height: 200px;
  }

  .analytics-bar-graph {
    height: 370px;
  }

  .analytics-legend-item-color {
    @extend .mt-2, .mr-2;

    border-radius: var(--square-border-radius-small);
    height: 14px;
    width: 14px;
  }

  .PaginationContainer {
    @extend .w-100;
  }
}

.white-card {
  background-color: var(--white-pure);
  box-shadow: var(--card-box-shadow);
  border-radius: var(--square-border-radius-large);
}
