.AwardeeCard {
  max-width: 420px;
  min-width: 300px;

  &.RemoveAwardee {
    &:hover {
      .TrashIcon {
        display: block;
      }
    }
  }

  .AwardeeCardBody {
    display: flex;
    align-items: center;
    padding: 10px 14px;

    .PersonInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    img {
      height: 40px;
      width: 40px;
      border-radius: var(--profile-image-border-radius);
      margin-right: 3%;
    }
  }

  .TrashIcon {
    text-align: center;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: none;

    svg {
      margin-top: 3px;
    }

    &:hover {
      background-color: var(--light-gray);
    }
  }
}
