.FollowersModal {
  margin-top: var(--nav-height);

  .modal-header {
    margin: 0.5rem;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    max-height: calc(80vh - var(--nav-height));
  }

  .search-input-group {
    svg {
      fill: var(--text-color-secondary);
    }
  }

  .search-input {
    height: 50px;
  }

  .users-list {
    overflow-y: auto;
    height: 100%;
  }

  .GroupFollower {
    &:hover {
      background-color: var(--background-color);
    }

    .GroupMiniCardPhoto {
      width: 35px;
      height: 35px;
    }
  }
}

.FollowersLink {
  line-height: 1.6rem;

  &.empty-state {
    color: var(--empty-state-text);
    font-weight: 500;
  }
}
