.AppEditorPage {
  .AppCard {
    padding: 28px 30px;
    gap: 24px;

    background-color: var(--card-background-color);
    border-radius: var(--square-border-radius);

    input {
      width: 50%;
    }

    .AudienceSelector {
      div {
        font-size: 13px;
      }

      h4 {
        @extend .p-1, .mb-0;

        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}
