/* This file need to be a .css because we also use it in the  lib/utils.rb
There we use Premailer, that only accepts css files */

p {
  margin: 0;
  font-size: inherit;
}

/* TipTap adds a <br> tag to empty p tags when editing, so we need to simulate that when viewing the source code */
p:empty {
  height: 22.5px;
}

code {
  color: var(--code-color);
  font-size: 87.5%;
}

pre {
  background: var(--black);
  border-radius: var(--square-border-radius);
  color: var(--white-pure);
  font-family: JetBrainsMono, monospace;
  padding: 0.75rem 1rem;
}

pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
  word-break: break-word;
  white-space: pre-wrap;
}

pre .hljs-comment,
pre .hljs-quote {
  color: #616161;
}

pre .hljs-variable,
pre .hljs-template-variable,
pre .hljs-attribute,
pre .hljs-tag,
pre .hljs-name,
pre .hljs-regexp,
pre .hljs-link,
pre .hljs-selector-id,
pre .hljs-selector-class {
  color: #f98181;
}

/* stylelint-disable selector-class-pattern */
pre .hljs-number,
pre .hljs-meta,
pre .hljs-built_in,
pre .hljs-builtin-name,
pre .hljs-literal,
pre .hljs-type,
pre .hljs-params {
  color: #fbbc88;
}
/* stylelint-enable selector-class-pattern */

pre .hljs-string,
pre .hljs-symbol,
pre .hljs-bullet {
  color: #b9f18d;
}

pre .hljs-title,
pre .hljs-section {
  color: #faf594;
}

pre .hljs-keyword,
pre .hljs-selector-tag {
  color: #70cff8;
}

pre .hljs-emphasis {
  font-style: italic;
}

pre .hljs-strong {
  font-weight: 700;
}

img {
  vertical-align: text-bottom;
  max-width: 100%;
}

video,
iframe {
  max-width: 100%;
}

/* stylelint-disable-next-line selector-class-pattern */
.tableWrapper {
  max-width: 100%;
  position: relative;
}

table {
  max-width: 100%;
  overflow-wrap: break-word;
}

table th {
  background-color: var(--light-gray);
}

table td,
table th {
  position: relative;
  outline: 1px solid var(--border-color);
}

table td.highlighted-border,
table th.highlighted-border {
  outline: 1px solid var(--danger-color);
}

table td.thick-border,
table th.thick-border {
  outline-width: 2px;
}

table.pasted-table th {
  background-color: none;
}

table.pasted-table td,
table.pasted-table th {
  border: none !important;
}

table.dashed-borders-table td,
table.dashed-borders-table th {
  outline-style: dashed;
  border: none;
}

table.alternating-rows-table tr:nth-child(even) {
  background-color: var(--lightest-gray);
}

table.borderless-table td,
table.borderless-table th {
  outline: none;
  border: none;
}

blockquote {
  /* split out otherwise Premailer does not set the border-left-color */
  border-left: 3px solid;
  border-left-color: var(--border-color);
  padding-left: 1rem;
  margin-left: 0;
}

a {
  /* using !important because copy-paste from google docs has inline styles we dont want */
  color: var(--link-color) !important;
  text-decoration: none;
}

strong span {
  font-weight: bold !important;
}

/* stylelint-disable custom-property-pattern */
a:hover {
  color: var(--dynamic-color-ql-editor-link--link-color) !important;
}

.details {
  display: flex;
  margin: 1rem 0;
  padding: 0.5rem;
}

.details > button {
  display: flex;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
}

.details > button::before {
  content: "\25B6";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
}

.details.is-open > button::before {
  content: "\25BC";
}

.details > div {
  flex: 1 1 auto;
  margin-left: 16px; /* aligning with the summary */
}

.details:last-child {
  margin-bottom: 0;
}

summary::marker {
  width: 100px;
}

/*
  if we have a span inside a u or s tag we need to make them inline-block to override the parent's
  text-decoration, otherwise it will not match the color or size of the text
  for some reason, text-decoration is not inherited and if a parent has text-decoration: underline,
  the child will have it as well unless we make it an inline-block
  https://stackoverflow.com/questions/1823341/how-do-i-get-this-css-text-decoration-override-to-work
*/

s span {
  display: inline-block;
  text-decoration: line-through;
}

u span {
  display: inline-block;
  text-decoration: underline;
}

u s span,
s u span {
  display: inline-block;
  text-decoration: underline line-through;
}

/*
  Froala (our old Editor) styled things differently, so when we transform we would sometimes lose the link color
  this will ensure that we keep the link color when transforming
  end users won't be able to change this, but that was the default behavior for Froala
  for many years without complaints. This will only happen on transformed content
*/
a.force-link-color span {
  color: var(--link-color) !important;
}

a.force-link-weight-300 {
  font-weight: 300 !important;
}

a.force-link-weight-400 {
  font-weight: 400 !important;
}

a.force-link-weight-500 {
  font-weight: 500 !important;
}

a.force-link-weight-600 {
  font-weight: 600 !important;
}

a.force-link-weight-700 {
  font-weight: 700 !important;
}

a.force-link-weight-800 {
  font-weight: 800 !important;
}
