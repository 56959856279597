.TasksWidget {
   container-type: inline-size;

   .GroupTasksCount {
    display: inline;
    background-color: var(--danger-color);
    color: white;
    border-radius: 100%;
   }

  .TasksGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  @container (min-width: 700px) {
    .TasksGrid {
      grid-template-columns: 1fr 1fr;
    }
  }

  .ClipboardIllustration {
    height: 100px;
  }
}
