.multi-select-users-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 300px;
  width: 100%;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
  overflow-y: auto;

  .CloseIcon {
    position: initial;

    &:hover {
      stroke: var(--link-color);
      fill: var(--link-color);
    }
  }

  a {
    width: 100%;
    border-bottom: 1px solid  var(--btn-muted-color);
    &:last-child{
      border: 0;
    }

    .EmployeeMiniCard {
      width: 100%;

      .EmployeeMiniCard-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
