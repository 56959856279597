.App:has(.SlideShowPage) {
  header {
    display: none;
  }

  #SlackFeedback {
    display: none;
  }
}

.SlideShowPage {
  height: calc(100vh);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 24px;
  padding-top: 100px;

  &--comments {
    .SlideShowPage__Slider, .SlideShowPage__Slider__Actions {
      max-width: 450px;
    }
  }

  @include up-to-medium-size {
    user-select: none;
    padding: 0px;
    padding-top: 12px;

    .SlideShowPage__Slider__Actions .autoplay-btn {
      display: none;
    }

    .SlideShowPage__Slider__Controls {
      display: none;
    }

    .SlideShowPage__Slider__wrapper {
      width: 100%;
      float: left;
    }

    .SlideShowPage__Slider__Actions {
      padding: 0px 8px;
    }

    .SlideShowPage__Slider__CoverSlide__wrapper {
      border-radius: 0;
    }

    .SlideShowPage__Slider__CommentSlide__wrapper {
      border-radius: 0;
      padding: 16px;
      padding-bottom: 0px;
    }

    .SlideShowPage__Slider__CommentSlide > div {
      width: calc(100vw - 16px);
      padding: 0px;
    }
  }

  .CelebrationPage__background {
    overflow: hidden;
  }

  .open-card-effect {
    background: var(--secondary-color);

    &.transparent {
      background: transparent;
    }

    svg {
      path[fill="rgb(82,69,214)"] {
        fill: var(--highlight-color);
      }

      path[fill="rgb(226,65,137)"] {
        fill: var(--secondary-color);
      }

      path[fill="rgb(255,0,114)"] {
        fill: var(--secondary-color);
      }
    }
  }

  &__Slider {
    @extend .visible-scroll;

    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    position: relative;
    gap: 8px;
    max-width: 1920px;

    &__wrapper {
      width: calc(100vw - 48px);
      float: left;

      &.slide-next-enter {
        transform: translateX(100%);
      }

      &.slide-next-enter-active {
        transform: translateX(0);
        transition: transform 300ms ease-out;
      }

      &.slide-previous-enter {
        transform: translateX(-100%);
      }
      &.slide-previous-enter-active {
        transform: translateX(0);
        transition: transform 300ms ease-out;
      }
    }

    &__CoverSlide {
      @include celebration-header-bg;

      padding-top: 0px;
      display: flex;
      width: 100%;
      max-width: $full-hd-width;
      height: 420px;
      background-size: cover;

      &__wrapper {
        position: relative;
        border-radius: 24px;
        overflow: hidden;
        box-shadow: var(--card-box-shadow);
      }

      @media only screen and (min-width: $bootstrap-lg) {
        &.work_anniversary {
          .illustration {
            transform: scale(1.5) translate(-65px, -33px) !important;
          }
        }

        &.new_employee_welcome {
          .illustration {
            transform: scale(1.5) translate(-65px, -33px) !important;
          }
        }
      }

      .CelebrationHeadlineWrapper {
        padding: 24px;
      }
    }

    &__CommentSlide {
      display: flex;
      margin-left: -8px;
      margin-right: -8px;
      float: left;

      @include up-to-large-size {
        columns: 1;
      }

      &__wrapper {
        height: 645px;
        position: relative;
        padding: 25px;
        overflow: auto;
        background-color: var(--tile-background-color);
        border-radius: 8px;
        box-shadow: var(--card-box-shadow);
        overflow-x: hidden;
        overflow-y: auto;
      }

      > div {
        width: 416px;
        margin-right: 75px;
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &__Actions {
      width: 100%;
      max-width: 1920px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      &__effect-btn {
        background: var(--white-pure);
        display: flex;
        align-items: center;
        font-size: 21px;
        padding: 0px 7px;
        border: 1px solid var(--btn-primary-color);
        border-radius: 50%;
        cursor: pointer;
      }

      .replay-btn svg {
        width: 26px;
        height: 26px;
      }
    }

    &__Controls {
      z-index: $zindex-tooltip;
      position: absolute;
      top: calc(50% - 10px);
      padding: 2px;
      border-radius: 50%;
      background: var(--white-pure);
      border: 1px solid var(--btn-primary-color);
      cursor: pointer;

      &--left {
        left: -42px;
      }

      &--right {
        right: -42px;
      }

      &--right-cover {
        right: -16px;
      }

      svg {
        color: var(--btn-primary-color);
        width: 26px;
        height: 26px;
      }
    }

    &__SliderProgress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      gap: 2px;
      background: $lightest-gray;
    }

    &__Progress {
      width: 100%;
      height: 4px;
      background-color: $mid-gray;

      .active {
        background-color: var(--highlight-color);
        height: 4px;
      }
    }
  }
}

.slide-next-enter {
  transform: translateX(100%);
}

.slide-next-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-out;
}

.slide-previous-enter {
  transform: translateX(-100%);
}
.slide-previous-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-out;
}
