div.WhereToFindMeInfoModal {
  &.cleary-modal-md.AppModal {
    max-width: 782px;
  }

  div.modal-header {
    padding: 12px 30px;
  }

  div.modal-body {
    padding: 0 30px 30px 30px;
  }

  div.field-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;

    @include medium-size {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
    }

    @include small-size {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
    }

    div.item {
      padding: 18px;
      border: 0.5px solid var(--skill-tag-border-color);
      border-radius: var(--square-border-radius);

      header {
        display: flex;
        align-items: center;

        svg {
          width: 26px;
          height: 26px;
          fill: var(--link-color);
        }
      }

      ul {
        margin-top: 1rem;
        margin-bottom: 0;
        padding-left: 20px;

        li {
          @extend .text-small, .text-secondary;

          &:not(:first-child) {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
