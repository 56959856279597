.CorrespondentSelect {
  width: 75%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 1rem;

  @include small-size {
    width: 100%;
    display: block;
  }

  .ClearyReactSelect__option--is-focused {
    background-color: var(--search-bg-color) !important;
    .EmployeeMiniCard {
      background-color: initial !important;
    }
  }

  .ClearyReactSelect__option--is-selected {
    background-color: var(--highlight-color) !important;
  }

  .ClearyReactSelect__menu {
    z-index: $zindex-dropdown !important;
  }
}
