div.CreateUserPage,
div.EditUserPage {
  .AdminHeader {
    margin-bottom: 0px;

    .bordered-thumbnail {
      height: 80px;
      width: 80px;
    }
  }

  .InfoIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-secondary);
    }
  }

  .UserFormErrorBanner {
    color: var(--white-pure);

    li,
    div {
      color: var(--white-pure);
    }

    ul {
      margin-bottom: 0px;
    }
  }

  .UserSettingsSection,
  .UserPermissionsTab,
  .AdminAlertsTab,
  .CollapsibleCard {
    box-shadow: var(--card-box-shadow);
    border-radius: 7px;
    background-color: var(--white-pure);
  }

  .form-group {
    label {
      font-weight: 500;
    }

    .UserRadioButtonField {
      input {
        height: 38px;
        width: auto !important;
      }
    }
  }

  div.ExtraUserFieldsSection,
  div.LegalAcknowledgementsSection,
  div.SoftLaunchAccessSection,
  div.ContactInformationSection,
  div.PersonalInformationSection,
  div.RoleDetailsSection,
  div.LocationAndTeamsSection,
  div.BasicInformationSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @include up-to-medium-size {
      grid-template-columns: 1fr;
    }
  }

  div.PhotosSection {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @include up-to-medium-size {
      grid-template-columns: 1fr 1fr;
    }

    .ImageSelector-dropzone,
    .loading-container {
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }


    .ImageSelector {
      max-width: none;
      width: 100%;
      height: 100%;

      .ImageSelector-image {
        width: 100%;
        height: 100%;
      }

      .ImageSelector-dropzone {
        border-radius: 50%;
        display: flex;
        padding-top: 0%;
        background-color: var(--lightest-gray);
        border: none;
        position: relative;
        padding-bottom: 100%;

        svg {
          position: absolute;
          top: 40%;
          left: 40%;
          width: 20%;
          height: 20%;

          path {
            fill: var(--gray);
          }
        }
      }
    }

    div.Picture {
      width: 100%;
      height: 100%;

      .picture-inner-container {
        height: 100%;
        width: 100%;
      }
    }
  }

  div.BasicInformationSection {
    .employee-id-field {
      grid-column-start: 1;
    }

    .title-field {
      grid-column-start: 1;
    }
  }

  div.RoleDetailsSection {
    .end-date-field {
      grid-column-start: 1;
    }
  }

  div.PersonalInformationSection {
    .custom-field,
    .bio-field {
      grid-column-start: 1;
      grid-column-end: -1;
    }

    .birthday-field {
      .react-datepicker-popper {
        z-index: $zindex-dropdown;
      }
    }
  }

  div.ContactInformationSection {
    .additional-emails-field {
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

  div.SoftLaunchAccessSection {
    .grant-button,
    .revoke-button {
      max-width: 150px;
    }
  }

  .AdminBanner {
    padding: 18px 40px; // So that it matches the padding of the AdminHeader
  }
}
