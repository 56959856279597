.SendInvitationsModal {
  .confetti-background:before {
    background-size: 220px 150px;
  }

  .ThankYouMessage {
    max-width: 330px;
    text-align: center;
  }

  .GroupHighFiveImage {
    margin-bottom: -1rem;
  }
}
