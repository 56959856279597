.RequestedViaLink {
  a:hover {
      color: var(--highlight-color) !important;
  }

  .ExternalLinkIcon {
    svg {
      height: 12px;
      width: 12px;
      margin-bottom: 1px;

      path {
        fill: currentColor;
      }
    }
  }
}
