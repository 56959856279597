
.SlashCommandListContainer {
  background-color: var(--white-pure);
  z-index: $zindex-modal;
  border: 1px solid var(--border-color);
  min-width: 280px;
  max-height: 300px;
  flex-direction: column;
  overflow: auto;

  .SlashCommandList {
    border: none;

    .item {
      padding: 7px 14px;
      display: flex;
      align-items: center;

      div.IconBox {
        padding: 8px;
        background-color: var(--white-pure);
        min-width: 34px;
        min-height: 34px;
      }

      svg {
        width: 18px;
        height: 18px;
        fill: var(--text-color-primary)
      }
    }
  }
}

.SlashCommandSecondaryMenu {
  background-color: var(--white-pure);
  z-index: $zindex-modal;
  border: 1px solid var(--border-color);
  width: max-content;
  overflow: auto;

  .TableDropdownContent {
    max-width: 215px;
  }
}
