.PageArchiveContent {
  margin-left: 0;
  transition: margin-left 0.5s ease-in-out;
  justify-content: flex-end;

  .DeletePageButton,
  .UnarchivePageButton {
    max-width: 20px;
  }

  .AsyncSearchInputGroup {
    max-width: fit-content;
  }
}
