.AiPlatformKnowledgeIngestionPage {
  &__wrapper {
    max-width: 857px;

    table {
      th {
        border-top: 0;
      }

      td, th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      tr:last-child {
        td {
          border-bottom: 0;
        }
      }

      td:first-child, th:first-child {
        padding-left: 0;
      }
    }
  }

  &__slider {
    background-color: var(--white-pure);

    .podium {
      svg {
        margin-top: 52px;
      }
    }

    .slick-arrow {
      background-color: #A8A8A833;

      svg {
        path {
          fill: var(--link-color);
        }
      }
    }

    .slick-prev {
      left: 10px;
    }

    .slick-next {
      right: 10px;
    }

    ul.pagination {
      position: absolute;
      bottom: 15px;
    }

    .text-3xl {
      line-height: 2.5rem;
    }

    .icon svg {
      fill: var(--link-color);
      width: 40px;
      height: 40px;
    }

    .BookReview {
      img {
        border-radius: 50%;
        width: 42px;
        height: 42px;
      }
    }

    .unanswered-chart-container {
      width: 350px;
      height: 175px;
      position: relative;
      overflow: hidden;

      .unanswered-chart {
        width: 350px;
        height: 350px;
        border-radius: 50%;
        transform: rotate(-90deg);

        &::after {
          content: '';
          position: absolute;
          background: var(--white-pure);
          width: 290px;
          height: 280px;
          border-radius: 100%;
          margin-left: 25px;
          margin-top: 35px;
        }
      }

      .unanswered-chart-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }

  }

  .TopFAQ-wrapper {
    > span:first-child {
      @extend .text-large, .bold;
      margin-bottom: 0.5rem;
    }

    > span:nth-child(3) {
      @extend .text-secondary, .text-normal;
    }
  }

  .faqs-empty-state {
    padding: 2rem 8rem;
    text-align: center;
  }
}
