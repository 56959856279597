.SettingsLink {
  .SidebarItem {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: var(--square-border-radius-large);

    color: var(--new-ui-nav-text-color);

    &:hover {
      @extend .pointer;
      background-color: var(--base-layout-sidebar-menu-item-hover-color);
    }

    &.active {
      background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);
      color: var(--new-ui-nav-text-active-color);
    }

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: currentColor;
      }
    }
  }

}

.HelpdeskSettingsMenu {
  padding: 8px 10px;
  box-shadow: var(--card-box-shadow);

  .MenuItem {
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: var(--square-border-radius-large);

    &:hover {
      @extend .pointer;
      background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);
      color: var(--new-ui-nav-text-active-color);
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
