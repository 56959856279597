.OptInPage, .OptOutPage, .ErrorSavingBuddyResponsePage, .DoNotWantOnboardingBuddyPage {
  padding-top: 50px;

  .container {
    max-width: 410px;
    padding: 28px 35px 35px 35px;
    border-radius: 8px;
    background-color: var(--white-pure);
    box-shadow: var(--card-box-shadow);
  }
}
