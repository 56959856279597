.JourneysTableWithSteps {
  .StepTemplateTableHeader {
    position: relative;

    .trigger-info-container {
      background-color: var(--journeys-table-with-steps-header-background-color);
      height: 32px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .trigger-info {
      height: 30px;
      font-size: 11px !important;
      line-height: 15px;
      text-align: center;
    }

    .step-link-container {
      height: 60px;
      border-bottom: 1px solid var(--border-color);
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .step-name {
      font-size: 13px;
      line-height: 17px;
      text-align: center;
    }

    .for-role {
      line-height: 15px;
      height: 35px;
      font-size: 11px !important;
      padding-top: 5px;
      text-align: center;
    }

    .step-name,
    .for-role {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  table {
    thead {
      tr {
        height: 140px;

        th {
          padding-bottom: 10px;
          padding-left: 10px;
          padding-right: 10px;
          vertical-align: bottom;
        }

        th:has(.AddCustomStepHeader) {
          background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
        }
      }
    }

    tbody {
      tr {
        height: 50px;

        td {
          padding-top: 0px;
          padding-bottom: 0px;
          padding-left: 10px;
          padding-right: 10px;
        }

        td:has(.CustomStep, .AddCustomStepButton) {
          background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
        }
      }

      a,
      span {
        white-space: nowrap;
      }
    }

    &.scrollable-table {
      table-layout: fixed;
      width: 100%;

      th,
      td {
        width: 100px;
      }

      thead {
        tr {
          th {
            vertical-align: top;
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 0px;

            a,
            span {
              white-space: break-spaces;
            }
          }
        }
      }
    }
  }
}

.StepTemplateLinkPopover {
  background-color: var(--text-color-primary);
  z-index: $zindex-dropdown;
  max-width: 200px;

  .arrow {
    &::after {
      border-bottom-color: var(--text-color-primary) !important;
    }
  }
}
