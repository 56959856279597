.MobileNav {
  position: relative;
  z-index: $zindex-modal;
  width: 100%;
  background-color: var(--nav-color);

  .left-container,
  .right-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .left-container {
    @extend .pl-4;
    left: 0;
  }

  .right-container {
    @extend .pr-4;
    right: 0;

    .CloseIcon {
      position: unset;
      cursor: pointer;
      stroke: var(--nav-text-color) !important;

      &:hover {
        stroke: var(--nav-text-active-color) !important;
      }
    }
  }

  .Navbar-companyImage {
    img {
      height: 24px;
      width: auto;
    }
  }

  .Navbar-burger {
    cursor: pointer;

    svg {
      display: inline-block;
      transform: scale(1.2);
      fill: var(--nav-text-color) !important;
    }

    &:hover {
      svg {
        fill: var(--nav-text-active-color) !important;
      }
    }

    .open {
      fill: var(--nav-text-active-color) !important;
    }
  }

  .Navbar-profileImage {
    height: 30px !important;
    width: 30px !important;
  }

  .Navbar-navItemContainer {
    @extend .ml-3;

    height: 60px;
    width: 30px;
  }
}
