.EditableWidget {
  position: relative;
  width: 100%;
  height: 100%;

  .ComponentContainer {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &:hover {
    .HoverTopMenu {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.EditableGridLayout {
  .react-grid-item {
    border: 1px solid transparent;
    cursor: grab;

    &:hover {
      z-index: $zindex-one;
    }

    &.react-draggable-dragging {
      cursor: grabbing;
    }

    &.react-grid-placeholder {
      background-color: var(--dynamic-color-primary-color-lighter--highlight-color) !important;
    }

    &:hover {
      border: 1px solid var(--highlight-color);

      .react-resizable-handle {
        background-color: var(--highlight-color) !important;
      }
    }

    &.fullWidth {
      width: calc(100% + 3rem) !important;
      margin-left: -1.5rem;
    }
  }

  .react-resizable-handle {
    transform: none !important;
    background-image: none !important;
    width: 8px !important;
    height: 8px !important;
    background-color: transparent !important;

    &::after {
      border-right: 0 !important;
      border-bottom: 0 !important;
    }
  }

  .react-resizable-handle-s {
    bottom: -4px !important;
  }

  .react-resizable-handle-e {
    right: -4px !important;
  }

  .react-resizable-handle-n {
    top: -4px !important;
  }

  .react-resizable-handle-w {
    left: -4px !important;
  }

  .react-resizable-handle-se {
    bottom: -4px !important;
    right: -4px !important;
  }

  .react-resizable-handle-sw {
    bottom: -4px !important;
    left: -4px !important;
  }

  .react-resizable-handle-ne {
    top: -4px !important;
    right: -4px !important;
  }

  .react-resizable-handle-nw {
    top: -4px !important;
    left: -4px !important;
  }
}

