.AiSources {
  list-style: none;
  padding: 0;
  margin: 0;

  a {
    padding: 0.4rem 1rem;
    width: fit-content;
    border-radius: 999px; // this makes the border being rounded regardless of the button size

    &:hover {
      border-color: var(--highlight-color);
    }
  }

  .ExternalLinkIcon {
    svg {
      height: 12px;
      width: 12px;
      fill: currentColor ;
      padding-bottom: 1px;
    }
  }
}
