.EmployeeSearch {
  .ClearyReactSelect__multi-value {
    margin: 0 2px;
  }

  .ClearyReactSelect__option--is-focused {
    .EmployeeMiniCard {
      background-color: initial !important;
    }
  }

  .ClearyReactSelect__option--is-selected {
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color) !important;

    .EmployeeMiniCard {
      background-color: initial !important;
    }
  }

  .AlertOutlineIcon {
    svg {
      width: 22px;
      height: 22px;
      fill: var(--admin-banner-orange-background-color);
    }
  }
}
