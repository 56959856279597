.IntegrationCard {
  box-shadow: none !important;
  border: var(--border-color) solid 1px !important;
  background-color: rgba(236, 235, 239, 0.28);

  &.isInstalled {
    background-color: var(--white-pure);
  }

  .IntegrationLogo {
    svg, img {
      height: 21px;
      width: 21px;
    }
  }
}
