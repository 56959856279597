.EditableLinks {
  input,
  button {
    height: 30px;
  }

  .current-link {
    color: var(--dynamic-color-group-current-link--link-color); //color(var(--link-color) shade(35%));
  }

  .Links {
    max-width: 250px;
    flex: 3;

    li {
      max-height: 30px;
      max-width: 200px;
    }
  }

  .EditIcon {
    flex: 1;
    position: relative;
    top: -1px;
  }

  .AddLink {
    top: -2px;
  }

  .remove-member.rounded-circle {
    position: relative !important;
    height: 15px !important;
    width: 15px !important;
    min-width: 15px !important;
    left: unset !important;
    top: unset !important;
    right: unset !important;
    transform: none !important;
  }
}
