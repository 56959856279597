.EventTemplateSelector {
  .EventTemplateList .card {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 2px 1px var(--highlight-color) !important;
    }

    .card-body {
      padding: 0;
    }
  }

  .EventTemplateList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;

    @include small-size {
      grid-template-columns: 1fr;
      grid-auto-rows: 1fr;

      .CreateFromScratch {
        order: -1;
      }
    }
  }

  .TemplateItem {
    img {
      border-top-left-radius: var(--square-border-radius);
      border-top-right-radius: var(--square-border-radius);
      height: auto;
      width: 100%;
      object-fit: cover;
    }
  }

  .CreateFromScratch {
    border: dashed 1px var(--kebab-menu-icon-color);
    border-radius: var(--square-border-radius);

    &:hover {
      box-shadow: 0 0 2px 1px var(--highlight-color) !important;
      border: none;
    }
  }

  .PlusIconCircleWrapper {
    width: 60px;
    height: 60px;
    border: solid 2px var(--kebab-menu-icon-color);
    border-radius: 100%;
  }

  .PlusIcon {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;

    svg {
      fill: var(--kebab-menu-icon-color);
    }
  }

  .ProgressiveImage {
    .Placeholder {
      min-height: 121px;
    }

    img {
      animation: none !important;
    }
  }
}
