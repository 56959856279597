.ReactionCountPopover {
  background-color: var(--black);
  max-width: 230px;

  .arrow {
    &::after {
      border-top-color: var(--black) !important;
    }
  }
}
