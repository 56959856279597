input.UserEditorPage-DatePicker {
  &::placeholder {
    color: var(--text-color-primary);
  }
}

.UserEditor-miniLoadingSpinner::before {
  border-top: 3px solid white !important;
}

.UserEditor_Form {
  .ProfilePhotos-primary {
    .ProfilePhotos-destroy {
      right: 1rem !important;
    }
  }

  .react-datepicker {
    min-width: 100%;
  }
}
