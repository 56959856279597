nav.OrgChartToolbar {
  #FilterButtonAndModal {
    border: 1px solid var(--border-color);
    border-radius: var(--square-border-radius);
    cursor: pointer;
    margin: 0 1rem;

    @include small-size {
      margin: 0 1rem 0 0.5rem;
    }

    &:hover {
      border-color: var(--highlight-color);
    }

    span.ChevronIcon {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.OrgChartFilterPopover {
  width: 350px;

  .content {
    display: flex;
    flex-direction: column;

    .FormCheck {
      input {
        height: 18px;
        width: 18px;
        margin-right: 8px !important;
      }
    }
  }
}
