.ToggleNotificationsButton {
  .BellActive, .BellInactive {
    @extend .mr-2;

    svg {
      width: 18px;
      height: 18px;
      fill: var(--btn-primary-color);
    }
  }
}
