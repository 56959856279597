.AiPlatform {
  padding-bottom: 24px;

  .card-title {
    @extend .text-xl, .d-flex, .justify-content-between, .align-items-center;
    font-weight: 600;
    border-bottom: 1px solid var(--border-color);
    padding-top: 12px;
    padding-bottom: 18px;

    .h5 {
      @extend .text-xl;
      font-weight: 600;
      margin: 0;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
