div.CreateWorkspaceButtonAndModal {
  .CreateWorkspaceButton {
    margin-right: 18px;
  }

  .IconButtonWithPopover {
    width: 20px;
    height: 20px;
    border-radius: var(--square-border-radius);
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &:hover {
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
    }

    .PlusIcon {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.CreatePageWorkspaceModal {
  .modal-body {
    max-width: 650px;

    .EmojiOrPlaceholder {
      width: 38px;
      line-height: 38px;
      font-size: 36px;

      svg {
        width: 38px;
        height: 38px;
      }
    }
  }
}

.CreateWorkspacePopover {
  background-color: var(--btn-primary-color);

  .arrow::after {
    border-bottom-color: var(--btn-primary-color);
  }
}
