@import '_variables.scss';

@mixin header($fontSize) {
  color: var(--text-color-primary);
  font-size: var(#{$fontSize});
  line-height: $header-line-height;
  text-transform: none;
  font-weight: 600;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin label() {
  color: var(--text-color-secondary);
  font-weight: 600;
  font-size: $label-font-size;
  text-transform: uppercase;
}

@mixin subtext() {
  color: var(--text-color-secondary);
}

@mixin linkText($color: --link-color, $font-weight: inherit) {
  color: var(#{$color});
  font-weight: var(#{$font-weight});

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: var(--dynamic-color-link-text#{$color});
  }
}

@mixin linkColors($color: --link-color) {
  a,
  a:visited,
  a:active {
    @include linkText($color, --font-weight-inherit);
  }
}

@mixin button() {
  outline: none !important;
  border-radius: var(--button-border-radius) !important;
  min-width: 130px;
  height: 2.3rem;
  padding: 6px 16px;
  cursor: pointer;
  border: none;

  font-weight: 500;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  &:disabled {
    cursor: not-allowed;
  }

  &.dropdown-toggle {
    min-width: auto;
    gap: 0.75em;

    &:after {
      margin: 0;
    }
  }

  &:hover {
    font-weight: 500;
  }
}

@mixin profileImage() {
  border: 1px solid var(--border-color);
  border-radius: var(--profile-image-border-radius);
}

@mixin buttonSmall() {
  height: 2.143rem;
  min-width: 100px;
}

@mixin buttonPrimary($btn-color, $color: --white-pure) {
  @include button();

  background-color: var(#{$btn-color});
  color: var(#{$color}) !important;

  &:hover {
    background-color: var(--dynamic-color-button-primary-hover#{$btn-color});
  }

  &:active,
  &:focus {
    background-color: var(--dynamic-color-button-primary-active#{$btn-color}) !important;
    border-radius: var(--button-border-radius) !important;
  }

  &:disabled {
    background-color: var(--dynamic-color-button-primary-disabled#{$btn-color});
  }

  .show &.dropdown-toggle {
    background-color: var(--dynamic-color-button-primary-show-toggle#{$btn-color});
  }
}

@mixin buttonSecondary($btn-color) {
  @include button();

  border: 1px solid var(#{$btn-color});
  color: var(#{$btn-color});
  background-color: white;

  &:hover {
    border: 1px solid var(--dynamic-color-button-secondary-hover-border#{$btn-color}); // color(var(#{$btn-color}) shade(15%));
    color: var(--dynamic-color-button-secondary-hover-color#{$btn-color}); //color(var(#{$btn-color}) shade(10%));
    background-color: white !important;
  }

  &:active,
  &:focus {
    border: 1px solid var(--dynamic-color-button-secondary-active-border#{$btn-color}) !important; //color(var(#{$btn-color}) tint(15%));
    color: var(--dynamic-color-button-secondary-active-color#{$btn-color}) !important; //color(var(#{$btn-color}) tint(15%));
    background-color: white !important;
  }

  &:disabled {
    border: 1px solid var(--dynamic-color-button-secondary-disabled-border#{$btn-color}); // color(var(#{$btn-color}) tint(30%));
    color: var(--dynamic-color-button-secondary-disabled-color#{$btn-color}); // color(var(#{$btn-color}) tint(30%));
    background-color: white !important;
  }
}

@mixin buttonMuted($btn-color) {
  @include button();

  color: var(--text-color-primary) !important;
  background-color: var(#{$btn-color});
  border: none !important;

  &:hover {
    color: var(--text-color-primary);
    background-color: var(--dynamic-color-button-muted-hover#{$btn-color}) !important;
  }

  &:active,
  &:focus {
    color: var(--text-color-primary);
    background-color: var(--dynamic-color-button-muted-active#{$btn-color}) !important;
  }

  &:disabled {
    color: var(--text-color-primary);
    background-color: var(--dynamic-color-button-muted-disabled#{$btn-color}) !important;
  }
}

//breakpoint mixins
@mixin xlarge-size {
  @media only screen and (min-width: $bootstrap-lg) and (max-width: $page-grid-width-large) {
    @content;
  }
}

@mixin large-size {
  @media only screen and (min-width: $bootstrap-md) and (max-width: $bootstrap-lg) {
    @content;
  }
}

@mixin up-to-large-size {
  @media only screen and (max-width: $bootstrap-lg) {
    @content;
  }
}

@mixin medium-size {
  @media only screen and (min-width: $bootstrap-sm) and (max-width: $bootstrap-md) {
    @content;
  }
}

@mixin up-to-medium-size {
  @media only screen and (max-width: $bootstrap-md) {
    @content;
  }
}

@mixin small-size {
  @media only screen and (max-width: $bootstrap-sm) {
    @content;
  }
}

@mixin max-media-width($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin min-media-width($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

// Use when css should only be applied to touchscreen devices
@mixin touchscreen {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

// Use when css should only be applied to devices that support hover states
@mixin hover-device {
  @media (hover: hover) {
    @content;
  }
}

@mixin default-page-grid {
  // default grid always responsive because the width changes based on expanded/collapsed left nav
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 30px;
  justify-content: center;
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 18px;


  &.grid-extended {
    max-width: 100%;
  }

  @include xlarge-size {
    // from 992px to 1290px
    // grid here is 12 * 60px column with 11 * 24px gap = 984px wide
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 60px);
  }

  @include large-size {
    // from 768px to 992px
    // grid here is 12 * 48px column with 11 * 16px gap = 752px wide
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 48px);
  }

  @include medium-size {
    // from 576px to 768px
    // grid here is 12 fluid columns with 11 * 8px gap = fluid width
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 8px;
    padding: 0 16px;
  }

  @include small-size {
    // max-width to 576px
    // grid here is 12 fluid columns with 11 * 8px gap = fluid width
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 8px;
    padding: 0 16px;
  }
}

@mixin celebration-header-bg {
  @media only screen and (min-width: $bootstrap-lg) {
    &:not(&--legacy) {
      .illustration {
        transform: scale(1.5) translate(-45px, -33px);
      }

      &.birthday {
        .illustration {
          transform: scale(1.5) translate(10px, -28px);
        }
      }

      &.new_employee_welcome {
        .illustration {
          transform: scale(1.5) translate(-45px, -35px);
        }
      }
    }
  }

  @include up-to-large-size {
    padding-top: 30px;
    height: auto !important;
    flex-direction: column !important;
    align-items: center !important;

    .balloon {
      position: absolute;

      &.balloon-1 {
        left: 10%;

        @include small-size {
          left: 5px;
        }
      }

      &.balloon-2 {
        left: 20%;

        @include small-size {
          left: 50px;
        }
      }

      &.balloon-3 {
        right: 20%;

        @include small-size {
          right: 50px;
        }
      }

      &.balloon-4 {
        right: 10%;

        @include small-size {
          right: 5px;
        }
      }
    }
  }

  &.birthday {
    background-color: var(--birthday-card-background-color);
  }

  &.work_anniversary {
    background-color: var(--work-anniversary-background-color);

    .illustration {
      svg {
        width: 324px;
        height: 205px;
      }
    }
  }

  &.new_employee_welcome {
    background-color: var(--new-employee-welcome-color);
  }

  &.confetti-background:before {
    opacity: 0.35;
  }
}
