.default-dropdown {
  .DropdownMenu-content {
    background-color: var(--white-pure);
    width: 200px;

    section {
      border-bottom: 1px solid var(--border-color);
    }

    .Card-dropdownMenuItem {
      padding: 0.5rem !important;
      width: 100% !important;

      > a {
        > div {
          @extend .text-small;
        }
      }

      > div {
        @extend .text-small;
      }

      .isvg {
        display: flex;
        height: 20px;
        width: 20px;
      }

      svg {
        fill: var(--text-color-primary);
        height: 20px;
        width: 20px;

        path {
          fill: var(--text-color-primary);
        }
      }

      &.Card-dropdownMenuItem--danger {
        div {
          color: var(--danger-color);
        }

        svg {
          fill: var(--danger-color);

          path {
            fill: var(--danger-color);
          }
        }
      }
    }
  }
}
