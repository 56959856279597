.TableOfContents {
  .header-ml-0 {
    margin-left: 0rem;
  }

  .header-ml-1 {
    margin-left: 1.5rem;
  }

  .header-ml-2 {
    margin-left: 3rem;
  }

  .header-ml-3 {
    margin-left: 4.5rem;
  }

  .header-ml-4 {
    margin-left: 6rem;
  }

  .header-ml-5 {
    margin-left: 7.5rem;
  }

  .CloseIcon {
    position: unset;
    right: 1em;
    cursor: pointer;
    line-height: 0;

    stroke: var(--dynamic-color-close-icon--border-color);
    fill: var(--dynamic-color-close-icon--border-color);

    &:hover {
      stroke: var(--gray);
      fill: var(--gray);
    }
  }
}
