nav.LeftNav {
  width: var(--left-nav-width); // 233px or 1/6th the window, whichever is greater
  left: calc(-1 * var(--left-nav-width));
  transition: left 0.5s ease-in-out;
  overflow: auto;
  padding-bottom: 1.5rem;
  background: var(--left-navbar-background-color);

  &.isDesktopNavOpen {
    left: 0;
  }

  *:focus {
    // remove default blue outline when any child has focus
    outline: none;
  }

  @include small-size {
    display: none !important;

    &.isMobileNavOpen {
      display: flex !important;
      width: 100vw;
    }
  }
}
