.SuggestedMessageEntry {
  position: relative;
  margin-top: -4px;
  // z-index: -1;
  background-color: var(--white-pure);
  text-align: left;

  &-wrapper {
    padding: 24px;

    &__attachment_title {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: var(--text-color-secondary);
        }
      }
    }

    &__attachment_content {
      .eye-icon {
        svg {
          width: 16px;
          height: 16px;

          path {
            fill: var(--text-color-secondary);
          }
        }
      }

      .edit-icon {
        svg {
          width: 12px;
          height: 12px;

          path {
            fill: var(--text-color-secondary);
          }
        }
      }
    }
  }

  sup, sub {
    display: none;
  }
}
