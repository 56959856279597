.CelebrationBanner {
  animation: grow-from-center 1s;
  position: relative;
  grid-column: 1 / span 12;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @include up-to-medium-size {
    flex-direction: column;
  }

  a, h4 {
    z-index: $zindex-one; // to be on top of confetti background
  }

  &.birthday {
    background-color: var(--birthday-card-background-color);
  }

  &.work_anniversary {
    background-color: var(--work-anniversary-background-color);
  }

  &.new_employee_welcome {
    background-color: var(--new-employee-welcome-color);
  }

  &.confetti-background:before {
    opacity: 1;
  }

  .small-balloon {
    position: absolute;

    svg {
      height: 50px;
    }

    &.balloon-1 {
      animation: bounce 2s;
      animation-delay: 0.05s;
      left: 5%;
      bottom: 5%;
    }

    &.balloon-2 {
      animation: bounce 2s;
      left: 10%;
      top: 10%;
    }

    &.balloon-3 {
      animation: mirror-bounce 2s;
      right: 10%;
      top: 10%;
    }

    &.balloon-4 {
      animation: mirror-bounce 2s;
      animation-delay: 0.05s;
      right: 5%;
      bottom: 5;
    }
  }

  .large-balloon{
    svg {
      height: 200px;
      width: 114px;
    }

    &.balloon-5 {
      animation: float-away 2s;
      animation-timing-function: linear;
      animation-delay: 1s;
      position: fixed;
      left: 50px;
      top: -200px;
    }

    &.balloon-6 {
      animation: float-away 2s;
      animation-timing-function: linear;
      animation-delay: 1.5s;
      position: fixed;
      right: 50px;
      top: -200px;
    }

    &.balloon-7 {
      animation: float-away 2s;
      animation-timing-function: linear;
      animation-delay: 2s;
      position: fixed;
      left: 100px;
      top: -200px;
    }

    &.balloon-8 {
      animation: float-away 2s;
      animation-timing-function: linear;
      animation-delay: 2.5s;
      position: fixed;
      right: 150px;
      top: -200px;
    }

    &.balloon-9 {
      animation: float-away 2s;
      animation-timing-function: linear;
      animation-delay: 3s;
      position: fixed;
      left: 150px;
      top: -200px;
    }

    &.balloon-10 {
      animation: float-away 2s;
      animation-timing-function: linear;
      animation-delay: 3.5s;
      position: fixed;
      right: 100px;
      top: -200px;
    }

    &.balloon-11 {
      animation: float-away 2s;
      animation-timing-function: linear;
      animation-delay: 2.25s;
      position: fixed;
      right: 40%;
      top: -200px;
    }

    &.balloon-12 {
      animation: float-away 2s;
      animation-timing-function: linear;
      animation-delay: 2.75s;
      position: fixed;
      left: 40%;
      top: -200px;
    }
  }
}
