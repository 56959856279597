.ApiTokensSection {
  table {
    thead th, tbody td {
      border: 0;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  a {
    display: flex;
    align-items: center;

    svg {
      margin-left: 2px;
      fill: var(--link-color);
    }
  }
}
