.TaskForm {
  .content-rich-view {
    cursor: not-allowed;
    border: 1px solid var(--border-color);
    background-color: var(--lightest-gray);
    border-radius: 4px;
    outline: none;
    padding: 1em;

    * {
      opacity: 0.8;
    }
  }
}
