.WorkflowEditPage__Actions__ActionNode {
  background-color: var(--white-pure);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 10px;
  width: 280px;
  cursor: pointer;

  &--first {
    border-top-left-radius: 0;
  }

  &--selected, &:hover {
    border: 1px solid var(--link-color);
  }

  &__action-name {
    padding: 2px 9px;

    svg, isvg {
      display: block;
      width: 16px;
      height: 16px;
    }
  }

  &__delete-icon {
    cursor: pointer;
    border-radius: 50%;
    padding: 0px 3px;
    svg path {
      fill: var(--text-color-secondary);
    }

    &:hover {
      background-color: var(--tile-background-color);
    }
  }
}
