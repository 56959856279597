.PageCoverImage {
  --cover-image-height-large: 275px;
  --cover-image-height-small: 175px;
  padding: 0 !important;

  .AddCover {
    @extend .mx-4, .mx-md-5;
    margin-top: 1rem;
    margin-bottom: -0.50rem !important;
    padding-left: 0.7rem !important;
    padding-right: 0.8rem !important;
    border: none;

    &:hover {
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color) !important;

      span {
        color: var(--dynamic-color-primary-color-darker--highlight-color);

        svg {
          g {
            fill: var(--dynamic-color-primary-color-darker--highlight-color);
            color: var(--dynamic-color-primary-color-darker--highlight-color);
          }
        }
      }

    }

    span {
      color: var(--gray);

      svg {
        g {
          fill: var(--gray);
        }
      }
    }

  }
  // dynamic-color-link-text--link-color
  .Placeholder {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  img {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
