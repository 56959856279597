.AllStepsTable {
  table-layout: auto;
  width: 100%;
  z-index: $zindex-zero;

  tbody {
    tr {
      td:first-child{
        width: 17%;
      }

      td:nth-child(2) {
        width: 23%;
      }

      td:nth-child(5) {
        width: 15%;
      }

      td:last-child {
        width: 5%;
      }
    }
  }
}
