.LayoutCollectionSidebar {
  position: fixed;
  z-index: $zindex-toolbar;
  top: var(--nav-height);
  left: 0;
  width: var(--left-nav-width);
  height: calc(100vh - var(--nav-height));
  overflow-y: auto;
  background-color: var(--left-navbar-background-color);

  .LayoutCollectionSidebarCloseIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-primary);
    }
  }
}
