.AliasSection {
  .AliasSection_shrinkButton {
    min-width: 50px !important;
    height: 2em !important;
    font-size: 1em !important;
  }

  .AliasSection_newAlias {
    margin-left: -15px;
  }
}
