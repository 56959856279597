.SearchResults {
  margin-top: 16px;

  .ImgContainer {
    width: 50px;
    flex-shrink: 0;

    svg {
      fill: var(--text-color-secondary);
      height: 34px;
      width: 34px;
    }

    img, .isvg {
      @extend .bordered-thumbnail;
      width: 40px !important;
      height: 40px !important;
    }

    .isvg {
      padding-left: 1px;
    }

    svg {
      width: 36px !important;
      height: 36px !important;
      padding: 6px !important;
    }
  }

  .SearchResultTemplate {
    cursor: pointer;
  }

  .EmojiOrPlaceholder {
    width: 34px;
    height: 34px;

    width: 34px;
    line-height: 34px;
    font-size: 34px;

    svg {
      width: 34px;
      height: 34px;
    }
  }
}
