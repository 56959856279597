.GeneralSettings {
  .bordered {
    border-radius: 3px;
  }
  .EmployeeMiniCard {
    padding: 1rem !important;
  }
}

.DeleteEventModal,
.ArchiveEventModal {
  width: 500px !important;
  padding-bottom: 3rem;

  @include small-size {
    width: inherit !important;
    margin: 0 auto !important;
  }

  button.close {
    font-size: 2rem;
    font-weight: normal;
  }
}
