.OtherBuddiesList {
  @include default-page-grid;
  grid-row-gap: 30px;

  .AsyncSearchInputGroup {
    grid-column: span 3;

    .AsyncSearchInput {
      width: 100%;
    }

    @include medium-size {
      grid-column: span 6;
    }

    @include small-size {
      grid-column: span 12;
    }
  }

  .CitySelect {
    grid-column: 10 / -1;

    @include medium-size {
      grid-column: span 6;
    }

    @include small-size {
      grid-column: span 12;
    }

    .ClearyReactSelect {
      width: 100%;
    }
  }

  .CirclesLoadingIndicator {
    grid-column: span 12;
  }

  .UserCard {
    grid-column: span 3;

    @include medium-size {
      grid-column: span 6;
    }

    @include small-size {
      grid-column: span 12;
    }

    .EmployeeThumbnailPhoto {
      width: 40px !important;
      height: 40px !important;
      flex-basis: 40px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .SelectBuddyButton {
      border: none;
      margin-left: 39px;

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  .PaginationContainer {
    grid-column: span 12;
  }
}
