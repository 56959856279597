div.QuestionStatsCard {
  .AdditionalFeedbackSection,
  .TextQuestionStats {
    table {
      box-shadow: none;

      td:first-child,
      th:first-child {
        padding-left: 0;
      }

      td:last-child,
      th:last-child {
        padding-right: 0;
      }
    }
  }

  .AdditionalFeedbackSection {
    svg {
      width: 28px;
      height: 28px;
      fill: var(--highlight-color);
    }
  }

  transition: border-color 0.2s ease;

  &:hover {
    border-color: var(--link-color) !important;
  }
}
