.CreateJourneyBlueprintModal {
  .modal-content {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }

  div.toggle {
    min-height: 5rem !important;
    align-items: center;
  }
}
