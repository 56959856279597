.ProfileWidget {
  container-type: size;

  .ProfileWidgetCardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .CompleteYourProfile {
      margin-top: 1.5rem !important;
      margin-bottom: 0 !important;

      .AlertOutlineIcon {
        margin-right: 3px;

        svg {
          width: 18px;
          height: 18px;
          fill: var(--white-pure);
        }
      }

      button.btn-primary {
        background-color: var( --admin-banner-orange-background-color);
        border-radius: var(--button-border-radius) !important;
        padding: 0 10px;

        &:focus,
        &:hover,
        &:active {
          box-shadow: none;
          background: var(--admin-banner-orange-background-color) !important;
          opacity: 0.9;
        }

        svg{
          margin-bottom: 1px;
          margin-left: 3px;
        }
      }
    }

    .DaysUntilStart {
      @extend .text-small;
    }
  }

  @container (max-height: 100px) {
    .ProfileWidgetCardBody {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .CompleteYourProfile,
      .DaysUntilStart {
        margin: 0 !important;
      }
    }

    @container (max-width: 450px) {
      .CompleteYourProfile {
        button {
          min-width: 32px;
          width: 32px;
          min-height: 32px;
          height: 32px;
          padding: 0;
        }

        .ButtonText {
          display: none;
        }
      }
    }
  }
}
