.CalendarWidget {
  .DayBlock {
    width: 47px;
    height: 44px;
    border-radius: 8px;

    h4 {
      font-size: 16px;
      line-height: 20px;
    }

    div {
      font-size: 13px;
      line-height: 1;
    }
  }

  .CalendarEvent {
    min-height: 70px;
    border-radius: 8px;

    a.calendar-link {
      span {
        word-break: break-word;
      }
    }
  }
}
