div.StepTemplatesTable {
  width: 100%;

  .l-vector {
    width: 8px;
    height: 16px;
    margin-top: -11px;
    border-left: 1.5px solid var(--light-gray);
    border-bottom: 1.5px solid var(--light-gray);
  }

  .CorrespondentIcon {
    svg {
      height: 30px;
      width: 30px;
    }
  }

  .AlertOutlineIcon {
    svg {
      width: 32px;
      height: 32px;
      fill: var(--admin-banner-orange-background-color);
    }
  }

  .white-bg-table {
    table-layout: auto;
    width: 100%;

    tbody {
      tr {
        td:first-child{
          width: 30%;
        }

        td:last-child{
          width: 5%;
        }
      }
    }
  }
}
