.PublishEventModal {
  .modal-content {
    background-color: var(--btn-primary-color);
  }

  .modal-header {
    .close {
      color: $white;
      opacity: 1;
      font-size: 2rem;
      font-weight: 300;
    }
  }

  .modal-body {
    padding: 0;
  }

  header {
    h2,
    span {
      color: $white;
    }
  }

  main {
    background-color: $white;
  }
}
