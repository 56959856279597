.RemoveGoLinkModal {
  margin-top: var(--nav-height);

  .modal-header {
    margin: 0.5rem 0.5rem 0;
    padding-bottom: 0;
  }

  .modal-body {
    margin: 0 0.5rem 0.5rem;
  }
}
