.StepTepmlatesAdvancedSettings {
  .AdvancedSettingsButton {
    cursor: pointer;
    color: var(--link-color);

    &:hover {
      color: var(--dynamic-color-link-text--link-color);

      svg {
        fill: var(--dynamic-color-link-text--link-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--link-color);
    }
  }
}
