.NewHireCarousel {
  .CarouselButton {
    width: 40px;
    height: 40px;
    background-color: var(--white-pure);
    box-shadow: var(--card-box-shadow);
    border-radius: 100%;
    z-index: $zindex-one;
  }

  .slick-slider {
    .slick-prev,
    .slick-next {
      path,
      g {
        stroke: var(--highlight-color) !important;
        fill: var(--highlight-color) !important;
      }

      &:hover {
        path,
        g {
          stroke: var(--link-color) !important;
          fill: var(--link-color) !important;
        }
      }
    }

    .slick-prev {
      -webkit-transform: translate(0, -1em) rotate(180deg) !important;
      -ms-transform: translate(0, -1em) rotate(180deg) !important;
      transform: translate(0, -1em) rotate(180deg) !important;
    }

    .slick-next {
      -webkit-transform: translate(0, -1em) rotate(0deg) !important;
      -ms-transform: translate(0, -1em) rotate(0deg) !important;
      transform: translate(0, -1em) rotate(0deg) !important;
    }
  }
}
