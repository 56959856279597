div.JourneyTable {
  z-index: $zindex-zero;

  table {
    table-layout: auto;
    width: 100%;

    tbody {
      tr {
        td:first-child{
          width: 20%;
        }

        td:nth-child(2) {
          width: 20%;
        }

        td:nth-child(3) {
          width: 15%;
        }

        td:nth-child(4) {
          width: 12%;
        }

        td:last-child {
          width: 13%;
        }
      }
    }
  }
}
