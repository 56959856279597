.LayoutCollectionTemplates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  .LayoutCollectionTemplate {
    border: 1px solid transparent !important;
    height: 314px;
    overflow: hidden;

    &:hover {
      border: 1px solid var(--highlight-color) !important;
    }

    .ProgressiveImage {
      img {
        height: auto !important;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
