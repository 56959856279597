.SortBy {
  display: flex;
  z-index: $zindex-dropdown;

  label {
    align-items: center;
    color: var(--text-color-secondary);
    margin: 0 7px 0 0;
    padding: 7px;
  }
}
