div.IntroductionDetail {
  background-color: $white-pure;

  .CorrespondentIcon {
    svg {
      height: 22px;
      width: 22px;
    }
  }

  .EmployeeThumbnailPhoto {
    width: 22px !important;
    height: 22px !important;
  }

  .IntroductionInvitationSelector {
    .EmployeeSearch {
      max-width: 400px;
    }

    .InfoIcon {
      svg {
        width: 20px;
        height: 20px;
        fill: var(--danger-color);
      }
    }

    .CheckIcon {
      width: 14px;
      height: 14px;

      path {
        fill: var(--success-color);
      }
    }
  }
}
