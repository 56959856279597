.BadgeConfigurationSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;

  .SettingsInfo, .Notifications, .BadgeOwner, .DeleteBadge {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;

    .EmployeeSearch {
      width: 50%;

      @include up-to-large-size {
        width: 100%;
      }
    }

    button {
      width: 140px;
    }
  }

  .BadgeType {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 100%;

    .form-group {
      width: 50%;
    }

    @include up-to-large-size {
      flex-direction: column;

      .form-group {
        width: 100%;
      }
    }
  }

  input[type=checkbox] {
    accent-color: var(--btn-primary-color);
  }

  p {
    color: var(--text-color-secondary);
    font-weight: 400;
  }
}
