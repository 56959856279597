.AiAnswersWizardModal {
  &__container {
    width: 674px;
  }

  &__illustration {
    width: 100%;
    height: 283px;
    background-color: var(--tile-background-color);
    overflow: hidden;

    img {
      max-width: 100%;
    }

    &--last-step {
      height: 226px;
    }
  }

  &__indicators {
    &__indicator {
      width: 30px;
      height: 30px;
      border: 2px solid $light-mid-gray;
      border-radius: 50%;

      > div {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: transparent;
      }

      &--active {
        border-color: $cleary-purple;

        > div {
          background-color: $cleary-purple;
        }
      }

      &--done {
        background-color: $cleary-purple;
        border-color: $cleary-purple;

        > div {
          background-color: $cleary-purple;
          width: auto;
          height: auto;
        }
      }
    }

    &__separator {
      width: 125px;
      border-bottom: 2px solid $light-mid-gray;

      &--active {
        border-color: $cleary-purple;
      }
    }
  }
}
