.FavoriteButton {
  min-width: 20px;
  width: 20px;
  background-color: transparent;

  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  &:hover {
    background-color: transparent;
  }

  .NotFavorite {
    @extend .d-none;
  }
}
