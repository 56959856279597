.EmployeeOrCorrespondentLink {
  .CorrespondentIcon {
    svg {
      height: 30px;
      width: 30px;
    }
  }

  .EmployeeThumbnailPhoto {
    width: 30px !important;
    height: 30px !important;
  }
}
