.UpdateInactiveCorrespondentsModal {
  .scrollable-container {
    max-height: 60vh;
    overflow-y: auto;

    table {
      th {
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: var(--text-color-primary);
      }

      td {
        vertical-align: top;
      }
    }

    .CorrespondentSelect {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

    }
  }
}
