.PageExpirationWarningModal {
  .modal-header {
    background-color: var(--page-expiration-warning-modal-info-header-bg-color);

    .close {
      color: var(--white-pure);
      opacity: 1;
      font-size: 2rem;
      font-weight: 300;
    }
  }

  .info-header {
    background-color: var(--page-expiration-warning-modal-info-header-bg-color);

    .InfoIcon {
      svg {
        fill: var(--white-pure);
        width: 60px;
        height: 60px;
      }
    }
  }

  .modal-body {
    padding-left: 0;
    padding-right: 0;

    .form-check-input {
      margin-top: 0;
    }

    .form-check-label {
      font-weight: 400;
    }
  }
}
