.RoundedAvatars {
  min-height: 25px;

  .AnonymousUserIcon {
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.RoundedSlackAvatars {
  background: #ECEBEF;
  border-radius: var(--square-border-radius);
  padding: 5px;

  .persons-icon-wrapper {
    border-radius: 50%;
    background: #aeaabe;
    text-align: center;

    svg {
      fill: white;
      width: 14px;
    }
  }

  .plusUsersContainer {
    background: none;
    color: #7E7B96;
    font-size: 0.9rem;
    width: initial !important;
    padding: 0 5px;
  }
}

.plusUsersContainer {
  color: var(--white-pure);
  width: 25px;
  height: 25px;
  background: var(--highlight-color);
  margin-left: 2px;
  text-align: center;
  line-height: 1.875rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.thumbnailUserImage {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 2px;
  border: none;
}

.ScrollablePopoverlist {
  max-height: 250px;
  max-width: 250px;
  overflow-y: scroll;
}
