.MyTasksTable {
  table-layout: auto;
  width: 100%;

  tbody {
    tr {
      td:first-child {
        width: 40%;
      }

      td:nth-child(2) {
        width: 20%;
      }

      td:nth-child(3) {
        width: 20%;
      }

      td:last-child {
        width: 20%;
      }

      @include small-size {
        td:first-child {
          min-width: 230px;
        }

        td:nth-child(2) {
          min-width: 200px;
        }

        td:nth-child(3) {
          min-width: 200px;
        }

        td:last-child {
          min-width: 200px;
        }
      }
    }
  }
}
