.ShoutoutsDetail {
  .BackToShoutouts {
    svg {
      fill: var(--text-color-secondary);
    }
  }
}

.ShoutoutsListCard {
  &.AnimateNewlyAddedShoutoutBackground {
    .card {
      animation: new-card-item-fade 5000ms;
    }
  }

  .card-body {
    // to macth desings 30px padding
    padding: 2.14em;
  }
}

