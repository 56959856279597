div.JourneyCreationProgress {
  div.ProgressGuidanceCard {
    .RocketIcon {
      svg {
        height: 20px;
        width: 20px;
      }
    }

    .StepsIcon {
      svg {
        height: 14px;
        width: 14px;
      }
    }

    .StepsIcon,
    .RocketIcon {
      border: none;
      padding-left: 2px;
      padding-right: 2px;
      z-index: $zindex-base;
      position: relative;
    }
  }
}
