.CommentEntry {
  background-color: var(--ticket-comment-background-color);
  border-radius: var(--square-border-radius-large);

  &.isPrivate {
    background-color: var(--ticket-private-comment-background-color);
  }

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: var(--text-color-secondary);
    }

    &:hover {
      @extend .pointer;

      path {
        fill: var(--highlight-color);
      }
    }
  }

}

.PrivateLabelTooltip {
  .tooltip-inner {
    @extend .text-smallest;
  }
}
