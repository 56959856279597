.BlockColorPicker {
  z-index: $zindex-dropdown;

  &--top {
    bottom: 40px;

    .block-picker > div:nth-child(1) {
      top: 216px !important;
      transform: rotateZ(-180deg);
    }
  }

  .block-picker > div:nth-child(2) {
    border: 1px solid var(--border-color);
  }

  .block-picker > div:nth-child(3) > div:nth-child(1) > span > div {
    border: 1px solid var(--border-color);
  }

  .InputContainer {
    width: 100%;
    z-index: $zindex-dropdown;
    background-color: var(--white-pure);
    padding: 10px;
    text-align: center;
    margin-top: -1px;
    border-radius: 0px 0px 4px 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;

    svg {
      width: 16px;
      height: 16px;
    }

    .btn-success {
      background-color: var(--success-color) !important;
    }
  }
}
