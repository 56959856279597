.CreateStepTypeSelectModal{
  .modal-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  & .step-types {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-columns: auto;
    column-gap: 10px;
    row-gap: 10px;
  }

  & .step-type-container {
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    border: 1px solid var(--border-color);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.0459);
    border-radius: 5px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      fill: var(--text-color);
      vertical-align: top;
      margin-top: 0;
    }
  }

  .active {
    border-color: var(--highlight-color);
    color: var(--highlight-color);
    svg {
      fill: var(--highlight-color);

      path {
        fill: var(--highlight-color);
        stroke: var(--highlight-color);
      }
    }
  }

  @include up-to-medium-size {
    & .step-types {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
