.HoverTopMenu {
  padding: 0.25rem 0.5rem;
  position: absolute;
  background-color: var(--highlight-color);
  right: 2px;
  top: -30px;
  height: 30px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  border-radius: 8px 8px 0px 0px;
  z-index: $zindex-dropdown;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .isvg {
    height: 20px;
    width: 20px;
  }

  svg {
    height: 20px;
    width: 20px;

    g, path {
      fill: var(--white-pure) !important;
    }

    &:hover {
      g, path {
        fill: var(--light-gray) !important;
      }
    }
  }
}
