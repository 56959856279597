.CropperWrapper {
  .controls {
    background: var(--black-pure);

    .btn {
      min-width: auto;
    }

    p {
      color: var(--white-pure);
    }

    @include small-size {
      @extend .flex-column, .gap-3;
    }
  }
}
