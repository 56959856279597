.PeopleSettingsSection {
  .UserCelebrationsSettings-title, .EmployeeSearch {
    width: 550px;

    @include up-to-large-size {
      width: 410px;
    }

    @include up-to-medium-size {
      width: 100%
    }
  }

  .RoundedAvatars {
    width: 550px;
    background-color: rgba(14, 0, 60, 0.04);
    padding: 20px;
    border-radius: var(--square-border-radius);

    @include up-to-large-size {
      width: 410px;
    }

    @include up-to-medium-size {
      width: 100%
    }
  }

  table {
    max-width: 500px;
    margin: auto;
    display: block;
    overflow-x: scroll;

    @include up-to-medium-size {
      margin: 0;
    }
  }

  th, td {
    border: none;
  }

  input[type='checkbox'] {
    width:20px;
    height:20px;
    margin: auto;
  }
}
