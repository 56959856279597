.ApiListMultiSelect {
  .XIcon {
    svg {
      width: 20px;
      height: 20px;
      fill: var(--text-color-primary);

      &:hover {
        fill: var(--link-color);
      }
    }
  }
}
