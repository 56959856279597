.TopLevelFilter {
  .TopLevelFilterLink {
    &:not(.isActive) {
      span {
        font-weight: 500;
      }
    }

    &.isActive {
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);

      span {
        font-weight: 600;
        color: var(--link-color);
      }
    }

    a.noResults {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
