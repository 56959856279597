.AiTextGenerationComponent {
  outline: 1px solid var(--highlight-color);
  border-radius: 8px;

  &.isErrored {
    outline: 1px solid var(--danger-color);
  }

  .ErrorMessage {
    svg {
      fill: var(--danger-color);
    }
  }

  .dot-typing {
    display: inline-block;
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--highlight-color);
    color: var(--highlight-color);
    box-shadow: 9990px 0 0 0 var(--highlight-color), 9996px 0 0 0 var(--highlight-color), 10002px 0 0 0 var(--highlight-color);
    animation: dot-typing 1.5s infinite linear;
  }

  @keyframes dot-typing {
    0% {
      box-shadow: 9990px 0 0 0 var(--highlight-color), 9996px 0 0 0 var(--highlight-color), 10002px 0 0 0 var(--highlight-color);
    }
    16.667% {
      box-shadow: 9990px -5px 0 0 var(--highlight-color), 9996px 0 0 0 var(--highlight-color), 10002px 0 0 0 var(--highlight-color);
    }
    33.333% {
      box-shadow: 9990px 0 0 0 var(--highlight-color), 9996px 0 0 0 var(--highlight-color), 10002px 0 0 0 var(--highlight-color);
    }
    50% {
      box-shadow: 9990px 0 0 0 var(--highlight-color), 9996px -5px 0 0 var(--highlight-color), 10002px 0 0 0 var(--highlight-color);
    }
    66.667% {
      box-shadow: 9990px 0 0 0 var(--highlight-color), 9996px 0 0 0 var(--highlight-color), 10002px 0 0 0 var(--highlight-color);
    }
    83.333% {
      box-shadow: 9990px 0 0 0 var(--highlight-color), 9996px 0 0 0 var(--highlight-color), 10002px -5px 0 0 var(--highlight-color);
    }
    100% {
      box-shadow: 9990px 0 0 0 var(--highlight-color), 9996px 0 0 0 var(--highlight-color), 10002px 0 0 0 var(--highlight-color);
    }
  }
}
