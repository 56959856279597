.TicketEntries {
  .TiptapEditor {
    min-height: 250px;
    max-height: 500px;

    &:has(.ProseMirror:focus) {
      border-color: var(--highlight-color);
    }

    .TiptapEditorContentWrapper {
      overflow: auto;
    }

    &.isPublicComment {
      background-color: var(--ticket-comment-background-color);
    }

    &.isPrivateComment {
      background-color: var(--ticket-private-comment-background-color);
    }
  }

  .Entry + .Message, .Entry + .CommentEntry {
    margin-top: -20px;
  }

  > .d-flex > div:last-child .Entry__separator:last-child {
    display: none !important;
  }

  sub, sup {
    display: none;
  }

  .DropdownButton {
    button {
      @extend .text-small;
    }
  }

  .MessageEntry,
  .CommentEntry {
    @extend .bordered;

    border-radius: var(--square-border-radius-xlarge);
    padding: 24px;
  }

  .UnassignedUserIcon {
    background-color: var(--light-gray);
  }
}
