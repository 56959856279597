.AiAssistantButton {
  .icon-container {
    background: var(--highlight-color);
    width: 24px;
    height: 24px;
    border-radius: var(--square-border-radius);

    svg {
      height: 14px;
      width: 14px;

      path {
        fill: var(--white-pure);
      }
    }
  }
}
