div.AnswerContent {
  div.ScaleAnswerContent {
    .scale-steps {
      min-width: 300px;
      position: relative;
      z-index: $zindex-base;

      &:before {
        content: '';
        z-index: $zindex-zero;
        position: absolute;
        top: 8px;
        width: 100%;
        height: 4px;
        background-color: var(--border-color);
      }

      @include small-size {
        flex: auto;
        width: 100%;
        margin: 0;
      }

      &.full-width {
        flex: auto;
        margin: 0;
      }

      .step-bubble {
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        position: relative;
        background-color: var(--white-pure);
        border: 4px solid var(--border-color);

        &:hover {
          border: 4px solid var(--highlight-color);
          cursor: pointer;
        }

        &.isDisabled {
          cursor: not-allowed;
          opacity: 0.8;

          &:hover {
            border: 4px solid var(--border-color);
          }
        }

        &.selected {
          background-color: var(--highlight-color);
          border: 4px solid var(--highlight-color);
        }
      }
    }
  }

  div.MultipleOptionAnswerContent {
    input {
      height: 15px;
      width: 15px;
      min-width: 15px;
    }
  }

  div.TextAnswerContent {
    textarea {
      min-height: 150px;
    }
  }
}
