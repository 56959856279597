.TicketDetails {
  .DetailGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      margin-bottom: 0;
    }

    &:has(.AssigneeSelect) {
      // Special case because the assignee select sometimes has the 'assign to me' link
      align-items: flex-start;

      label {
        margin-top: 8px;
      }
    }

    .ClearyReactSelect__control {
      min-width: 180px;

      .ClearyReactSelect__value-container {
        text-align: right;
        padding-right: 0;

        .ClearyReactSelect__single-value {
          display: flex;
          justify-content: flex-end;
        }
      }

      .ClearyReactSelect__indicator-separator {
        display: none;
      }
    }

    .ClearyReactSelect--is-disabled {
      background-color: transparent;

      .ClearyReactSelect__indicators {
        display: none;
      }
    }

    .react-datepicker-wrapper {
      width: 200px;
    }

    .DateTimePicker,
    .ClearyReactSelect__control {
      @extend .text-small;

      border: 1px solid transparent;
      padding-right: 0;

      &:hover {
        @extend .bordered, .pointer;
      }
    }

    .DateTimePicker {
      &:disabled {
        text-align: right;
        pointer-events: none;
        background-color: transparent;

        & + .react-datepicker__close-icon {
          display: none;
        }
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }
}
