.LoadingContainer--Children {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: $zindex-one;

  &.isLoading {
    opacity: 0.3;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }

  &.react-reveal {
    animation-duration: 0.7s !important;
  }
}