.PickEffectButtonPositioner {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 110px;
  right: 30px;
}

.DisplayEffectFormButton {
  svg {
    width: 31px;
    height: 31px;
    fill: white;
  }
}

.EffectPickerForm {
  position: absolute;
  right: 0;
  bottom: 100%;
  background: var(--white-pure);
  width: 363px;
}

.PlayEffectButton {
  background: none;
  border: none;
  height: 38px;

  &[disabled] {
    opacity: 0.5;
  }

  span {
    pointer-events: none;
  }

  svg {
    width: 36px;

    circle {
      fill: var(--btn-primary-color);
    }
  }

  .SpinnerIcon {
    > div, svg {
      height: 36px !important;
    }
  }
}
