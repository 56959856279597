section.ProfileVideoIntroductions {
  display: grid;
  grid-column: 1 / span 3;
  grid-row: 1 / span 2;
  z-index: $zindex-one;
  width: 43px;
  height: 43px;
  align-self: flex-end;
  justify-self: center;
  position: relative;
  left: 60px;

  @include small-size {
    grid-column: 1 / span 12;
    grid-row: 1;
  }

  button {
    background: none;
    border: none;
    width: 43px;
    height: 43px;
    padding: 0;

    svg {
      fill: var(--btn-primary-color);

      path:nth-of-type(2) {
        stroke: var(--btn-primary-color);
      }
    }

    &:hover {
      svg {
        fill: var(--dynamic-color-button-primary-hover--btn-primary-color);

        path:nth-of-type(2) {
          stroke: var(--dynamic-color-button-primary-hover--btn-primary-color);
        }
      }
    }
  }
}
