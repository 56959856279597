.TicketEntries {
  .Entry {
    &__separator {
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-top: -18px;
      }

      .Entry__separator__dot:last-child {
        margin-bottom: -3px;
      }

      &__dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--border-color);
      }

      &__line {
        flex-grow: 1;
        height: 15px;
        width: 1px;
        background-color: var(--border-color);
      }
    }

    .arrow-icon {
      svg {
        width: 18px;
        height: 18px;
      }
    }

    svg .ai-icon {
      width: 12px;
      height: 12px;
    }

    &__title--replaceable {
      &:hover {
        background-color: var(--tile-background-color);
      }
    }

    &__close-icon {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__replaceable-content {
      position: relative;
    }
  }

  .Entry + .Entry {
    margin-top: -16px;
  }

  .Entry + .Entry .Entry__separator:first-child {
    display: none !important;
  }

  .Entry--replace.Entry--open {
    .Entry__separator:last-child {
      margin-top: -5px;
    }
  }

  :first-child > .Entry__separator {
    display: none !important;
  }
}
