.StepTemplateActionsDropdown{
  .Card-dropdownMenuItem{
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: none;
        fill: var(--text-color-primary);
      }
    }

    &.CopyAction {
      svg{
        width: 22px;
        height: 22px;
      }
    }

    &.DeleteAction {
      svg{
        path {
          stroke: none;
          fill: var(--danger-color);
        }
      }
    }
  }
}
