.ReactionsTab {
  .EmojiLibrary {
    box-shadow: var(--card-box-shadow);
  }

  .LikeIcon {
    @extend .p-3;

    box-shadow: var(--card-box-shadow);

    svg {
      height: 25px;
      width: 25px;
    }
  }
}
