div.EditFormPage {
  .AdminContent {
    max-width: 1085px;
  }

  .FormProgressGuidance {
    .step-container {
      .icon-circle-container {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid var(--dynamic-color-primary-color-lightest--highlight-color);
        background-color: var(--background-color);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: $zindex-base;

        svg {
          fill: var(--dynamic-color-primary-color-darker--highlight-color);

          g, path {
            fill: var(--dynamic-color-primary-color-darker--highlight-color);
          }
        }
      }

      .step-title {
        color: var(--text-color-secondary);
      }

      &.active {
        .step-title {
          color: var(--link-color);
        }
      }

      &.active,
      &.completed {
        .icon-circle-container {
          background-color: var(--link-color);
          border-color: var(--link-color);

          svg {
            fill: var(--white);

            g, path {
              fill: var(--white);
            }
          }
        }
      }
    }


    .step-separator {
      z-index: $zindex-zero;
      margin-left: 60px;
      margin-right: 60px;
      margin-top: 20px;
      border-top: 2px solid var(--dynamic-color-primary-color-lightest--highlight-color);
      position: relative;
      margin-bottom: -20px;
    }

    .add-question-button {
      width: fit-content;
    }
  }

  .react-datepicker-popper {
    z-index: $zindex-dropdown;
  }

  .FormTaskHeader,
  .FormHeader {
    .PillTabSelector {
      .PillTab--selected {
        background-color: var(--white) !important;
        border-bottom: 2px solid var(--link-color) !important;
        border-radius: 0%;
      }
    }

    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }

  .DropdownMenu-content {
    .delete-dropdown-item {
      border-top: 1px solid var(--light-gray);
    }
  }

  .form-settings-dropdown-container {
    .DropdownMenu-toggle {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 15px;
        height: 15px;
      }

      &:hover {
        cursor: pointer;

        background-color: var(--light-gray);
      }
    }

    .Card-dropdownMenuItem {
      svg {
        width: 20px;
        height: 20px;

        path, g {
          stroke: none;
          fill: var(--text-color-primary);
        }
      }

      .TrashIcon {
        path,
        g {
          stroke-width: 0.2;
          stroke: var(--status-label-red-text-color);
          fill: var(--status-label-red-text-color);
        }
      }

      .LinkIcon {
        transform: scale(0.8);
      }

      &.AnonymousResults > div > div {
        width: 100%;
      }
    }
  }

  .RecipientsAndInstructionsSection {
    .AudienceToggleSelector {
      position: relative;
      z-index: $zindex-dropdown;

      .form-group {
        margin-bottom: 0px;
      }
    }
  }

  .arrow-container {
    margin-top: -10px;

    span {
      font-size: 20px;
      margin-bottom: -15px;
      color: var(--border-color);
    }
  }

  .add-question-button {
    box-sizing: border-box;
    border: 2px dashed var(--border-color);
    color: var(--text-color-secondary);
  }

  .MultipleChoiceQuestionStats,
  .ScaleQuestionStats {
    min-height: 300px;
  }
}
