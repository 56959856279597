main.CreateBadgePage {
  @include default-page-grid;
  margin-top: 20px;

  h2 {
    grid-column: span 12;
    margin-bottom: 0px;
  }

  p {
    grid-column: span 12;
    font-size: var(--base-font-size);
    margin-bottom: 24px;
  }

  .BackButton {
    grid-column: span 4;
    margin-bottom: 10px;

    @include small-size {
      grid-column: span 6;
    }
  }

  .CreateBadgeCard {
    grid-column: span 12;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    gap: 30px;

    background: var(--white-pure);
    box-shadow: var(--card-box-shadow);
    border-radius: var(--square-border-radius-large);

    .Title {
      width: 540px;
      text-align: center;

      input {
        text-align: center;
        font-weight: 700;
        font-size: 36px;
        padding-bottom: 10px;
        min-height: 45px;
      }

      @include small-size {
        width: 100%;
        overflow: auto;

        input {
          font-size: 20px;
        }
      }
    }

    .Description {
      width: 540px;
      text-align: center;
      resize: none;

      @include small-size {
        width: 100%;
        overflow: auto;
      }
    }

    .ImageSelector,
    .ImageSelector-image {
      height: 100px;
      width: 100px;
    }

    .ImageSelector-dropzone {
      border: 1px solid var(--light-gray);
      border-radius: 6px;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 2px;
      min-height: 100%;
      min-width: 100%;
      height: 100%;
      width: 100%;

      p {
        font-size: 11px;
      }

      &:hover {
        background-color: $profile-photos-dropzone-hover-background;
      }
    }

    .CreateBadgeButton {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}
