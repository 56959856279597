input.ArticleEditor-publishedAtPicker {
  &::placeholder {
    color: var(--text-color-primary);
  }
}

.ArticleEditor-form {
  div.ql-tooltip {
    min-width: 100% !important;
  }
}
