.PagesNav {
  .Workspaces {
    &.isWorkspaceFocused {
      // hide the overflow of a large list of workspaces when we focus
      // so the animation looks better
      overflow: hidden;
    }

    header.WorkspacesNavHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 8px;
      padding-left: 18px;
      margin-left: 0;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .Workspace {
      .WorkspaceHeader {
        min-height: 32px;

        &:hover {
          background-color: var(--left-navbar-hover-color);

          .WorkspaceOwnerActions {
            display: flex;
          }

          .ToggleButton {
            svg {
              display: block !important;
            }
          }
        }

        .WorkspaceOwnerActions {
          display: none;

          @include up-to-medium-size {
            display: flex;
          }
        }

        .ToggleButton {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          flex-grow: 0;
          width: 18px;
          height: 18px;

          .ChevronDownIcon {
            svg {
              &, path {
                fill: var(--page-workspace-text-color);
              }
            }
          }

          &:hover {
            svg {
              &, path {
                fill: var(--text-color-muted);
              }
            }
          }

          svg {
            display: none;
            transform: rotate(-90deg);

            @include up-to-medium-size {
              display: block;
            }
          }

          &.showPages {
            svg {
              display: block;
              transform: none;
            }
          }
        }

        .EmojiOrPlaceholder {
          line-height: 20px;
          font-size: 20px;
          width: 20px;

          svg {
            padding-bottom: 2px;
          }
        }

        .ChevronDownIcon {
          svg {
            width: 16px;
            height: 16px;
          }
        }

        .WorkspaceName {
          color: var(--page-workspace-text-color);
          font-weight: 700;
          font-size: 14px;

          &:hover {
            color: var(--link-color);
          }

          @include up-to-medium-size {
            font-size: 15px;
          }
        }

        .IconButtonWithPopover {
          width: 24px;
          height: 24px;
          border-radius: var(--square-border-radius);
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 12px;
            height: 12px;
          }

          &:hover {
            background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
          }
        }
      }
    }
  }
}

.OpenWorkspacePopover {
  background-color: var(--btn-primary-color);

  .arrow::after {
    border-bottom-color: var(--btn-primary-color);
  }
}
