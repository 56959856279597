.EventsListPage {
  .AccessLevel {
    top: -3px;
    right: -5px;

    .LockIcon {
      svg {
        fill: var(--text-color-secondary);
        width: 24px;
      }
    }
  }

  @include small-size {
    overflow-x: hidden;
  }
}

.EventPage {
  .AccessLevel {
    .LockIcon {
      svg {
        fill: var(--kebab-menu-icon-color);
        width: 18px;
      }
    }

    svg {
      position: relative;
      top: -2px;
    }
  }
}
