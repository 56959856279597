.calendar-tooltip-wrapper {
  .InfoIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-secondary);
    }
  }

  .WhiteToolTip {
    opacity: 1 !important;
  
    .tooltip-inner {
      background-color: var(--white-pure);
      color: var(--text-color);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.12) !important;
    }
  
    .arrow::before {
      border-bottom-color: var(--white-pure);
    }
  }
  
}
