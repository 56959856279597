.StackedBarChart {
  height: 35px;
  border-radius: 5px;
  overflow: hidden;

  > div {
    height: 100%;
    background: var(--highlight-color);
  }
}
