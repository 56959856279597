$article-editor-tabs-height: 48px;
$admin-banner-height: 40px;

.ArticleEditorPage, .ArticleTemplateEditorPage {
  .grid-container {
    // we need to add this position fixed since AdminPageContent has a extra bottom padding that would be awesome
    // we could get rid of it. But I can't do this since the "old editor" still being used.
    position: fixed;
    left: 0;
    top: var(--nav-height);
    height: calc(100vh - var(--nav-height));
    width: 100%;

    display: grid;
    grid-template-areas:
      "header header"
      "sidebar main";
    // header take their content height
    // main uses the rest
    grid-template-rows: auto 1fr;
    // sidebar take their content width
    // main uses the rest
    grid-template-columns: auto 1fr;
  }

  .grid-header {
    grid-area: header;
  }

  .ArticleTemplatesSidebar {
    grid-area: sidebar;
    overflow-y: auto;
  }

  .ArticleEditorTabs {
    background-color: var(--left-navbar-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);

    .PillTabSelector {
      height: 100%;

      .PageTab {
        height: 100%;
        padding: 13px 30px;
      }
    }
  }

  .CirclesLoadingIndicator {
    grid-area: main;
  }


  .ArticleEditor {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    grid-area: main;
    overflow-y: auto;

    .ArticleContentEditor, .ArticleSummaryEditor, .ArticleAudienceAndSettingsEditor {
      width: var(--article-content-width);
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      @include xlarge-size {
        width: 624px;
      }

      @include up-to-large-size {
        width: 624px;
      }
    }
  }

  .PreviewIcon {
    svg {
      fill: var(--link-color);
      width: 12px;
      height: 12px;

      path {
        fill: var(--link-color);
      }
    }
  }
}
