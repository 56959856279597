.GroupMiniCard {
  div {
    line-height: 1.2;
  }
}

.GroupMiniCardPhoto {
  width: 40px;
  height: 40px;
  border-radius: var(--profile-image-border-radius) !important;
}
