.CustomizeChatNotificationsCard {
  // fix tiptap navbar z-index bug by setting parent component
  // z-index as 0
  z-index: 0;

  .InfoIcon {
    svg {
      fill: var(--text-color-secondary);
      width: 16px;
      height: 16px;
    }
  }
}
