section.GroupHierarchy {
  .hierarchy-content {
    display: flex;
    flex-direction: column;
  }

  @include small-size {
    .hierarchy-content {
      align-items: center;
    }

    .sub-teams {
      text-align: center;
    }
  }
}
