
.ClickWrapper {
  @include hover-device {
    &:hover {
      .card {
        box-shadow: 0 0 2px 1px var(--highlight-color) !important;
        cursor: pointer;

        .card {
          box-shadow: var(--card-box-shadow) !important;
        }
      }
    }
  }
}
