.FormResponsesStatsBanner {
  .InfoIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-secondary);

      &:hover {
        fill: var(--link-color);
        cursor: pointer;
      }
    }
  }
}
