.SlackAppTab {
  .ChannelConfigurationsTable.white-bg-table {
    box-shadow: none !important;

    th:first-child, td:first-child {
      padding-left: 0;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .enabled-icon {
      svg {
        fill: var(--admin-banner-green-background-color);
      }
    }

    .disabled-icon {
      svg {
        fill: var(--admin-banner-red-background-color);
      }
    }
  }
}

.SlackAppTab__RootSidebar {
  .RootSidebarContent {
    overflow: hidden !important;
  }

  .Sidebar {
    padding: 2rem !important;
  }

  input[type='checkbox'],
  input[type='radio'] {
    height: auto;
  }

  .plus-icon {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .trash-icon {
    svg {
      width: 24px;
      height: 24px;
      fill: var(--text-color-dark-muted);
    }
  }

  .react-select-wrapper {
    flex-grow: 1;
  }
}
