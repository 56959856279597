.VideoThumbnailSelect {
  .VideoThumbnailSelectDropdownContent {
    width: fit-content;

    .thumbnail-option {
      cursor: pointer;
      display: block;
      border-radius: 4px;
      width: 160px;
      position: relative;

      &.selected {
        outline: 2px solid var(--highlight-color);
      }

      img {
        max-width: 160px;
        height: 90px;
        width: auto;
        transition: all 0.2s ease-in-out;
      }

      .current-thumbnail {
        border-radius: 4px;
        background-color: var(--highlight-color);
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .thumbnail-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    .ImageSelector {
      width: 160px;
      max-width: unset;
      height: 90px;

      .ImageSelector-dropzone {
        padding-top: 0%;
        height: 90px;

        .ImageSelector-text {
          height: 90px;
        }
      }
    }
  }
}
