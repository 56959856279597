.TimezonesWidget {
  .slick-prev {
    display: none;
  }

  .slick-next {
    top: 0;
    right: 0;
    padding-left: 0.5rem;
    height: 100%;
    background-image: linear-gradient(90deg, var(--white-pure), transparent);
    width: 6rem;
    justify-content: flex-start;
    border-top-left-radius: var(--square-border-radius);
    border-top-right-radius: var(--square-border-radius);

    svg {
      path {
        fill: var(--text-color-secondary);
        stroke: var(--text-color-secondary);
      }
    }
  }
}
