.ProfilePhotos-primary {
  position: relative;
  img {
    @include profileImage;

    max-width: 100%;
  }

  &:hover .ProfilePhotos-destroy,
  &:hover .ProfilePhotos-reposition {
    display: flex;
  }
}

.ProfilePhotos-modal {
  .modal-content {
    background-color: transparent;
    border: 0;
  }
}

.ProfilePhotos-slider {
  img {
    border-radius: var(--square-border-radius);
    max-height: 500px;
    width: auto !important;
  }

  .slick-slide {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .slick-slide.slick-active {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .slick-track {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
    }
  }

  .slick-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .slick-dots {
    display: block !important;
  }

  .slick-arrow {
    height: 35px;
    width: 35px;
  }

  .slick-arrow svg {
    height: 100%;
    width: 100%;
  }

  .slick-prev {
    margin-left: -10%;
  }

  .slick-next {
    margin-right: -10%;
  }
}

.ProfilePhotos-other {
  @include profileImageSmall;

  &:hover .ProfilePhotos-destroy,
  &:hover .ProfilePhotos-promote {
    display: flex;
  }
}

.ProfilePhotos-destroy {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0.33rem;
  right: 0.33rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  background-color: var(--profile-photo-destroy-color);
  color: white;
  line-height: 1;
  justify-content: center;
  align-items: center;
  font-weight: normal;

  &:hover {
    background-color: var(--dynamic-color-profile-photos--profile-photo-destroy-color); // color($profile-photos-destroy-bg-color tint(25%));
  }
}

.ProfilePhotos-promote, .ProfilePhotos-reposition {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  border-radius: var(--square-border-radius);
  background-color: var(--profile-photo-promote-color);
  color: white;
  text-align: center;
  font-size: 0.667rem;
  line-height: 1;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    background-color: var(--dynamic-color-profile-photos--profile-photo-promote-color); //color($promote-background-color tint(25%));
  }

  @include touchscreen {
    @extend .mb-4;

    position: relative;
    color: var(--highlight-color);
    border: 1px solid var(--highlight-color);
    background-color: var(--white-pure);
    border-radius: var(--square-border-radius-large);
    padding: 5px 7px;
    align-items: center;
    font-size: 10px;
    left: 10px;
  }
}
