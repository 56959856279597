.ArticleStatsPage {
  .ArticleStatRow {
    gap: 24px;
  }

  .width-container {
    max-width: 1290px;
    margin: 0 auto;
  }

  .stat-value {
    font-weight: bold;
    display: block;
    font-size: 24px;
  }

  .reach-tab-content {
    .counters-wrapper {
      min-width: 174px;
    }

    .chart-wrapper {
      flex-grow: 1;

      p {
        font-size: 18px;
        font-weight: 500px;
      }
    }

    .chart-content {
      height: calc(100% - 48px);
      position: relative;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
