.ArticleWidgetType {
  // We need the outer ArticleWidgetType class because the container widths
  // Need to be of the widget, not the individual article item
  container-type: inline-size;

  .ArticleItem {
    border-radius: var(--square-border-radius);
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "image banner button"
      "image article-type button"
      "image content button";

      &:hover {
        @extend .bg-highlight-color-tint-90;

        .ArticleItem__title {
          color: var(--link-color);
        }
      }

    .ArticleThumbnailImage {
      @extend .mr-3;
      grid-area: image;
      border-radius: var(--square-border-radius-large);
      width: 120px;
      height: 120px;
    }

    .AcknowledgementBanner {
      @extend .mb-2;
      grid-area: banner;
      padding: 3px 7px !important;
      width: fit-content !important;

      .AcknowledgementText {
        @extend .text-smallest, .font-weight-500, .m-0;
      }

      .AcknowledgementIcon {
        display: none;
      }
    }

    .Article-Type {
      grid-area: article-type;
      margin-bottom: 6px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .ArticleItem__content {
      grid-area: content;
      display: flex;
      flex-direction: column;

      .ArticleItem__title {
        margin-bottom: 6px;
        word-break: break-word;
      }
    }

    .SocialShareButton {
      grid-area: button;
      display: flex;
      align-items: center;
    }

    @container (max-width: 650px) {
      grid-template-columns: auto 1fr;
      grid-template-areas:
        "image banner"
        "image article-type"
        "image content"
        "image button";

      .SocialShareButton {
        @extend .mt-2;
      }
    }

    @container (max-width: 480px) {
      .ArticleThumbnailImage {
        width: 90px;
        height: 90px;
      }

      .ArticleItem__title {
        font-size: 1.2rem;
      }

      .SocialShareButton {
        margin-top: 0 !important;

        button {
          font-size: 11px !important;
          height: 23px;
          padding-right: 0.75rem;
          padding-left: 0.75rem;
          min-width: auto;
        }
      }

      .ArticleByLine {
        .PublishedAt {
          display: none;
        }
      }
    }

    @container (max-width: 380px) {
      grid-template-columns: auto 1fr;
      grid-template-areas:
        "banner banner"
        "image article-type"
        "image content"
        "image button";

      .ArticleThumbnailImage {
        @extend .mr-2;
        grid-area: image;
        width: 65px;
        height: 65px;
      }

      .ArticleItem__title {
        font-size: 1rem;
      }
    }
  }
}
