@keyframes selectFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes selectFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes tagFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes tagFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.NewsPage {
  @include default-page-grid;

  @include small-size {
    overflow-x: hidden;
  }

  .TabSelector {
    grid-column: 1 / span 12;
    overflow: inherit;
  }

  .TabSelector--content {
    @include default-page-grid;
    padding: 0;
  }

  .NewsPage-TagFilter, .NewsPage-InfiniteScroller, .NewsPage-NoResults {
    grid-column: 3 / span 8;

    @include large-size {
      grid-column: 2 / span 10;
    }

    @include up-to-medium-size {
      grid-column: 1 / span 12;
    }
  }

  .ClearyReactSelect {
    width: 60%;
  }

  .ClearyReactSelect__placeholder,
  .ClearyReactSelect__menu-notice {
    font-size: 0.85rem !important;
  }

  .ClearyReactSelect__menu {
    z-index: $zindex-dropdown !important;
  }

  .NewsPage-filter-tag:not(.filter-tag-fadeIn) {
    visibility: hidden;
  }

  .NewsPage-filter-select:not(.filter-select-fadeIn) {
    visibility: hidden;
  }

  .Filter-label,
  .NewsPage-filter-select,
  .NewsPage-filter-tag {
    height: 40px;
  }

  .NewsPage-filter-tag {
    padding-left: 10px;
  }

  .NewsPage-filter-select,
  .NewsPage-filter-tag {
    width: 300px;
  }

  .filter-select-fadeIn {
    animation: selectFadeIn 0.3s ease-in-out;
    animation-fill-mode: forwards;
  }

  .filter-select-fadeOut {
    animation: selectFadeOut 0.3s ease-in-out;
    animation-fill-mode: forwards;
  }

  .filter-tag-fadeIn {
    animation: tagFadeIn 0.3s ease-in-out;
    animation-fill-mode: forwards;
  }

  .filter-tag-fadeOut {
    animation: tagFadeOut 0.3s ease-in-out;
    animation-fill-mode: forwards;
  }
}
