.card-shadow {
  box-shadow: var(--card-box-shadow);
}

.border-shadow {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
}

.bg-white {
  background-color: var(--white-pure);
}

.bg-tile {
  background-color: var(--tile-background-color);
}
