section.GroupContact {
  .info-with-icon {
    display: flex;
    align-items: flex-start;
    word-break: break-word;
  }

  .info-field {
    color: var(--text-color-primary);

    &.isEmpty {
      color: var(--empty-state-text);

      .info-with-icon {
        align-items: center;
      }
    }
  }

  .GroupIcon {
    height: 1.6rem;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }

    &.empty-state {
      svg {
        fill: var(--empty-state-text);
      }
    }
  }
}
