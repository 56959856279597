.JourneyLaunchSuccessModal{
  .modal-header {
    background-color: var(--highlight-color);
  }

  .modal-body {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  .close {
    color: $white-pure;
  }

  .journey-live-container {
    background-color: var(--highlight-color);
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    .title {
      color: $white-pure;
    }
    .circle-container {
      background-color: $white-pure;
      padding: 15px;
      border-radius: 50%;
      .CelebrationIcon {
        fill: var(--highlight-color);
      }
    }
  }

  @include up-to-medium-size {
    & .users-in-journey {
      flex-direction: column;
      justify-content: flex-start;
      .RoundedAvatars {
        margin-bottom: 10px;
        margin-left: 10px;
      }
    }
  }
}
