.CelebrationEditor__Main {
  width: 100%;

  .CelebrationEditor__Main__content-wrapper {
    margin: 0 auto;
    max-width: $container-width;
  }
}

.ShowTemplatesButton {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 30px;
  right: 30px;

  .text-container {
    font-size: 16px;
  }

  svg {
    width: 27px;
    height: 27px;
    &, * {
      fill: white;
    }
  }
}
