ol.pagination {
  li {
    a {
      font-weight: 400;
    }

    &.active, &.active a {
      font-weight: 600;
    }
  }
}
