.ZohoSettings {
  .ZohoSettings__integration-status {
    svg {
      width: 16px;
      height: 16px;
    }

    .waiting {
      svg {
        animation: infinite-rounding 2s linear infinite;

        @keyframes infinite-rounding {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        path {
          fill: var(--text-color-secondary);
        }
      }
    }

    .success {
      svg path {
        fill: var(--success-color);
      }
    }

    .error {
      svg path {
        fill: var(--error-color);
      }
    }
  }
}
