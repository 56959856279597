.EventSchedule {
  // col-md-6 is used by the current edit event screen,
  // once we integrate the new modal, this will not be needed.
  .EventDateTimePicker {
    @extend .w-100;
    .col-md-6 {
      @extend .col-md-3;
    }
  }

  .DatePickerDecorator {
    span > label {
      margin: 0;
    }

    &.hasError {
      .react-datepicker__input-container input {
        border-bottom: 1px solid var(--error-color);
      }
    }
  }

  .DatePicker-circle {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 5px;
  }

  .react-datepicker__input-container input {
    border: none;
    font-size: 0.9rem;
    border-radius: 0;
    padding-left: 0;
  }

  .HelpText {
    font-size: 0.8rem;
    font-style: italic;
    color: var(--text-color-secondary);
  }

  .DateTooltip {
    svg {
      height: 16px;
      path {
        fill: var(--gray);
      }
    }
  }

  .DatePicker--eventTime {
    .DatePicker-circle {
      background: var(--black);
    }
    label {
      color: var(--black);
    }

    .react-datepicker__input-container input {
      border-bottom: 1px solid var(--black);
    }
  }

  .DatePicker--archivedAt {
    .DatePicker-circle {
      background: var(--gray);
    }

    label {
      color: var(--gray);
    }

    .react-datepicker__input-container input {
      border-bottom: 1px solid var(--gray);
    }
  }

  .DatePicker--startedAt,
  .DatePicker--lockedAt {
    width: 25%;
    .EventDateTimePicker {
      .col-md-6 {
        @extend .col-md-12;
      }
    }

    .DatePicker-circle {
      background: var(--highlight-color);
    }

    label {
      color: var(--highlight-color);
    }

    .react-datepicker__input-container input {
      border-bottom: 1px solid var(--highlight-color);
    }
  }

  @include max-media-width($bootstrap-xl) {
    .react-datepicker__input-container input {
      width: 100%;
    }

    .DatePickerDecorator {
      width: 100%;
    }

    .col-md-6 {
      display: block !important;
      width: 100% !important;
      flex: 1 !important;
      max-width: 100% !important;
    }
  }

  .Timeline {
    .TimelineItem {
      .TimelineContent,
      .TimelineDateTime {
        flex: 1;
        word-wrap: break-word;
        width: 60px;
        white-space: normal;
      }
    }

    .TimelineItem.createdAt {
      .Content {
        color: var(--light-mid-gray);
      }
      .Head {
        svg {
          fill: var(--light-mid-gray);
        }
      }
    }

    .TimelineItem.startedAt {
      .Content {
        color: var(--highlight-color);
      }
      .Head {
        svg {
          fill: var(--highlight-color);
        }
      }
    }

    .TimelineItem.eventTime {
      .Content {
        color: var(--black);
      }
      .Head {
        svg {
          fill: var(--black);
        }
      }
    }

    .TimelineItem.lockedAt {
      .Content {
        color: var(--highlight-color);
      }
      .Head {
        svg {
          fill: var(--highlight-color);
        }
      }
    }

    .TimelineItem.archivedAt {
      .Content {
        color: var(--mid-gray);
      }
      .Head {
        svg {
          fill: var(--mid-gray);
        }
      }
    }

    .Bubbles {
      position: relative;
      z-index: $zindex-base;
    }

    .Line {
      z-index: $zindex-zero;
      background: var(--light-gray);
      height: 3px;
      top: 62%;
      width: calc(100% - 5rem) !important;
      left: 2rem;
    }
  }

  .react-datepicker-popper {
    z-index: $zindex-popover;
  }
}
