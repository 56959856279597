.SocialShareComponent {
  width: 100%;

  .SocialShareViewer {
    border: 1px solid transparent;
    border-radius: 8px;
  }

  .suggestion-counter  {
    position: absolute;
    right: 0px;
    bottom: -30px;
  }

  .close-icon-wrapper {
    top: 10px;
    right: 10px;
  }

  .SocialShareEditor {
    border: 1px solid var(--border-color);
    box-shadow: var(--card-box-shadow);
    border-radius: 8px;
  }

  &.is-selected {
    .SocialShareViewer, .SocialShareEditor {
      border-color: var(--link-color);
    }
  }
}
