.CarouselCardContainer {
  // We don't use height here because height: 200px would stretch the image to fit the 200px by 920px
  // Setting max-height w/ the aspect-ratio preserves the image but displays a 200px by 920px window
  // over the image
  max-height: 200px;
  max-width: 1290px;
  margin: auto;
  aspect-ratio: 129 / 20;
  overflow: hidden;

  @include xlarge-size {
    transform: scale(0.9248);
    aspect-ratio: unset;
  }

  @include large-size {
    transform: scale(0.9038);
    aspect-ratio: unset;
  }

  @include up-to-medium-size {
    transform: scale(0.894);
    aspect-ratio: unset;
  }
}

.EditableGridLayout--small, .EditableGridLayout--medium {
  .CarouselCardContainer {
    transform: scale(0.894);
    aspect-ratio: unset;
  }
}

.CarouselCard {
  overflow: hidden;
  border-radius: var(--square-border-radius) !important;

  max-height: inherit;
  max-width: inherit;
  aspect-ratio: inherit;

  .InnerCarouselCard {
    padding: 1.5rem;

    @include medium-size {
      padding: 0;
    }
  }
}
