.BackButton {
  cursor: pointer;

  .BackIcon {
    fill: var(--text-color-secondary);
    stroke: var(--text-color-secondary);
  }

  .BackButton-link {
    color: var(--text-color-secondary) !important;
    font-weight: 400;
  }

  &:hover {
    .BackIcon {
      fill: var(--link-color);
      stroke: var(--link-color);
    }

    .BackButton-link {
      color: var(--link-color) !important;
    }
  }

  &.link-color {
    .BackIcon {
      fill: var(--link-color);
      stroke: var(--link-color);
    }

    .BackButton-link {
      color: var(--link-color) !important;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &.bold {
    .BackButton-link {
      font-weight: 600 !important;
    }
  }
}
