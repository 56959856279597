.LayoutCollectionNavbar {
  .ViewModeButton {
    @extend .mr-1;
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  

    .isvg {
      display: flex;
      width: 20px;
      height: 20px;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: var(--text-color-secondary)
    }

    &.isSelected {
      background-color: var(--highlight-color);

      svg {
        fill: var(--white-pure);
      }
    }
  }
}
