.QuestionTitleWithIndexBubble {
  .QuestionIndexCirlce {
    background-color: rgba(14, 0, 60, 0.28);
    color: var(--white);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
}
