.GroupsWidget {
  container-type: inline-size;

  .PeopleWithFlag {
    display: block;
    height: 206px;
    max-width: 100%;

    .isvg, svg {
      height: 100%;
      width: 100%;
    }
  }

 .GroupsGrid {
   display: grid;
   grid-template-columns: 1fr;
   gap: 1.5rem 1rem;

   @container (min-width: $bootstrap-sm) {
     grid-template-columns: 1fr 1fr;
   }
 }

 .GroupListItem {
  &:hover {
    border-radius: var(--square-border-radius);
    background-color: var(--dynamic-color-link-color-lightest--link-color);

    span {
      color: var(--link-color);
    }
  }
 }
}
