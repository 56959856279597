.JourneyBlueprintSettingsPage {
  .GroupCorrespondentsGrid {
    @extend .bordered;

    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 1rem;

    .TrashIcon {
      width: 24px;
      height: 24px;
    }
  }
}
