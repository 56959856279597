.ArticleTemplatesSidebar {
  .ListIcon, .GridIcon {
    width: 26px;
    height: 26px;
    background-color: var(--white-pure);
    border-radius: var(--square-border-radius);

    svg {
      width: 20px;
      height: 20px;
      fill: var(--text-color-secondary);

      path {
        fill: var(--text-color-secondary);
      }
    }

    &:hover {
      border: 1px solid var(--highlight-color);

      svg {
        fill: var(--highlight-color);

        path {
          fill: var(--highlight-color);
        }
      }
    }

    &.isSelected {
      border: 1px solid var(--highlight-color);

      svg {
        fill: var(--highlight-color);

        path {
          fill: var(--highlight-color);
        }
      }
    }
  }
}
