.CelebratedPerson {
  margin: 0 auto;
  max-width: 280px;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .EffectPlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-zero;
  }

  .EmojiWrapper {
    top: 63%;
    left: 58%;
    background: var(--white-pure);
    border-radius: 50%;
    width: 20%;
    height: 20%;
    font-size: 24px;
  }

  .CelebratedPersonThumbWrapper {
    z-index: $zindex-one;
  }

  .CelebratedPersonThumb {
    border-radius: 50%;
    width: 55%;
    transform: translate(-2%, -1.1%);
  }
}

.CelebratedCard {
  display: block;
  z-index: $zindex-one;

  .Title {
    font-size: 21px;
  }
}
