.ArticlesTable {
  .article-status-select {
    min-width: 150px;
  }

  .white-bg-table {
    tbody {
      th {
        padding: 20px 0 20px 30px;

        &:last-of-type {
          padding: 20px 30px;
        }
      }

      td {
        padding: 14px 0 14px 30px;

        &:last-of-type {
          padding: 14px 30px;
        }
      }
    }
  }

  .ClearyReactSelect__menu {
    z-index: $zindex-dropdown !important;
  }
}
