.InvitedPeopleModal {
  .type-column {
    width: 150px;
  }

  .PaginationContainer {
    .entriesText {
      width: auto;
    }

    .pagination {
      margin-right: initial;
    }
  }
}
