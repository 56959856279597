section.ManageMembers {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);

  .SearchOrSelectList {
    display: flex;
    width: 100%;

    .EmployeeSearch {
      width: 50%;

      @include small-size {
        width: 100%;
      }
    }

    .BatchCreateMembersButton {
      @include small-size {
        display: none;
      }
    }
  }


  div.GroupMembershipRow {
    gap: 20px;

    div.ClearyReactSelect {
      width: 25%;
    }

    div.FormCheck {
      input.form-check-input {
        margin-right: 8px !important;
      }
    }

    input.MembershipRole {
      flex-grow: 2;
    }

    button.AddButton {
      min-width: 65px;
    }
  }
}
