.TiptapToolbarSelect {
  &.LineHeightSelect {
    width: 48px;

    // This fixes a bug where this select flashes when the editor is re-rendered or the cursor is moved
    span,
    div {
      animation: none !important;
    }

    &.disabled {
      svg {
        fill: var(--editor-toolbar-disabled-icon-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .ClearyReactSelect__menu {
      width: 150px;
    }
  }
}
