.CelebrationListPageWidget {
  &.farewell {
    background-color: var(--farewell-card-background-color);

    .text-container {
      margin-right: 150px;
    }
  }

  &.achievement {
    background-color: var(--achievement-card-background-color);
  }

  &.new_parent {
    background-color: var(--new-parent-card-background-color);
  }

  .text-container {
    margin-right: 140px;
    min-height: 123px;
  }

  h1 {
    font-size: 24px;
    color: var(--white-pure);
  }

  p {
    color: var(--white-pure);
  }

  .Illustration {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

