.TiptapToolbarSelect {
  &.ColumnLayoutSelect {
    width: 42px;

    &.disabled {
      svg {
        fill: var(--editor-toolbar-disabled-icon-color);

        path {
          fill: var(--editor-toolbar-disabled-icon-color);
        }
      }
    }
  }
}
