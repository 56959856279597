// TABLES

table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}

thead {
  th {
    font-weight: 600;
    text-align: left;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    white-space: nowrap;
    padding: 0.25rem 0;
    padding-left: 0.5rem;

    &[data-sort] {
      cursor: pointer;
      color: var(--highlight-color);

      &[data-sorted=desc]:after {
        font-size: 0.8em;
        content: ' ▼';
      }

      &[data-sorted=asc]:after {
        font-size: 0.8em;
        content: ' ▲';
      }
    }
  }
}


td {
  border-bottom: 1px solid var(--border-color);
  padding: 0.5rem;
}


.white-bg-table {
  background-color: var(--white-pure);
  box-shadow: var(--card-box-shadow);
  table-layout: auto;
  width: 100%;
  border-radius: var(--square-border-radius-large);

  @include small-size {
    display: block;
    overflow-x: scroll;
  }

  thead {
    th {
      font-size: 12px;
      font-weight: 600;
      padding: 20px 30px;
      border-top: none;
      border-bottom: 1px solid var(--border-color);
    }
  }

  tbody {
    td {
      font-size: 14px;
      vertical-align: middle;
      padding: 14px 30px;
      border-top: none;
      border-bottom-color: var(--border-color);

      & div, a {
        font-size: 14px;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    tr {
      &.hide-row-transition {
        animation: table-row-fade 2s;
        animation-fill-mode: forwards;

      }
    }
  }

  &.bordeless-table {
    box-shadow: none;

    th:first-child, td:first-child {
      padding-left: 0;
    }

    th:last-child, td:last-child {
      padding-right: 0;
    }
  }
}

.table-with-row-hover {
  tbody {
    tr {
      &:hover {
        background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);
        cursor: pointer;
      }
    }
  }
}

.analytics-table {
  thead {
    th {
      @extend .text-small, .text-secondary, .font-weight-500, .border-0;

      padding-left: 16px;
    }
  }

  tbody {
    td {
      @extend .border-0;

      padding: 14px 16px;
    }

    tr:nth-child(odd) {
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
    }

    tr {
      &.hide-row-transition {
        animation: table-row-fade 2s;
        animation-fill-mode: forwards;

      }
    }
  }
}
