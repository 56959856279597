div.AudienceToggleSelector {
  label {
    margin-bottom: 0px;
  }
}

.EstimatedAudienceAvatars {
  background-color: var(--lightest-gray);
  border-radius: var(--square-border-radius-small);

  @include up-to-medium-size {
    flex-direction: column;
    justify-content: flex-start;

    .RoundedAvatars {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}
