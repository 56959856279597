// Also used for the article edit page
.ArticlePage {
  @include default-page-grid;
  margin-top: 1.5rem;

  .ArticlePage-content {
    grid-column: 3 / span 8;

    @include xlarge-size {
      grid-column: 2 / span 10;
    }

    @include large-size {
      grid-column: 2 / span 10;
    }

    @include up-to-medium-size {
      grid-column: 1 / span 12;
    }
  }
}
