.LegendItem {
  gap: 7px;
  border: 1px solid var(--light-gray);
  border-radius: var(--square-border-radius);

  .Color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
