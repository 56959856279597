section.GroupFollowers {
  .GroupIcon {
    height: 1.6rem;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--link-color);
    }

    &.empty-state {
      svg {
        fill: var(--empty-state-text);
      }
    }
  }
}
