.AiAssistantAnswerFeedback {
  .FeedbackButton {
    cursor: pointer;

    .isvg, svg {
      width: 20px;
      height: 20px;
    }

    svg {
      fill: var(--text-color-dark-muted);

      path {
        fill: var(--text-color-dark-muted);
      }
    }

    &.helpful {
      svg {
        fill: var(--helpful-color);

        path {
          fill: var(--helpful-color);
        }
      }
    }

    &.not-helpful {
      svg {
        fill: var(--not-helpful-color);

        path {
          fill: var(--not-helpful-color);
        }
      }
    }
  }
}
