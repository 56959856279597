section.GroupMembershipActions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  @include small-size {
    grid-column: 1 / span 12;
    justify-content: center;
  }

  .notifications-button {
    min-width: 36px;
    width: 36px;
    background-color: var(--btn-background-color);
    border: none;

    &:hover {
      // override secondary hover color
      background-color: var(--btn-hover-background-color) !important;
    }

    svg {
      fill: var(--link-color);
      height: 20px;
      width: 20px;
    }
  }
}
