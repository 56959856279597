main.AiAnswersTab {
  @include default-page-grid;

  @extend .mb-4;

  grid-column: span 12;
  row-gap: 30px;

  .AiAnswersTab__Answers {
    table td {
      vertical-align: middle;
    }
  }

  .AiAnswersTab__Answers__query {
    font-weight: 600;
    color: var(--highlight-color);
    cursor: pointer;
  }

  .BackButton {
    width: fit-content;
  }
}

.AiAnswersTab__RootSidebar {
  .Sidebar {
    padding: 2rem !important;
  }

  .AiSources {
    a {
      @extend .bordered;
    }
  }

  .TiptapView {
    // sup tags are used for mentions, we are currently not displaying that to end users
    // sub tag returns the confidence score, we don't want to show it in the text
    sup, sub {
      display: none;
    }
  }
}

.AiAnswersTab__AskAIConversations {
  .EmployeeSearch {
    width: 270px;
  }
}

.AiAnswersTab__Conversations__Sidebar {
  .Sidebar {
    overflow: initial !important;
    padding: 0 !important;
    background-color: white;

    .Sidebar__header {
      margin-bottom: 0 !important;
    }

    .ConversationHistory__Content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.AiEvaluationSyncIcon {
  svg {
    width: 17px;
    height: 17px;
  }
}
