.Toastify__toast {
  margin-bottom: 0;
  border-radius: 0;
  text-align: center;

  .Toastify__toast-body div {
    color: $white-pure;
  }

  .Toastr--CloseIcon {
    display: flex;
    align-items: center;

    svg {
      stroke: $white-pure;
      fill: $white-pure;
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

.Toastify__toast.Toastify__toast--default {
  .Toastify__toast-body div {
    color: $black;
  }

  .Toastr--CloseIcon {
    display: flex;
    align-items: center;

    svg {
      stroke: var(--text-color-primary);
      fill: var(--text-color-primary);
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

.Toastify__toast.Toastify__toast--success {
  background-color: $success-color;
}

.Toastify__toast.Toastify__toast--info {
  background-color: $info-color;
}

.Toastify__toast.Toastify__toast--error {
  background-color: $error-color;
}

.Toastify__toast.Toastify__toast--warning {
  background-color: $yellow;
}
