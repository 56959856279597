.FeedWidget {
  .FeedItem {
    margin: 0;
  }

  .ArticleCard {
    box-shadow: none !important;

    .card-body {
      padding: 0;
    }
  }

  hr {
    margin: 30px 0px;
    border-bottom: 1px solid var(--feed-widget-separator-color);
  }
}
