.InactiveCorrespondentBanner {
  @extend .p-3;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: calc(-1 * var( --admin-content-y-margin));

  .AlertOutlineIcon {
    svg {
      height: 24px;
      width: 24px;
      fill: var(--white-pure);
    }
  }
}
