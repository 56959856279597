.HighlightedEvent {
  // Necessary to grow outside of parent container
  // These px values are a result of testing
  .MobileHR {
    width: calc(100% + 36px);
    margin-left: -18px;
  }

  &.hasCoverImage {
    .card-body {
      padding: 0;

      .row {
        padding: 1.25rem;
      }
    }
  }
}
