.ClearyReactSelect__input {
  height: auto;
}

.ClearyReactSelect--is-disabled {
  background-color: var(--lightest-gray);

  .ClearyReactSelect__control--is-disabled {
    background-color: inherit;
  }
}

.ClearyReactSelect__multi-value {
  background-color: var(--white-pure) !important;
  border: 1px solid var(--skill-tag-border-color) !important;
  border-radius: 5px !important;

  &--is-disabled {
    background-color: var(--lightest-gray) !important;
  }
}

.ClearyReactSelect__multi-value__remove {
  color: var(--skill-tag-border-color) !important;

  &:hover {
    background-color: transparent !important;
    color: var(--highlight-color) !important;
  }
}

.ClearyReactSelect__option {
  cursor: pointer !important;
}

.ClearyReactSelect__option--is-disabled {
  cursor: not-allowed !important;
}

.ClearyReactSelect__placeholder {
  @extend .truncate-text-at-1-lines;
}
