.EditTemplateToolbar {
  // z-index and position required to fix a conflict
  // with the navbar's bottom box shadow.
  z-index: $zindex-dropdown;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: var(--nav-height);
  border-bottom: 1px solid var(--secondary-gray);
  background-color: var(--white) !important;

  .CancelButton {
    margin-right: 0 !important;
  }

  .DropdownMenu-content {
    top: 52px !important;
    right: -8px !important;
  }

  .DeleteTemplateIcon {
    path,
      g {
        stroke: var(--status-label-red-text-color);
        fill: var(--status-label-red-text-color);
      }
  }

  .Navbar-companyImage {
    position: fixed;
    left: 0;
  }

  button.btn {
    min-width: unset;
  }


  .Toolbar-link {
    cursor: pointer;
    color: var(--text-color-primary);

    svg {
      fill: var(--text-color-primary);
    }

    &:hover {
      color: var(--link-color);

      svg {
        fill: var(--link-color);
      }
    }
  }
}
