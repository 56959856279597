.ViewOrgChartAndSendShoutout {
  display: grid;
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
  justify-content: center;
  align-self: start;

  svg.OrgChartIcon * {
    fill: var(--link-color);
  }

  @include small-size {
    grid-row: 3;
    grid-column: 1 / span 12;
    margin-bottom: 35px !important;
  }
}
