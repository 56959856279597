.DropdownMenu-toggle {
  cursor: pointer;
  line-height: 0;
}

.DropdownMenu-toggle--light {
  color: $white-pure;
}

.DropdownMenu-content {
  position: absolute;
  right: 0;
  box-shadow: var(--card-box-shadow);
  border-radius: var(--square-border-radius);
  z-index: $zindex-popover;
}

.DropdownMenu-content > div:first-of-type {
  border-top-left-radius: var(--square-border-radius);
  border-top-right-radius: var(--square-border-radius);
}

.DropdownMenu-content > div:last-of-type {
  border-bottom-left-radius: var(--square-border-radius);
  border-bottom-right-radius: var(--square-border-radius);
}

.DropdownMenu-carat {
  transition: transform 0.25s;
  font-size: 1.2rem;
  transform: rotate(-270deg);
  margin-top: 2px;
  color: var(--nav-text-color);

  &.expanded {
    transform: rotate(-90deg);
  }
}
