div.CelebrationIconBanner {
  background-color: var(--highlight-color);
  padding-left: 15px;
  padding-right: 15px;
  display: flex;

  .title {
    color: $white-pure;
  }

  .circle-container {
    background-color: $white-pure;
    padding: 15px;
    border-radius: 50%;

    .CelebrationIcon {
      fill: var(--highlight-color);
    }
  }
}
