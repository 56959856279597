.Tag-body {
  height: 100%;
  line-height: 1.4em;
  font-size: 0.8em;
  padding: 0 0.5em 0.0625em;
  overflow: hidden;
  margin-right: 0.5em;
  border-radius: var(--square-border-radius);
  background-color: var(--tag-background-color);
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  color: $white-pure;
}

.Tag-header {
  color: var(--text-color-secondary);
  font-size: 0.75em;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.0625em;
  margin-top: 0.6em;
  margin-bottom: 0.75em;
}

.Tag-delete {
  border-right: 1px solid #a3a3a3;
  margin-right: 0.2em;
  display: inline-block;
  flex: 0 0 auto;
  padding-right: 0.2em;
}

.Tag-text {
  font-size: 1em;
  margin-top: 1px;
  color: $white-pure;
}
