.CarouselCardForm {
  & .react-datepicker-popper {
    z-index: $zindex-popover
  }

  .CoverImageWidget {
    .AddCover {
      max-width: unset;
      min-width: 140px;
      border-radius: var(--button-border-radius) !important;
    }
  }
}
