@mixin profileImageSmall() {
  position: relative;
  width: 30%;
  margin-top: 5%;

  img {
    width: 100%;
    height: 100%;

    @include profileImage
  }

  &:nth-child(3n-1) {
    margin-left: 5%;
    margin-right: 5%;
  }
}