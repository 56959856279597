.AddCommentBubbleMenu {
  .AddCommentCircle {
    border-radius: 50%;
    background-color: var(--white-pure);
    box-shadow: var(--card-box-shadow);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      &, * {
        fill: var(--highlight-color);
      }
    }
  }

  .comment-editor-container {
    background-color: var(--white-pure);
    width: 300px;
    min-width: 300px;
    border-radius: 8px;
    border: 1px solid var(--highlight-color);
  }
}
