// !! provisional class used to set the new width of the
// !! search container
.New-Navbar-search {
  // Since we're switching between flex and block between mobile
  // and desktop, we want to set display to -webkit-box so that
  // .truncate-text-at-1-lines can continue working.
  .MainContent {
    @media (min-width: 768px) {
      display: -webkit-box !important;
    }
  }

  .MainContent {
    @media (max-width: 768px) {
      .Title {
        @extend .truncate-text-at-1-lines;
      }
    }
  }
}

//!! get rid of the new prefix later
.GlobalSearch {
  width: 220px;

  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;

  &.isFocused {
    width: 640px;
  }

  em {
    font-weight: 700;
    font-style: normal;
  }

  .GlobalSearch-GroupHeading > div {
    text-transform: capitalize;
  }

  .ShowAllResults {
    color: var(--link-color);
  }

  .SeeMore {
    font-weight: 600;
    color: var(--link-color);
  }

  .GlobalSearch-Dropdown__group-heading {
    font-weight: 700;
    font-size: 13px;
    color: var(--text-color-secondary);
  }

  .EmojiOrPlaceholder {
    display: inline-block !important;
    width: 26px;
    height: 24px;
    font-size: 20px;
    line-height: 20px;
  }
}

.Navbar-mobile {
  .GlobalSearch {
    width: 100%;

    .GlobalSearch-Dropdown__group-heading {
      font-size: 14px;
    }

    .EmDash {
      display: none;
    }

    .SecondaryTextGroup {
      @extend .truncate-text-at-1-lines;
    }

    img {
      min-width: 30px !important;
      min-height: 30px !important;
      margin: 0 1rem 0 0 !important;
    }
  }

  .SearchResultOptionImg {
    svg {
      min-width: 30px !important;
      min-height: 30px !important;
      margin: 0 1rem 0 0 !important;
    }
  }
}

.SearchResultOptionImg {
  margin-bottom: 0.125rem;
  margin-top: -0.125rem;

  svg {
    fill: var(--text-color-secondary);
    width: 22px;
    height: 22px;
    margin-bottom: 0.125rem;
    margin-top: -0.125rem;
  }
}

.GlobalSearchIcon {
  position: absolute;
  left: 12px;

  svg {
    fill: var(--text-color-secondary);
  }
}
