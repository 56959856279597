.SurveysWidget {
  container-type: inline-size;

  .CompletedChecklist {
    display: none;
  }

  @container (min-width: 500px) {
    .CompletedChecklist {
      display: block;
      width: 161px;
      height: 165px;

      svg {
        fill: var(--highlight-color);
      }
    }
  }

  .SurveyLink {
    box-shadow: var(--card-box-shadow);
    border: 1px solid var(--border-color);

    &:hover {
      border: 1px solid var(--highlight-color);
    }
  }
}
