.EditableContent-Display {
  display: inline-flex;
  color: var(--text-color-secondary);

  *:not(svg) {
    // unset the heights of child elements, like Tags
    height: unset;
  }

  svg {
    fill: var(--text-color-secondary);
  }

  .pencilIcon {
    display: none;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--btn-primary-color);
      vertical-align: top;
      margin-top: 0;
    }
  }

  &.canEdit {
    padding: 4px 23px 4px 0;

    &.isEmpty {
      color: var(--text-color-secondary);
    }
  }

  &.canEdit:hover {
    &:not(.isEmpty) {
      padding: 4px 0 4px 4px;
      border-radius: var(--editable-content-hover-radius);
      background-color: var(--border-color);

      .pencilIcon {
        display: inline-block;
      }

      &:not(.isTrigger) {
        cursor: text;
      }

      &.isTrigger {
        cursor: pointer;
      }
    }

    &.isEmpty {
      color: var(--link-color);
      cursor: pointer;

      svg {
        fill: var(--link-color);
      }
    }
  }

  &:not(.canEdit) {
    &.isTriggerable {
      color: var(--link-color);
      cursor: pointer;

      svg {
        fill: var(--link-color);
      }
    }

    &.isTriggerable:hover {
      color: var(--dynamic-color-link-text--link-color);
      cursor: pointer;

      svg {
        fill: var(--dynamic-color-link-text--link-color);
      }
    }
  }
}

.EditableUserContent-DisplayElement {
  .EmployeeMiniCard {
    &, &:hover {
      background: initial;
    }
  }
}
