.TemplatePreviewModal {
  position: relative;
  transition: all 0.3s ease-in-out;
  max-width: 100%;

  .TemplateContent {
    // We need to subtract the height of the action buttons
    height: calc(100% - 77px);
  }

  .ModalActions {
    position: absolute;
    bottom: 0;
    /* subtract the width of the TemplatesNav */
    width: calc(100% - var(--templates-nav-modal-width));
    border-top: 1px solid var(--border-color);
    background-color: var(--background-color);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    height: 77px;
  }

  .modal-header {
    padding: 0 !important;
  }

  .modal-body {
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
    border-radius: var(--square-border-radius-large);
  }

  .modal-content {
    height: 100%;
    max-height: 100%;
    position: fixed;
    width: 90%;
    max-height: calc(100vh - 3.5rem); // Subtract any additional padding or margin that might be present
    border-radius: 12px;
    border: none;
  }

  .TemplatePreviewModal-addPencilIcon {
    svg {
      fill: var(--link-color);
      width: 12px;
      height: 12px;
    }
  }
}
