main.NewProfilePage {
  section.celebration-banner {
    @include default-page-grid;

    background-color: var(--white-pure);
  }

  section.user-info-grid {
    @include default-page-grid;

    background-color: var(--white-pure);
    box-shadow: var(--card-box-shadow);
    padding-top: 35px;
    padding-bottom: 35px;
  }

  section.card-grid {
    @include default-page-grid;

    padding-top: 35px;
    padding-bottom: 40px;
    align-items: start;

    @include small-size {
      padding-top: 20px;
    }
  }

  &.PreboardingProfilePage {
    .ProfileAboutMe {
      grid-column: 4 / span 6;
      grid-row: 1 / span 3;
      align-self: start;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 0;

      @include small-size {
        grid-column: 1 / span 12;
        grid-row: 4 / span 1;
      }
    }
  }
}
