.TopNavbar {
  color: var(--top-nav-icon-color);
  height: var(--nav-height);
  box-sizing: border-box;

  @media print {
    display: none;
  }

  &.isHidden {
    z-index: -1;
    height: 60px;
    opacity: 0;
  }

  .Navbar-primary {
    height: calc(var(--nav-height));
    border-bottom: 1px solid var(--border-color);
    background-color: var(--top-nav-color);
    display: flex;
    align-items: center;
  }

  .Navbar-companyImage {
    img {
      height: var(--logo-height);
      width: var(--logo-width);
    }
  }

  .GlobalSearch {
    width: 560px;

    &.isFocused {
      width: 560px;
    }

    input {
      height: 30px;
    }

    @include up-to-large-size {
      width: 380px;

      &.isFocused {
        width: 380px;
      }
    }
  }

  .AskAiNavbarButton {
    height: 42px;

    &:hover {
      background: linear-gradient(258.12deg, var(--ask-ai-end-color) 10.82%, var(--ask-ai-start-color) 104.37%);
      color: var(--white-pure);

      .text-ai {
        background: none;
        -webkit-text-fill-color: var(--white-pure);
        color: var(--white-pure);
      }

      svg {
        path {
          fill: var(--white-pure);
        }
      }
    }
  }

  .Navbar-navItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: var(--square-border-radius-small);

    svg {
      path {
        fill: var(--top-nav-icon-color) !important;
      }
    }

    &:hover:not(:has(.Navbar-profileImage)) {
      cursor: pointer;
      background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);

      svg {
        path {
          fill: var(--top-nav-icon-active-color) !important;
        }
      }
    }

    &:hover:has(.Navbar-profileImage) {
      cursor: pointer;
      background-color: transparent;

      .Navbar-profileImage {
        border: 2px solid var(--top-nav-icon-active-color) !important;
      }
    }

    .PersonIcon {
      svg {
        width: 26px;
        height: 26px;
      }
    }

    &.isActive {
      svg {
        path {
          fill: var(--highlight-color) !important;
        }
      }
    }
  }

  .Navbar-profileImage {
    height: 32px !important;
    width: 32px !important;
    border-radius: var(--profile-image-border-radius);
  }

  .DropdownMenu-content {
    padding: 8px;
    background-color: var(--top-nav-color);

    .Navbar-dropdownMenuItem {
      // This width is enough to fit the "Company Update" link in one line in english,
      // for every other language, we will just let it break into multiple lines.
      width: 11em;
      display: block;
      color: var(--top-nav-icon-color);
      background-color: var(--top-nav-color);
      border-radius: var(--square-border-radius);
      font-weight: 500;

      &:hover {
        color: var(--top-nav-icon-active-color);
        background-color: var(--dynamic-color-primary-color-tint-90--highlight-color);
      }

      &.TryNewUiToggle {
        * {
          font-weight: 500;
          color: var(--top-nav-icon-color);
        }
      }

      &.TryNewUiToggle > div > div {
        width: 100%;
      }
    }
  }
}
