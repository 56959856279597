.IconButtonPopover {
  background-color: var(--text-color-primary);

  &.rightPopover {
    .arrow {
      &::after {
        border-right-color: var(--text-color-primary) !important;
      }
    }
  }

  &.bottomPopover {
    .arrow {
      &::after {
        border-bottom-color: var(--text-color-primary) !important;
      }
    }
  }

  &.topPopover {
    .arrow {
      &::after {
        border-top-color: var(--text-color-primary) !important;
      }
    }
  }
}

.IconButtonWithPopover {
  svg {
    height: 20px;
    width: 20px;

    fill: var(--gray);

    g, path {
      fill: var(--gray);
    }

    &:hover {
      fill: var(--highlight-color);

      g, path {
        fill: var(--highlight-color);
      }
    }
  }
}
