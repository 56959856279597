.PagesNav {
  .FocusedWorkspace {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(calc(-1 * var(--left-nav-width)));
    transition: transform 0.3s linear;
    will-change: transform;
    background-color: var(--left-navbar-background-color);
    z-index: $zindex-toolbar;

    &.useNewUi {
      background-color: var(--base-layout-sidebar-background-color);
      transform: none;
      left: calc(-1 * var(--pages-nav-width));
      transition: left 0.5s linear;

      &.isWorkspaceFocused {
        left: 0;
      }
    }

    @include up-to-medium-size {
      transform: translateX(calc(-1 * 100vw));
    }

    &.isWorkspaceFocused {
      transform: none;
    }

    header.WorkspacesNavHeader {
      width: 100%;
      display: flex;
      align-items: baseline;
      padding-top: 15px;
      padding-bottom: 8px;
      padding-left: 20px;
      margin-left: 0;

      .BackIcon {
        fill: var(--text-color-secondary);
        stroke: var(--text-color-secondary);
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }
    }

    .WorkspaceHeader {
      min-height: 32px;
      padding-left: 18px;

      .WorkspaceOwnerActions {
        display: flex;
        align-items: center;
        margin-right: 14px;

        .CreatePageButton {
          @include up-to-medium-size {
            display: none;
          }
        }
      }

      .EmojiOrPlaceholder {
        line-height: 20px;
        font-size: 20px;
        width: 20px;

        svg {
          padding-bottom: 2px;
        }
      }

      .PlusIcon {
        svg {
          width: 14px;
          height: 14px;
        }
      }

      .WorkspaceActionsDropdownMenu,
      .IconButtonWithPopover {
        width: 22px;
        height: 22px;
        border-radius: var(--square-border-radius);
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
        }
      }

      .WorkspaceName {
        color: var(--page-workspace-text-color);
        font-weight: 700;
        font-size: 14px;

        @include up-to-medium-size {
          font-size: 15px;
        }
      }

      .WorkspaceActionsDropdownMenu {
        .KebabMenu {
          width: 22px;
          height: 22px;
        }

        :hover {
          .KebabMenu {
            svg {
              path {
                fill: var(--highlight-color);
              }
            }
          }
        }
      }

      .KebabMenu {
        &:hover {
          border-radius: initial;
          background-color: initial;
        }

        svg {
          path {
            fill: var(--text-color-secondary);
          }
        }
      }
    }
  }
}

.CreatePagePopover {
  background-color: var(--btn-primary-color);

  .arrow::after {
    border-bottom-color: var(--btn-primary-color);
  }
}
