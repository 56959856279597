.TasksAdminTable {
  .TaskableDetailsButtonAndModal {
    justify-content: center;
  }

  tbody {
    tr {
      td:nth-child(2){
        width: 33%;
      }
    }
  }

  &.isCompletedTaskTabSelected {
    tbody {
      tr {
        td:nth-child(1){
          width: 33%;
        }
        td:nth-child(2){
          width: initial;
        }
      }
    }
  }
}

.TasksAdminTable.LoadingContainer--Children {
  z-index: $zindex-zero;
}
