.TriggerSelect {
  .DaysInput {
    width: 100px;
  }

  .DateTimePicker {
    width: 100px;
  }

  .react-datepicker-popper {
    z-index: $zindex-toolbar;
  }

  .react-datepicker {
    min-width: 120px;

    .react-datepicker__time-container {
      width: 100%;
    }
  }
}
