aside.ContentVariablesDropdown {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: var(--light-gray);

    @include up-to-medium-size {
      flex-direction: column;
      align-items: flex-start;
    }

    span.link {
      svg {
        width: 28px;
        height: 28px;
        fill: var(--highlight-color);
      }

      &:hover {
        svg {
          fill: var(--dynamic-color-link-text--highlight-color);
        }
      }
    }
  }

  dl {
    max-height: 230px;
    overflow-y: scroll;

    dt {
      flex: 1 0 50%;
      padding: 16px 24px;
      margin: 0;
      border-bottom: 1px solid var(--border-color);
      border-left: 1px solid var(--border-color);

      @include up-to-medium-size {
        flex: 0 0 100%;
        padding: 16px 24px 8px;
        border-bottom: none;
        border-right: 1px solid var(--border-color);
      }

      svg {
        width: 0.87rem;
        height: 0.87rem;
        fill: var(--text-color-primary);
      }
    }

    dd {
      flex: 3 0 50%;
      padding: 16px 24px;
      margin: 0;
      text-align: right;
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);

      @include up-to-medium-size {
        flex: 0 0 100%;
        padding: 8px 24px 16px;
        border-left: 1px solid var(--border-color);
        text-align: left;
      }
    }
  }
}
