.ManageEmployeeJourneysModal{
  .btn {
    min-width: unset;
  }

  .journeys-container {
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    max-height: 250px;
    overflow-y: auto;
    border-radius: 4px;

    .journey-container {
      border: 1px solid var(--lightest-gray);

      .JourneyProgressWithStatus {
        .ProgressBar {
          width: 300px;
        }

        @include up-to-medium-size {
          .BlueprintLink {
            width: 100% !important;
          }

          .StatusLabel {
            width: 100% !important;
          }

          .ProgressBar {
            display: none !important;
          }
        }
      }
    }
  }
}
