.PhotosSlider {
  --profile-slides-picture-height-large: 500px;
  --profile-slides-picture-width-large: 744px;
  --profile-slides-picture-height-large-mobile: 190px;
  --profile-slides-picture-width-large-mobile: 281px;

  --profile-slides-picture-height-small: 49px;
  --profile-slides-picture-width-small: 61px;

  .modal-body {
    padding: 0 3rem;
  }

  .modal-header button.close {
    font-size: 2rem;
    font-weight: 300;
  }

  img {
    object-fit: cover;
    border-radius: var(--profile-photos-border-radius);
  }

  .AddProfilePicture {
    min-height: var(--profile-slides-picture-height-small);
    min-width: var(--profile-slides-picture-width-small);
    border-radius: var(--profile-photos-border-radius);

    & > div {
      height: var(--profile-slides-picture-height-small);
      width: var(--profile-slides-picture-width-small);
    }
  }

  .slick-slider {
    .slick-slide:not(.slick-active.slick-current) {
      padding: 0 10px;
    }

    .slick-current {
      padding: 0 2em;
    }

    .slick-dots {
      .DisplayGridLayout {
        display: grid !important;
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 3px;
      }
    }

    .slick-dots .slick-active {
      img {
        opacity: 0.7;
        border: solid 2px var(--highlight-color);
      }
    }

    .slick-track {
      img {
        height: var(--profile-slides-picture-height-large) !important;
        width: var(--profile-slides-picture-width-large) !important;
      }
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      .Chevron {
        display: flex !important;
        height: 30px;
        width: 30px;
      }

      svg {
        & * {
          fill: var(--text-color-secondary) !important;
          stroke: var(--text-color-secondary) !important;
        }
      }
    }

    .slick-prev {
      transform: none;
      left: 0;
    }

    .slick-next {
      transform: none;
      right: 0;
    }

    .slick-dots {
      display: flex;
      justify-content: center;
      bottom: unset;
      margin-top: 50px;
      position: relative;

      li {
        width: fit-content;
        height: fit-content;
      }

      img {
        height: var(--profile-slides-picture-height-small);
        width: var(--profile-slides-picture-width-small);
      }
    }
  }

  @include small-size {
    .modal-body {
      padding: 0;
    }

    .slick-slider {
      .slick-dots {
        .DotImageList {
          display: grid !important;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 7px;
        }
      }

      .slick-track {
        img {
          height: var(--profile-slides-picture-height-large-mobile) !important;
          width: var(--profile-slides-picture-width-large-mobile) !important;
        }
      }

      .slick-prev,
      .slick-next {
        top: 20%;
      }
    }
  }
}
