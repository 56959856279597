.AcknowledgementRemindersModal {
  .PendingUsers {
    background-color: rgba(14, 0, 60, 0.04);
    border-radius: var(--square-border-radius);
  }
}

.AcknowledgementRemindersButton {
  svg {
    width: 18px;
    height: 18px;
  }
}
