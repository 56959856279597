.IntroductionForm {
  .content-rich-view {
    cursor: not-allowed;
    border: 1px solid var(--border-color);
    background-color: var(--lightest-gray);
    border-radius: 4px;
    outline: none;
    padding: 1em;

    * {
      opacity: 0.8;
    }
  }

  .IntroductionsHelpCard {
    background: var(--journey-step-form-help-card-background-color);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: var(--square-border-radius-large);

    .IntroductionImage {
      z-index: $zindex-base;
    }
  }

  .CalendarMeetingDetailsSection {
    .max-meetings-per-day-input,
    .minutes-input {
      width: 100px;
    }

    .react-datepicker {
      min-width: 120px;

      .react-datepicker__time-container {
        width: 100%;
      }
    }

    .react-datepicker-popper {
      z-index: $zindex-dropdown;
    }

    .automatically-schedule-radio-container {
      input {
        width: 38px;
        height: auto !important;
      }
    }
  }
}
