.GroupsPage {
  .GroupsTree {
    .ItemArrow {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex: none;

      .ChevronDownIcon {
        svg {
          width: 16px;
          height: 16px;
          transform: rotate(-90deg);
        }
      }

      &.isExpanded {
        .ChevronDownIcon {
          svg {
            transform: none;
          }
        }
      }
    }

    ul {
      list-style: none;
      padding-left: 0px;

      li {
        padding: 2px 0;

        ul {
          padding-left: 24px;
        }
      }
    }
  }
}
