.AiOverview {
  > .card-body {
    padding: 0;
  }

  .AiOverviewConfidenceScore {
    &--low {
      color: var(--error-color);
    }

    &--medium {
      color: var(--warning-color);
    }

    &--high {
      color: var(--success-color);
    }
  }

  .TiptapView {
    // sup tags are used for mentions, we are currently not displaying that to end users
    // sub tag returns the confidence score, we don't want to show it in the text
    sup, sub {
      display: none;
    }
  }

  hr {
    border-bottom-color: var(--medium-separator-color);
  }

  .FollowUpQuestionsCommentIcon {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--highlight-color);

      path {
        fill: var(--highlight-color);
      }
    }
  }
}
