.AiBlockComponent {
  position: relative;
  width: calc(100% + 10px);
  padding-left: 5px;
  padding-right: 5px;
  margin-left: -5px;
}

.AiBlockComponent:hover {
  outline: 1px solid var(--highlight-color);

  .HoverTopMenu {
    opacity: 1;
    pointer-events: all;
  }
}
