.ManuallyAddedEmployeesSelector {
  .subtitle {
    font-size: 15px;
    font-weight: 500;
  }

  .select-disclaimer {
    font-size: 11px;
  }

  .toggles-1 {
    width: 25%;
  }

  .toggles-2 {
    width: 50%;
  }

  .toggles-3 {
    width: 75%;
  }

  .toggles-4 {
    width: 100%;
  }

  .toggle {
    min-height: 0 !important;
  }
}
