div.OrgChartPage {
  nav.ProfilePreview {
    width: var(--org-chart-panel-width);
    left: calc(-1 * var(--org-chart-panel-width));
    transition: left 0.5s ease-in-out;
    overflow: auto;
    padding-bottom: 1.5rem;
    background: var(--white-pure);
    padding: 20px;
    border-right: 1px solid var(--border-color);

    .ContactInfo {
      svg {
        width: 16px;
        height: 16px;
        fill: var(--text-color-secondary);
      }

      .SlackLogo {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    &.isProfilePreviewOpen {
      left: 0;
    }

    *:focus {
      // remove default blue outline when any child has focus
      outline: none;
    }

    @include small-size {
      width: 0;
      padding: 0;
      left: -100vw;
      transition: all 0.5s ease-in-out;
      border-right: 0;

      &.isProfilePreviewOpen {
        padding: 20px;
        left: 0;
        width: 100vw;
        transition: all 0.5s ease-in-out;
      }
    }

    @media print {
      display: none !important;
    }

    img.EmployeeThumbnailPhoto {
      width: 100px !important;
      height: 100px !important;
      margin: 0 0 20px 0;
    }

    .CloseIcon {
      stroke: var(--dynamic-color-close-icon--border-color);
      fill: var(--dynamic-color-close-icon--border-color);
    }
  }
}
