.TogglePageWidth {
  &:hover {
    cursor: pointer;
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color) !important;

    span {
      color: var(--dynamic-color-primary-color-darker--highlight-color);

      svg {
        fill: var(--dynamic-color-primary-color-darker--highlight-color);
        color: var(--dynamic-color-primary-color-darker--highlight-color);
      }
    }
  }

  span {
    color: var(--gray);

    svg {
      fill: var(--gray);
    }
  }
}
