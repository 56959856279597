.top-0    { top:    0; }
.right-0  { right:  0; }
.bottom-0 { bottom: 0; }
.left-0   { left:   0; }

.justify-content-evenly {
  justify-content: space-evenly;
}

.include-default-grid {
  @include default-page-grid;
}

.grid-span-12 {
  grid-column: span 12;
}

.grid-span-6 {
  grid-column: span 6;
}

.grid-span-4 {
  grid-column: span 4;
}

.grid-span-3 {
  grid-column: span 3;
}

.z-index-0 {
  z-index: $zindex-zero;
}

.z-index-1 {
  z-index: $zindex-one;
}

.z-index-2 {
  z-index: $zindex-two;
}
