.EventPage {
  .LockIcon {
    @include small-size {
      position: relative;
      left: -1px;
    }
  }

  .VideoIcon {
    @include small-size {
      margin-right: 0.7rem !important;
      position: relative;
      left: 1px;
    }
  }

  .EventDetails {
    &.hasCoverImage {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}

.DraftEventBanner {
  background: var(--draft-event-banner-color);
}
