.LinksWidget {
  container-type: inline-size;

  .LinksGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 1rem;
  }

  @container (min-width: 518px) {
    .LinksGrid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @container (min-width: 661px) {
    .LinksGrid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @container (min-width: 870px) {
    .LinksGrid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .AddLinkButton {
    svg {
      fill: var(--text-color-secondary);
      width: 16px;
      height: 16px;
    }

    &:hover {
      svg {
        fill: var(--link-color);
      }
    }
  }

  .LinkListItem {
    .PencilIcon, .XIcon {
      display: none;

      svg {
        width: 20px;
        height: 20px;
        fill: var(--text-color-secondary);

        &:hover {
          fill: var(--link-color);
        }
      }
    }

    &:hover {
      .PencilIcon, .XIcon {
        display: block;
      }
    }
  }
}
