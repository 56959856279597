div.MyTasksHeaderTile {
  width: 100%;
  background-color: var(--task-header-tile-background-color);
  border-radius: var(--square-border-radius);
  min-height: 223px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @include small-size {
    min-height: 160px;
  }

  h4 {
    color: var(--white-pure);
    margin-bottom: 20px;
    text-align: center;

    @include small-size {
      padding: 0 10px;
    }
  }

  span.header-image {
    width: 100%;
    display: flex;
    justify-content: center;

    svg {
      width: 100%;
      max-width: 840px;
    }
  }
}
