.EditableContent {
  &:hover {
    .EditableContent-editLink {
      display: inline-block;
    }
  }
}

.EditableContent-show {
  >div {
    display: inline-block;
  }
}

.EditableContent-editLink {
  display: none;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1rem;
  }
}
