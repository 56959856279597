.ScanningChannelsBottomBanner {
  position: sticky;
  bottom: 0px;
  left: calc(100vw - 480px);
  width: 420px;

  img {
    width: 75px;
    height: 75px;
  }

  .content {
    padding-bottom: 75px !important;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
