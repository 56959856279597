.SmartPdfModal {
  .close {
    padding: 6px 10px !important;
  }

  .TiptapEditorContentWrapper {
    margin: 0 auto;
    // 65px from the headers and 60px from the toolbar
    height: calc(100vh - (65px + 60px));
    width: 756px;
    padding: 24px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;

  }
}
