.AuditLogsPage {
  .ClearyReactSelect {
    min-width: 200px;
  }

  .DateTimePicker {
    min-width: 250px;
  }

  table {
    table-layout: auto;
    width: 100%;

    tbody {
      tr {
        td:first-child{
          width: 15%;
        }

        td:nth-child(2) {
          width: 20%;
        }

        td:nth-child(3) {
          width: 5%;
        }

        td:nth-child(4) {
          width: 5%;
        }

        td:last-child {
          width: 55%;
        }
      }
    }
  }
}

