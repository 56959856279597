.CelebrationCard {
  &.blockClicks {
    pointer-events: none;
  }

  .Card-dropdownMenu {
    z-index: $zindex-dropdown;
  }
}

.CelebrationCard-hero {
  border-top-right-radius: var(--square-border-radius);
  border-top-left-radius: var(--square-border-radius);
  background-color: var(--highlight-color);
  height: 215px;

  img {
    height: 215px;
    object-fit: cover;

    @include small-size {
      height: 130px;

      svg {
        height: 120px;
      }
    }
  }

  @include small-size {
    height: 130px;

    svg {
      height: 120px;
    }
  }

  &.confetti-background:before {
    opacity: 1;
  }

  .isvg {
    z-index: $zindex-one;
  }

  &.birthday {
    background-color: var(--birthday-card-background-color);
  }

  &.work_anniversary {
    background-color: var(--work-anniversary-background-color);

    svg {
      width: 324px;
      height: 205px;

      @include small-size {
        height: 120px;
      }
    }
  }

  &.new_employee_welcome {
    background-color: var(--new-employee-welcome-color);
  }

  &.upcoming_work_anniversary {
    background-color: var(--upcoming-work-anniversary-color);
  }

  &.upcoming_birthday {
    background-color: var(--upcoming-birthday-color);
  }

  &.upcoming_new_employee_welcome {
    background-color: var(--upcoming-new-employee-welcome-color);
  }

  .balloon {
    position: absolute;

    &.balloon-1 {
      left: 10%;
    }

    &.balloon-2 {
      left: 20%;
    }

    &.balloon-3 {
      right: 20%;
    }

    &.balloon-4 {
      right: 10%;
    }
  }
}
