main.MyPostsListPage {
  @include default-page-grid;

  header {
    @extend .mt-4, .mb-4;

    grid-column: span 12;
    display: flex;
    justify-content: space-between;
  }

  .ArticlesTable, .ArticleStatsTable {
    grid-column: span 12;
  }
}
