.EventCard {
  /*
  * Set height to the equivalent of 2 lines so that card height
  * is always the same for both 1 and 2 lines of title text.
  */
  h5 {
    height: 2.2em; // `em` instead of `rem` because line-height is relative to h5's font-size
    line-height: 1.1; // Unitless: always relative to the container's font-size
  }

  .EventCardBody {
    min-height: 250px; // set min height to account for draft events without much content
  }

  .EventParticipants > div {
    font-weight: normal !important;
    color: var(--text-color-secondary) !important;
  }

  .EventTimeStatus {
    & p:first-of-type {
      color: var(--text-color-secondary);
    }

    & p:last-of-type {
      font-weight: 500;
      @extend .text-small;
      color: var(--text-color-primary) !important;
    }
  }

  @include small-size {
    .EventTimeStatus p:last-of-type {
      font-size: 1rem !important;
    }

    .EventParticipants .text-small {
      display: block !important;
    }
  }

  @include max-media-width($bootstrap-lg) {
    &.d-inline-block {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 1rem;
    }

    &.isFirstEventInRow {
      margin-right: 20px !important;
    }
  }
}

// Cannot use @include large-screen mixin in .EventCard block above
// because it would not be specific enought for it to override the preceding media queries.
@media (min-width: $bootstrap-lg) {
  /**
 * From designs: 30px (20 + 10) gutter
 * 20px -> 1.429rem, 10px -> 0.714rem,
 * Max 3 cards per row, consistent width across all rows
 * Cards in middle have left/right margin of 1.429rem
 * First cards only have right margin of 0.714rem
 * Last cards only have left margin 0.714rem
 * Workin with 15px on all sides instead of 10/20 doesn't seem to work.
 * __________       __________       __________
 * |         |      |         |      |         |
 * |  Card   | 30px |  Card   | 30px |  Card   |
 * |_________|      |_________|      |_________|
 * __________       __________
 * |         |      |         |
 * |  Card   | 30px |  Card   |
 * |_________|      |_________|
 */
  div.EventCard.d-inline-block {
    width: calc(33.333333% - 1.429rem) !important;
    margin: 0 1.429rem calc(1.429rem + 0.714rem) !important;

    &.isFirstEventInRow {
      margin-left: 0 !important;
      margin-right: 0.714rem !important;
    }

    &.isLastEventInRow {
      margin-left: 0.714rem !important;
      margin-right: 0 !important;
    }
  }
}
