.LinkListItem {
  &:hover {
    border-radius: var(--square-border-radius);
    background-color: var(--dynamic-color-link-color-lightest--link-color);

    span {
      color: var(--link-color);
    }
  }
}
