.WelcomeCard {
  &.confetti-background:before {
    opacity: 0.35;
  }

  .card-body {
    position: relative;
  }

  .modules-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    .ModuleLink {
      grid-column: span 2;

      .module-link-container {
        border: 1px solid var(--border-color);
        background-color: var(--white-pure);

        &:hover {
          background-color: var(--welcome-card-module-hover-background-color);
          color: var(--hightlight-color);
          border-color: var(--skill-tag-hover-background-color);
        }

        svg {
          fill: var(--text-color-primary);
          width: 20px;
          height: 20px;
          position: relative;
          z-index: $zindex-base;
          margin-left: 1px;

          path {
            fill: var(--text-color-primary);
          }
        }

        .CalendarIcon {
          width: 15px;
          height: 15px;
        }
      }
    }

    :last-child:nth-child(odd){
      grid-column-start: 2;
      grid-column-end: 4;
    }

    @include up-to-medium-size {
      .ModuleLink {
        grid-column: span 4;
      }

      :last-child:nth-child(odd){
        grid-column: span 4;
      }
    }
  }
}
