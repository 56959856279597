.PersonCard {
  img {
    height: 60px;
    width: 60px;
    border-radius: 270px;
  }

  .card-body {
    padding: 10px 12px 10px 12px;
  }

  .PersonInfo {
    padding-left: 12px;
  }
}
