.OffcanvasBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.Offcanvas {
  border-radius: 24px 24px 0px 0px;
  padding-top: 17px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: #fff;
  transition: transform 0.2s ease-out;
  padding-bottom: 5rem;

  &.before-opening, &.closing {
    transform: translateY(100%);
  }

  &.opening, &.active {
    transform: translateY(0);
  }

  .OffcanvasHandler {
    border-radius: 24px;
    background: var(--border-color);
    height: 8px;
    width: 33%;
    margin: 0 auto;
  }
}
