.AudienceSelectorModal {
  &.publish {
    .publish-header {
      background-color: var(--btn-primary-color);
    }
    
    .modal-header {
      background-color: var(--btn-primary-color);

      .close {
        color: var(--white-pure);
        opacity: 1;
        font-size: 2rem;
        font-weight: 300;
      }
    }
  }

  &.shared {
    .modal-header {
      .close {
        color: var(--black-pure);
        opacity: 1;
        font-size: 2rem;
        font-weight: 300;
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  header {
    h2,
    span {
      color: var(--white-pure);
    }
  }

  footer,
  main {
    background-color: var(--white-pure);
  }
}
