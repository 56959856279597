.ShoutoutSuggestionsWidget {
  container-type: inline-size;

  .ShoutoutSuggestionsWidget-Title {
    padding: 24px;

    .XIcon {
      svg {
        fill: var(--white-pure);
        height: 24px;
        width: 24px;
      }
    }
  }

  .slick-slider {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .slick-list {
    flex-grow: 1;
  }

  .slick-track {
    height: 100%;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  .slick-slide > div {
    height: 100%;
  }

  ul.pagination {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  @container (max-width: 400px) {
    .ShoutoutSuggestionListItem {
      .card-body {
        padding: 24px 11px;
      }
    }

    .MegaphoneIcon {
      top: 15px;
      right: -55px;

      svg {
        height: 80px;
      }
    }
  }

  .ShoutoutVerticalLayout {
    padding: 24px 16px;

    .SendShoutoutButton {
      height: 33px;
      width: 33px;
      border-radius: var(--profile-image-border-radius);
      box-shadow: var(--card-box-shadow);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;

      &:hover {
        border-color: var(--highlight-color);
      }

      svg {
        fill: var(--btn-primary-color);
      }
    }
  }
}

.SendShoutoutButtonPopover {
  background-color: var(--black-pure);
  z-index: $zindex-dropdown;
  width: 120px;

  .arrow {
    &::after {
      border-bottom-color: var(--black-pure) !important;
    }
  }
}
