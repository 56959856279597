.GreetingCardBannerImage {
  .EditableContent {
    padding: 10px;
    border: 2px solid var(--btn-primary-color);
    border-radius: var(--square-border-radius);
  }

  .EditableContent-Display {
    background-color: initial !important;

    &:hover {
      border: 2px solid var(--btn-primary-color);
      border-radius: var(--square-border-radius);
    }
  }
}

