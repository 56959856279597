main.BadgePage {
  section.BadgeInformation {
    @include default-page-grid;
    margin-top: 20px;

    header {
      grid-column: span 12;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }

    .AwardInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      grid-column: span 12;
      margin-top: 40px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--border-color);

      @include small-size {
        flex-direction: column;
        gap: 10px;
      }

      span {
        color: var(--link-color)
      }

      .disabled {
        pointer-events: none;
        color: var(--light-mid-gray);

        span {
          color: var(--light-mid-gray);
        }
      }

      .RecipientsAndAward {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        .AwardLink {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
        }
      }
    }

    .NoAwardees {
      border-bottom: none;
    }

    .AwardBadge {
      grid-column: span 12;
      margin-bottom: 20px;

      .AwardOptions {
        display: flex;
        flex-direction: row;
        margin-bottom: 7px;

        label {
          margin-bottom: 0px;
        }

        .UploadCSV {
          color: var(--link-color);
          font-weight: 600;
        }

        svg {
          height: 20px;
          margin-left: 15px;
          fill: var(--link-color);
        }
      }

      .SelectRecipients {
        display: flex;
        flex-direction: row;
        gap: 20px;

        @include up-to-medium-size {
          flex-direction: column;
        }
      }

      .EmployeeSearch {
        width: 50%;

        @include up-to-medium-size {
          width: 100%;
        }
      }

      .AwardButton {
        width: 90px;
      }
    }
  }

  .BadgeAwardees {
    @include default-page-grid;
    grid-row-gap: 10px;
    margin-bottom: 20px;

    .AwardeeCard {
      grid-column: span 4;

      @include up-to-large-size {
        grid-column: span 6;
      }

      @include small-size {
        grid-column: span 12;
      }
    }

    .EmptyState {
      display: flex;
      flex-direction: column;
      grid-column: span 12;
      text-align: center;
      align-items: center;
      color: var(--text-color-secondary);
      gap: 10px;

      span {
        max-width: 400px;
      }
    }
  }
}
