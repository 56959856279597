.AddCustomStepButton {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent !important;

  &:hover {
    background-color: var(--btn-primary-color) !important;
    color: var(--white-pure) !important;
  }
}

.AddCustomStepPopover {
  background-color: var(--black-pure);
  z-index: $zindex-dropdown;
  width: 130px;

  .arrow {
    &::after {
      border-bottom-color: var(--black-pure) !important;
    }
  }
}

.AddCustomStepHeader {
  .header {
    background-color: var(--journeys-table-with-steps-header-background-color);
    height: 32px;
  }

  .title {
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
  }

  .empty-container {
    height: 35px;
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
  }
}
