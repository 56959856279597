.ContextMenu {
  background-color: var(--white-pure);
  box-shadow: var(--card-box-shadow);
  border-radius: var(--square-border-radius);
  width: 240px;

  .ContextMenuItem {
    background-color: var(--white-pure);
    outline: none;
    border: none;
    position: relative;
    cursor: pointer;

    &.isActive {
      background-color: var(--btn-primary-color);
      color: var(--white-pure);

      &:hover {
        background-color: var(--dynamic-color-button-primary-hover--btn-primary-color);
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
      color: var(--text-color-muted);
    }

    .ContextMenuItemIcon {
      width: 16px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--text-color-primary);
      }

      &.disabled {
        svg {
          fill: var(--text-color-muted);
        }
      }
    }

    .ArrowRight {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .ContextMenuSubmenu {
      display: none;
      position: absolute;
      background-color: var(--white-pure);
      box-shadow: var(--card-box-shadow);
      border-radius: var(--square-border-radius);
      width: 240px;
      top: -14px;
      padding: 14px 0px;

      &--left {
        display: block;
        left: -241px;
      }

      &--right {
        display: block;
        left: 241px;
      }
    }

    &:hover {
      background-color: var(--dynamic-color-card-dropdown-item--card-background-color);
    }
  }
}
