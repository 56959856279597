.CelebrationEditorPageSidebar {
  .Sidebar {
    transform: translateX(100%);
    transition: transform 0.3s linear;
  }

  &.slide-in {
    .Sidebar {
      transform: translateX(0);
    }
  }

  .template-image:hover {
    outline: 3px solid $highlight-color;
  }

  .Sidebar--floating {
    margin-top: 50px;
  }
}
