.DepartmentEditorSidebar {
  .Sidebar {
    max-width: 640px;
    width: 640px;
  }

  .EmojiOrPlaceholder {
    svg {
      width: 38px;
      height: 38px;
    }
  }
}
