.Notification {
  &:hover {
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
  }
}

.Notification--unread {
  background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
}

.Notification-listItemIcon {
  img {
    width: 2em;
    height: 2em;
  }

  .isvg.loaded {
    display: block;
    width: 2em;
    height: 2em;
  }

  svg {
    width: 2em;
    height: 2em;

    circle,
    path,
    g {
      stroke: var(--text-color-secondary);
      fill: var(--text-color-secondary);
    }
  }

  .KudosIcon {
    path {
      fill: var(--text-color-secondary);
    }
  }
}

