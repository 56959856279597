header.StepTemplateHeader {
  div.BackToJourneyBlueprintButton {
    .BackIcon {
      fill: var(--link-color);
      stroke: var(--link-color);
      margin-bottom: 2px;
    }
  }

  .draft-button {
    margin-right: 10px;
  }
}
