.AwardedBadgeCard {
  .AwardedBadgeCard-hero {
    border-top-right-radius: var(--square-border-radius);
    border-top-left-radius: var(--square-border-radius);
    background-color: var(--awarded-badge-card-hero-color);
    height: 10em;

    .AwardedBadgeCard-image {
      z-index: $zindex-one;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: var(--white-pure);

      img {
        z-index: $zindex-one;
        height: 70px;
        width: 70px;
      }
    }

    &.confetti-background:before {
      opacity: 1;
    }
  }

  .AwardedBadgeCard-profileImg {
    box-sizing: border-box;
    width: 3.2em;
    height: 3.2em;
    overflow: hidden;
    line-height: 0;
    cursor: pointer;
  }


  .AwardedBadgeCard-userName {
    color: var(--highlight-color);
    font-weight: 500;
  }

  .Card-dropdownMenu {
    z-index: $zindex-dropdown;
  }
}
