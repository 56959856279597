.ContextMenuColorPickerPreview {
  border: 1px solid var(--border-color);
}

.ContextMenuColorPicker {
  height: 100%;

  .BlockColorPicker {
    left: -93px;

    &--top {
      bottom: 15px;
    }
  }
}
