div.CheckboxToggleButtonGroup {
  &.isDisabled {
    .btn-group-toggle {
      cursor: not-allowed;
    }

    label.btn-toggle {
      pointer-events: none;
    }

    .btn-toggle.active {
      opacity: 0.5;
    }
  }

  &.isCheckbox {
    div.btn-group.btn-group-toggle {
      label.btn.btn-toggle {
        border: 0.5px solid var(--skill-tag-border-color);
        border-radius: var(--square-border-radius);
        background-color: var(--skill-tag-background-color);
        padding: 5px 14px;
        color: var(--skill-tag-text-color);
        margin: 5px 10px 5px 0;

        &.active {
          background-color: var(--skill-tag-hover-background-color);
          color: var(--skill-tag-hover-text-color);
        }
      }
    }
  }

  &.isRadio {
    label {
      flex: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.focus {
        box-shadow: none;
      }
    }

    .btn-group.btn-group-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5rem 0;
    }

    .btn-toggle {
      border: solid 1px var(--border-color) !important;
      color: var(--text-color-secondary);
    }

    .btn-toggle.active {
      background-color: var(--highlight-color) !important;
      border: solid 1px var(--highlight-color) !important;
      color: var(--white);
    }
  }
}
