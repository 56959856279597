section.ProfileCoreFields {
  grid-column: 4 / span 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid var(--border-color);

  @include small-size {
    grid-column: 1 / span 12;
    grid-row: 2;
    align-items: center;
    border-right: none;
    padding-top: 1rem;

    .WorkerTypeAndPronouns {
      & > *:last-child {
        padding-right: 0;
      }
    }
  }

  span.WorkerTypeAndPronouns {
    min-height: 38px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include small-size {
      justify-content: center;
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
    fill: var(--text-color-secondary);
    vertical-align: unset;
    margin-top: 4px;
  }
}
