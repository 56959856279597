.TiptapToolbarSelect {
  background-color: transparent;
  border-radius: var(--square-border-radius);
  border: none;

  &:hover {
    background-color: #ebebeb;
  }

  &.disableSelectedOptionHighlighting {
    .ClearyReactSelect__value-container {
      display: flex;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .ClearyReactSelect__control {
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: var(--editor-toolbar-hover-background-color);
    }
  }

  .ClearyReactSelect__single-value {
    font-size: 14px;
  }

  .ClearyReactSelect__dropdown-indicator {
    padding: 0;
  }

  .ClearyReactSelect__value-container {
    padding: 2px 0;
  }

  .ClearyReactSelect__menu {
    width: max-content;
  }


  .ClearyReactSelect__option--is-selected {
    svg {
      fill: var(--white-pure)
    }
  }
}
