@keyframes visible {
  from {
    opacity: 0;
    filter: blur(1.5rem);
    to {
      opacity: 1;
      filter: blur(0);
    }
  }
}

.ProgressiveImage {
  border-top-left-radius: var(--square-border-radius);
  border-top-right-radius: var(--square-border-radius);

  .Placeholder {
    visibility: hidden;
    z-index: $zindex-zero;
    border-top-left-radius: var(--square-border-radius);
    border-top-right-radius: var(--square-border-radius);
    background: var(--dynamic-color-secondary-color-lightest--secondary-gray);
  }

  &.isLoading {
    .Placeholder {
      visibility: visible;
      z-index: $zindex-base;
    }
  }

  &.errored {
    min-height: 100px;
  }

  img {
    animation: visible 0.5s;
    animation-timing-function: ease-in;
  }
}
