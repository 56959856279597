.NewHireCarouselCard {
  height: 275px;
  overflow-y: auto;

  .NewHireCarouselCardPicture {
    --profile-picture-dimension: 120px !important;
    --profile-picture-dimension-mobile: 120px !important;
  }

  // this is needed to override react-slick css in the carousel
  .EmployeeThumbnailPhoto {
    display: inline !important;
  }

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: var(--link-color);
    }
  }
}
