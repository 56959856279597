section.ProfileTenure {
  @extend .ProfileCard;

  grid-column: 1 / span 3;

  @include small-size {
    grid-row: 3;
    grid-column: 1 / span 12;
    margin-top: 14px;
  }
}
