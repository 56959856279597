.InsertTableDropdownButton {
  .DropdownMenu-content {
    width: 215px;
  }
}

.TableDropdownContent {
  .insertTable {
    border-collapse: collapse;
  }
  
  .insertTable td {
    width: 20px;
    height: 20px;
    border: 1px solid var(--border-color);
    cursor: pointer;
  }
  
  .insertTable td:hover, .insertTable td.selected {
    background-color: var(--highlight-color);
  }
}
