.TiptapImageStyleButton {
  .DropdownMenu-content {
    width: 100px;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .isActive {
      background-color: var(--highlight-color);
      color: var(--white-pure)
    }
  }
}
