
#g-signin2 {
  div {
    color: white !important;
  }
}

.login-container {
  padding-top: 180px;
  padding-bottom: 70px;

  @include small-size {
    padding-top: 130px;
    padding-bottom: 70px;
  }
}

.LoginPage {
  min-height: 100vh;

  &.withBackground {
    background: url('/public/images/illustrations/login-arrow-pink.svg') no-repeat 0 0;
    background-size: auto 100%;

    @include small-size {
      background: url('/public/images/illustrations/login-arrow-pink-mobile.svg') no-repeat 0 100%;
      background-size: 100% auto;
    }
  }

  .cleary-logo-above-form {
    svg {
      width: 163px;
      height: auto;
      margin-top: 60px;
      margin-bottom: -100px;

      @include small-size {
        margin-top: 0px;
      }
    }
  }

  .company-image {
    position: absolute;
    top: 32px;
    left: 32px;
    height: 3rem;

    @include small-size {
      position: initial;
      margin-bottom: -40px;
      margin-top: 50px;
    }
  }

  .powered-by {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;

    @include small-size {
      position: initial;
      margin-top: 40px;
    }
  }

  .powered-logo {
    svg {
      width: 80px;
    }
  }
}


.login-avatar {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  margin-left: -56px;
  margin-top: -56px;
  border: 10px solid var(--background-color);
  width: 112px;
  height: 112px;
  border-radius: 50%;
}

.login-form {
  .email-icon {
    width: 20px;
    display: block;
    position: absolute;
    left: 10px;
    top: 5px;

    svg {
      fill: $cleary-purple;
    }
  }

  .email-input {
    padding-left: 36px;
  }
}

.login-card {
  max-width: 586px;
  padding: 70px;
  --btn-primary-color: $cleary-purple;

  @include small-size {
    padding: 20px;
    padding-top: 70px;
  }
}
