.SignWithAudio {
  border: 1px dashed var(--border-color);
  padding: 30px;

  button {
    &:active, &:focus {
      border: none;
      background: none;
      outline: none;
    }
  }

  .audio {
    display: flex;
    align-items: center;
    gap: 8px;

    audio {
      flex-grow: 1;
    }

    .delete {
      background: var(--lighter-background-color);
      padding: 7px;
      border-radius: 100%;
      border: none;

      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    gap: 12px;
    background: var(--lighter-background-color);
    padding: 8px 32px;
    border-radius: 24px;

    &.not-recording {
      justify-content: space-between;
    }

    .record {
      background: #D2191A;
      border: none;
      color: var(--white-pure);
      border-radius: 100%;
      padding: 7px;

      svg {
        color: var(--white-pure);
      }

      .stop {
        background: var(--white-pure);
        border-radius: 6px;
        width: 24px;
        height: 24px;
      }
    }

    canvas {
      flex-grow: 1;
    }

    .delete, .restart {
      background: none;
      border: none;
      color: var(----text-color-secondary);
      padding: 0px;
    }
  }
}
