.ShoutoutCardHomeFeed {
  &.blockClicks {
    pointer-events: none;
  }

  .ShoutoutCard-hero {
    border-top-right-radius: var(--square-border-radius);
    border-top-left-radius: var(--square-border-radius);
    background-color: var(--shoutout-card-background-color);
    height: 215px;

    .isvg {
      z-index: $zindex-one;
    }

    svg {
      width: 265px;
      height: 203px;
    }

    @include small-size {
      height: 130px;

      svg {
        height: 120px;
      }
    }
  }

  .Card-dropdownMenu {
    z-index: $zindex-dropdown;
  }

  &.confetti-background:before {
    opacity: 1;
  }
}
