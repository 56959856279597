.TiptapToolbarSelect {
  &.TextAlignSelect {
    width: 48px;

    &.disabled {
      svg {
        fill: var(--editor-toolbar-disabled-icon-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .ClearyReactSelect__menu {
      width: 48px;
    }

    .ClearyReactSelect__dropdown-indicator {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
