div.ManagedGroupListPage {
  .CreateNewButton {
    width: fit-content;
  }

  .InfoIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--btn-primary-color);
    }
  }
}
