.FeaturedEventWidget {
  container-type: inline-size;

  .TopQuestionContent {
    background-color: rgba(14, 0, 60, 0.04);
  }

  .CoverImageWidget.hasCoverImage {
    height: 143px;
    min-height: unset;
    max-height: unset;
  }

  @container (max-width: 310px) {
    .CoverImageWidget.hasCoverImage {
      height: 110px;
    }
  }

  .LockIcon {
    svg {
      fill: var(--text-color-secondary);
      height: 24px !important;
      width: 24px !important;
    }
  }

  .QuestionVotingSectionMobile {
    height: unset;
    border: none;
    margin-top: 0;

    h5 {
      @extend .text-small, .text-secondary;
    }
  }

  .Cleary-chevron {
    path,
    g {
      stroke: var(--text-color-primary);
      fill: var(--text-color-primary);
    }

    &.Cleary-chevron--hover {
      @include hover-device {
        &:hover {
          path,
          g {
            stroke: var(--highlight-color);
            fill: var(--highlight-color);
          }
        }
      }
    }

    &.Cleary-chevron--active {
      path,
      g {
        stroke: var(--highlight-color);
        fill: var(--highlight-color);
      }
    }
  }

  .Cleary-chevron-up {
    padding-left: 0 !important;
  }

  .PeopleWithGiantCalendarImage {
    overflow: hidden;
    height: 165px;
    max-width: 100%;

    svg {
      height: 165px;
      max-width: 100%;
    }
  }

  .CommentsWidget--Toggle .CommentIcon {
    svg {
      fill: var(--text-color-primary);
      width: 0.857rem;
    }
  }
}
