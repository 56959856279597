.JourneyBlueprintSettingsModal {
  .DesktopSectionHeaders {
    min-width: 160px;
  }

  .GeneralJourneyBlueprintSettings {
    .EmployeeSearch {
      max-width: 350px;
    }
  }
}
