.gpr-text-trending-category {
  color: white;
}

.PoweredByTenorSVG {
  svg {
    width: 110px;
    height: auto;
    float: right;
  }
}

.TenorGifImg {
  max-height: 300px;
  max-width: 100%;
  display: block;
}

.GifPickerReact {
  --gpr-picker-border-radius: var(--square-border-radius) !important;
  --gpr-search-input-border-radius: var(--square-border-radius) !important;
  --gpr-search-input-text-color: var(--text-color-primary) !important;
  --gpr-picker-border-color: var(--border-color) !important;
  --gpr-search-border-color-active: var(--highlight-color) !important;
}
