.TiptapToolbarSeparator {
  height: 100%;
  width: 9px;
  padding: 8px 4px;
  flex-shrink: 0;
  flex-grow: 0;
  display: block;

  .TiptapToolbarSeparatorLine {
    height: 100%;
    width: 1px;
    background: var(--editor-toolbar-border-color);
    display: block;
  }
}
