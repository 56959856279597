div.PillTabSelector {
  max-width: 100%;

  @include small-size {
    overflow-x: scroll;
  }

  .Tab.PillTab {
    @extend .mr-3, .p-2;

    font-weight: 500;
    color: var(--text-color-secondary);

    &:hover {
      color: var(--link-color);
    }

    @include small-size {
      white-space: nowrap;
    }
  }

  .Tab.PillTab--selected {
    color: var(--link-color);
    background-color: var(--dynamic-color-link-color-lightest--link-color);
    border-radius: var(--square-border-radius);

    &:hover {
      color: var(--link-color);
    }
  }

  .Tab.PageTab {
    color: var(--text-color-secondary);
    font-weight: 400;
    padding: 8px 24px 5px;
    border-bottom: 1px solid var(--border-color);

    &:hover {
      color: var(--link-color);
    }
  }

  .Tab.PageTab--selected {
    color: var(--link-color);
    font-weight: 600;
    padding: 8px 24px 4px;
    border-bottom: solid 2px var(--highlight-color);

    &:hover {
      color: var(--link-color);
    }
  }
}
