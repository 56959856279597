.VideoRecorder {
  @extend .mb-2;
  position: relative;

  .recording-controls {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  video {
    @extend .w-100, .h-100;
  }

  .video-placeholder {
    @extend .d-flex, .justify-content-center, .align-items-center;
    width: 100%;
    min-height: 300px;
    background-color: var(--btn-muted-color);

    svg {
      width: 62px;
      height: 62px;
      fill: var(--btn-primary-color);

      path:nth-of-type(2) {
        stroke: var(--btn-primary-color);
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: var(--dynamic-color-button-primary-hover--btn-primary-color);

        path:nth-of-type(2) {
          stroke: var(--dynamic-color-button-primary-hover--btn-primary-color);
        }
      }
    }
  }
}
