main.PlatformAnalyticsPage {
  @include default-page-grid;

  header {
    grid-column: span 12;

    .ModuleTabs {
      border-bottom: 1px solid var(--border-color);
    }
  }

  .DisabledModule {
    svg {
      height: 216px;
      width: 197px;
    }
  }
}
