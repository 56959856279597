.TabSelector {
  width: 100%;
  overflow: hidden;

  li.nav-item.NoResults a {
    opacity: 0.3;
    pointer-events: none;
  }

  .nav-tabs {
    position: relative;
    border: 0;
    &:after {
      content: '';
      display: block;
      background: rgba(0, 0, 0, 0.1);
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    @include small-size {
      overflow-x: scroll;
      overflow-y: hidden;
      flex-wrap: nowrap;

      .nav-item {
        white-space: nowrap;
      }
    }
  }

  .nav-link {
    color: $black;
    background: transparent !important;
  }

  .nav-link {
    border: 0;
    font-weight: 500;

    &.active,
    &:hover,
    &:focus {
      border: 0;
    }

    &.active {
      color: var(--highlight-color);
    }
  }

  table thead th {
    border-top: none;
  }

  .nav-link:not(.active) {
    @extend .text-color-secondary;
  }
}
