.ReactSelectValues {
  .Select-control {
    border-bottom: 1px solid var(--border-color);

    &:hover {
      border-bottom: 1px solid var(--highlight-color);
    }
  }

  .Select-menu-outer {
    border-top: 1px solid var(--highlight-color);
  }

  .Select-value-icon {
    display: flex;
    align-items: center;
    padding-bottom: 0.3rem !important;
  }

  .Select-value-icon,
  .Select-value-label {
    height: 27px;
  }

  .Select-value {
    height: 30px;
    display: inline-flex;
    background-color: transparent;
    border-radius: var(--square-border-radius);
    border: solid 1px var(--border-color);
    margin-right: 5px;
    margin-bottom: 2px;
    margin-top: 2px;
    line-height: unset;
  }

  .Select--single .Select-value {
    border: none !important;
  }

  .Select-value-label {
    color: var(--text-color-primary);
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  .Select-value-icon {
    order: 1;
    color: var(--text-color-secondary);
    border-right: none;
    font-size: 1.2rem;
    padding: 3px;

    &:hover {
      background-color: transparent;
    }
  }
}
