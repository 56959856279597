.ImpersonationHistoryTable {
  .ClearyReactSelect__menu {
    z-index: 9999;
  }

  .react-datepicker-popper {
    z-index: 9999;
  }

  .DateTimePicker {
    min-width: 250px;
  }
}
