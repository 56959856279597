.MyTicketsPage {
  min-height: calc(100vh - var(--nav-height));
  background-color: $white-pure;
  border-top: 1px solid var(--base-layout-border-color);

  .TicketListPage__CreateNewButton {
    border-radius: 7px !important;

    svg {
      fill: $white-pure;
      width: 14px;
      position: relative;
      top: -1px;
    }
  }
}

