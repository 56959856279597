.btn-tag {
  color: var(--link-color);
  background-color: var(--dynamic-color-link-color-lightest--link-color);
  border-radius: var(--square-border-radius);
  font-weight: 600;

  &:hover {
    color: var(--link-color);
    background-color: var(--dynamic-color-link-color-lighter--link-color);
  }

  svg {
    fill: var(--link-color);
  }
}

.btn-outline-primary {
  color: var(--link-color);
  border-color: var(--link-color);

  &:hover,
  &:active,
  &:focus {
    color: var(--link-color) !important;
    border-color: var(--link-color) !important;
    background-color: var(--white-pure) !important;
  }
}

.btn-link {
  color: var(--link-color);
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }

  svg {
    fill: var(--link-color);
  }

  &:hover {
    color: var(--dynamic-color-link-text--link-color);

    svg {
      fill: var(--dynamic-color-link-text--link-color);
    }
  }
}

.btn-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .isvg {
    display: flex;
    align-items: center;
  }

  svg {
    width: 16px;
    height: 16px;

    fill: currentColor;
  }
}

.btn-ai {
  background: linear-gradient(258.12deg, var(--ask-ai-end-color) 10.82%, var(--ask-ai-start-color) 104.37%);
}
