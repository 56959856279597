.PageHierarchyBreadcrumbs {
  .Page-parent-category {
    color: var(--text-color-secondary);
  }

  .Page-breadcrumb-separator {
    font-size: 1.5rem;
    color: var(--text-color-secondary);
  }
}
