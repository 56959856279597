.ActionForm {
  .nav-tabs,
  .nav-tabs .nav-link {
    border: 0 !important;
  }

  .nav-tabs .nav-link {
    @extend .text-small;
    color: var(--text-color-dark-muted) !important;
    font-weight: normal;
    padding: 0.5rem 1rem;
    padding-right: calc(1rem + 2px);
  }

  .nav-tabs .nav-link.active {
    background-color: var(--nav-tabs-background-color) !important;
    color: var(--nav-tabs-active-color) !important;
  }

  .nav-tabs a[data-rb-event-key=separator].nav-link {
    padding-left: 8px !important;
    padding-right: 8px !important;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .VariablesSelect {
    svg {
      width: 16px;
      height: 16px;
    }

    .selected {
      background-color: var(--nav-tabs-background-color) !important;
      color: var(--nav-tabs-active-color) !important;
    }
  }

  code {
    font-size: 87.5%;
  }

  pre {
    border-radius: var(--square-border-radius);
    color: var(--text-color-secondary);
    font-family: JetBrainsMono, monospace;
    padding: 0.75rem 1rem;
    border: 1px solid var(--border-color);
  }
}
