.PendingRequestsModal {
  .modal-content {
    margin-top: 100px;
    border: none;
  }

  .Content {
    padding: 0 20px 20px;

    h4 {
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 7px;
      margin-bottom: 20px;
    }

    .PendingRequest {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 15px;
      align-items: center;
      padding: 15px 0px;
      min-height: 60px;
      border-bottom: 1px solid var(--border-color);

      img {
        height: 30px;
        width: 30px;
        border-radius: var(--profile-image-border-radius);
      }

      .User {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        grid-column: span 3;

        @include up-to-large-size {
          grid-column: span 12;
        }
      }

      .Justification {
        grid-column: span 6;

        @include up-to-large-size {
          grid-column: span 12;
        }
      }

      .ActionButtons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        grid-column: span 3;

        @include up-to-large-size {
          grid-column: span 12;
          justify-content: flex-start;
        }
      }
    }
  }
}
