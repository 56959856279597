.RoundedAnimatedButton {
  padding: 0;
  transition: width 0.3s ease;
  min-width: auto !important;
  border-radius: 999px !important; // Doesn't matter the value, as long it's bigger than the button height

  .text-container {
    display: inline-block;
    width: 0;
    margin-right: 0;
    overflow: hidden;
    transition: width 0.3s ease;
  }

  .text-container-inner {
    float: right;
    padding-right: 10px;
    text-wrap: nowrap;
  }
}
