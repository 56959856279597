.CelebrationCard {
  display: block;

  .CelebrationCard-hero {

    &,
    img {
      height: 115px;
    }
  }

  .birthday-celebration-image {
    width: 100%;

    svg {
      width: 100%;
      height: 100%;
    }

    .illustration {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80%;
    }

    .balloon-1 {
      width: 10%;
      left: 5%;
      bottom: 10%;
    }

    .balloon-2 {
      width: 8%;
      left: 14%;
    }

    .balloon-3 {
      width: 10%;
      right: 12%;
    }

    .balloon-4 {
      right: 5%;
      bottom: 8%;
      width: 8%;
    }
  }

  .upcoming-birthday-celebration-image, .work-anniversary-celebration-image {
    &, svg {
      width: 100%;
      height: 100%;
    }

    .illustration {
      width: 100%;
      height: 80%;
      align-self: end;
    }
  }

  .upcoming-work-anniversary-celebration-image {
    &, svg {
      width: 100%;
      height: 100%;
    }

    .person-1 {
      position: absolute;
      bottom: 0;
      height: 80%;
      left: 100px;
    }

    .person-2 {
      position: absolute;
      bottom: 0;
      height: 80%;
      right: 20px;
      width: initial;
    }
  }

  .new-employee-welcome-illustration {
    width: 100%;
    height: 80%;
    align-self: end;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .CelebratedPersonThumb {
    @extend .ml-3;
    position: absolute;
    top: 100%;
    margin-top: -46px;
    z-index: $zindex-one;

    .img-padding {
      border-radius: 50%;
      overflow: hidden;
      padding: 2px;
      background-color: var(--white-pure);
    }

    img {
      border-radius: 50%;
      width: 90px;
      height: 90px;
    }

    .EmojiWrapper {
      top: 63%;
      left: 58%;
      background: var(--white-pure);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-size: 17px;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
    }
  }

  .title {
    margin-top: 50px;
    font-size: 18px;
  }

  .sign-by-label {
    font-size: 13px;
  }
}
