.PageFeedback {
  grid-column: span 6;

  .Label {
    top: 35px;
  }

  .hasNoFeedback {
    top: 25px;

    svg {
      path {
        fill: var(--light-mid-gray) !important;
      }
    }

    h3 {
      color: var(--light-mid-gray)
    }
  }

  .ThumbsUp, .ThumbsDown {
    @extend .d-flex, .align-items-center, .justify-content-center;

    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--dynamic-color-primary-color-lightest--highlight-color);

    svg {
      width: 26px;
      height: 26px;
    }
  }

  .GrayBackground {
    background-color: var(--lightest-gray);
  }

  .ThumbsUp {
    svg {
      path {
        fill: var(--highlight-color);
      }
    }
  }

  .ThumbsDown {
    svg {
      path {
        fill: var(--dynamic-color-primary-color-lighter--highlight-color);
      }
    }
  }

  .EmptyStateBody {
    padding: 0px !important;
  }

  .NoFeedbackText {
    top: 40px;
  }
}
