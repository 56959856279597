section.KeyLinks {
  .Icon {
    width: 16px;
    height: 16px;

    display: block;
    line-height: 0;

    svg {
      fill: var(--dynamic-color-link-text--link-color);
    }

    &.empty-state {
      svg {
        fill: var(--empty-state-text);
      }
    }
  }

  .links-list {
    max-height: 190px;
    overflow-y: auto;
  }

  .link-item {
    span.link-name {
      color: var(--dynamic-color-link-text--link-color);
      font-weight: var(--link-font-weight);
      width: 100%;
    }

    .visible-on-hover {
      display: none;
    }

    .clickable-icon {
      cursor: pointer;
    }

    &:hover {
      background-color: var(--dropdown-hover-background-color);

      .visible-on-hover {
        display: flex;
      }
    }
  }

  .add-link {
    color: var(--empty-state-text);
    height: 1.6rem;

    &:hover {
      color: var(--dynamic-color-link-text--link-color);
      cursor: pointer;

      .PlusIcon svg {
        fill: var(--dynamic-color-link-text--link-color);
      }
    }
  }

  &.list-visible {
    .card-body {
      padding: 1.25rem 0.75rem;

      h6.title, .add-link, .link-item {
        padding: 0 0.5rem;
      }
    }
  }
}
