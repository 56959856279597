.UsersCurrentlyEditing {
  .plusUsersContainer {
    height: 35px;
    width: 35px;
  }
}

.UserCurrentlyEditing {
  img {
    overflow: hidden;
    border: 2px solid var(--border-color);
    border-radius: var(--profile-image-border-radius);
    width: 35px;
    height: 35px;
    margin: 0 2px;
  }
}

.UsersCurrentlyEditingPopover {
  max-height: 200px;
  width: max-content;
  overflow-y: auto;
}
