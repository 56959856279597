.AppLauncher-popover {
  width: 300px;
  box-shadow: var(--card-box-shadow);

  header {
    border-bottom: 1px solid var(--border-color);

    svg {
      @extend .ml-2;

      &:hover {
        path {
          fill: var(--nav-text-active-color);
        }
      }

      path {
        fill: var(--text-color-secondary);
      }
    }

    .Selected {
      svg {
        path {
          fill: var(--nav-text-active-color);
        }
      }
    }
  }

  .AppList {
    @extend .d-flex, .flex-column;

    max-height: 350px;
    overflow-y: scroll;

    .AppListItem {
      height: 50px;
    }
  }

  .AppGrid {
    max-height: 250px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 7px;

    svg {
      height: 12px;
      width: 12px;
    }

    .FavoriteButton {
      @extend .p-0;

      min-height: 12px;
      height: 12px;
      min-width: 12px;
      width: 12px;
      position: relative;
      top: 0px;
      right: 30px;
    }
  }

  .AppGridItem, .AppListItem {
    &:hover {
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);

      .NotFavorite {
        @extend .d-block;
      }
    }
  }

  .footer {
    border-top: 1px solid var(--border-color);
  }
}
