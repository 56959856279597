div.EmployeeJourneysPage {
  .AdminHeader {
    flex-direction: row;

    .journeys-editable-container {
      width: 60%;
    }

    .user-information-container{
      width: 40%;
    }

    .EmployeeThumbnailPhoto {
      width: 24px;
      height: 24px;
    }

    .buttons-container {
      .pencilIcon, .personIcon {
        display: none;
      }
    }

    @include up-to-medium-size {
      flex-direction: column;

      .buttons-container {
        .btn-secondary {
          min-width: auto;
          padding: 0.375rem 0.375rem;
        }

        span {
          display: none;
        }

        .pencilIcon, .personIcon {
          display: initial;

          svg {
            width: 20px;
            height: 20px;
            fill: var(--btn-primary-color);
          }
        }
      }

      .PrimaryPhoto {
        margin-right: 0px !important;
        margin-bottom: 1.5rem !important;
      }

      .info-and-journeys-container {
        flex-direction: column;

        .journeys-editable-container {
          width: 100%;
          margin-top: 20px;

          .EditableContent-Display.canEdit {
            padding: 0px;
          }
        }

        .user-information-container{
          width: 100%;
        }
      }
    }
  }
}
