div.StepCompletionStatusIconAndTooltip {
  .red-exclamation {
    line-height: 30px;
    font-size: 20px;
  }

  .ClockIcon {
    svg {
      width: 20px;
      height: 20px;
      fill: var(--gray);
      margin-top: -2px;
    }
  }

  .TaskableDetailsButtonAndModal {
    input {
      margin-right: 0px !important;
    }
  }
}

.StepPopover {
  background-color: var(--text-color-primary);
  z-index: $zindex-dropdown;
  max-width: 250px;

  .arrow {
    &::after {
      border-bottom-color: var(--text-color-primary) !important;
    }
  }

  svg {
    fill: var(--white-pure);
  }
}



.TaskablePopover {
  background-color: var(--white-pure);
  z-index: $zindex-dropdown;
  max-width: 300px;

  .taskable-information-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 5px;

    .overdue {
      color: var(--error-color);
    }
  }

  svg {
    fill: var(--text-color);
  }
}
