div.ProgressGuidanceCard {
  width: 100%;

  div.card-body {
    padding: 0;

    div.progress-steps {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 8px;

      span.step-container::before {
        content: "";
        border-radius: 50%;
        background-color: var(--light-gray);
        width: 8px;
        height: 20px;
        display: block;
        position: absolute;
        z-index: $zindex-zero;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      span.step-container {
        font-weight: 400;
        font-size: 13px;
        color: var(--text-color-secondary);
        padding: 8px;
        padding-left: 0px;
        border-bottom: 6px solid var(--light-gray);

        &.completed {
          color: var(--link-color);
          border-bottom: 6px solid var(--link-color);
        }

        &.active {
          color: var(--link-color);
          border-bottom: 6px solid var(--dynamic-color-button-primary-disabled--btn-primary-color);
        }

        .step-title {
          z-index: $zindex-base;
          position: relative;
        }
      }
    }

    div.guidance {
      padding: 18px 20px 20px;
    }
  }
}
