.SearchResultsPage {
  h3 {
    font-size: inherit;
  }

  .SearchHighlight {
    em {
      padding: 1px;
      color: $search-match-highlight-font-color;
      background: $search-match-highlight-bg-color;
      opacity: 0.8;
      font-style: normal !important;
    }
  }

  .TabSelector--content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .Article-Date,
  .Group-Date {
    cursor: default;
  }

  .ArticleResult-Link:hover {
    .Article-Label {
      color: var(--highlight-color);
    }

    .Article-Type {
      svg > g {
        stroke: var(--highlight-color);
        fill: var(--highlight-color);
      }

      svg {
        & line,
        & polygon,
        & polyline,
        & path,
        & rect {
          stroke: var(--highlight-color);
        }
      }
    }
  }

  .ArticleResult,
  .GroupResult,
  .PageResult {
    flex: 0 calc(33.333% - 1em);
    margin: 1em 1em 1em 0;

    .Article-Type svg {
      height: 16px;
      width: 16px;
    }

  }

  .Article-Label,
  .Group-Label,
  .Page-Label {
    margin-top: -0.4em;
  }

  .EmployeeMiniCard:hover {
    background-color: white;
  }

  .PeopleResult_List {
    & > a {
      flex: 0 calc(33.333% - 1em);
      margin: 1em 1em 1em 0;
    }
  }
}

.FileOwnerPhoto {
  width: 20px;
}

.GoogleFileCard {
  width: 270px;
}

.GoogleFileIcon {
  width: 20px;
}

.GoogleDriveResultsRow {
  margin-left: -6px;
}
