.WorkflowEditPage {
  &__react-flow-wrapper {
    height: 100%;
    width: 100%;

    .button-edge__label {
      position: absolute;
      pointer-events: all;
      transform-origin: center;
    }

    .button-edge__button {
      width: 30px;
      height: 30px;
      border: 0;
      background: #fbfcff;
      cursor: pointer;

      svg path {
        fill: var(--link-color);
      }

      .isvg:hover {
        background-color: var(--tile-background-color);
        border-radius: 50%;
        padding: 1px 1px;
      }
    }
  }

  .default-dropdown {
    top: 6px;
    right: 2px;
  }

  .saveIcon {
    svg path {
      fill: var(--white-pure);
    }
  }

  .WorkflowEditPage__sidebar {
    background-color: var(--white-pure);
    border-left: 1px solid var(--border-color);

    .description-label {
      display: flex;
      align-items: center;

      svg path {
        fill: var(--text-color-secondary);
      }
    }
  }

  .WorkflowEditPage__draft-warning {
    background-color: #E5EEFF;
    border-radius: 8px;
    border: 1px solid #DDE6FD;
    padding: 10px;
    color: var(--link-color);
    font-weight: 500;

    svg path {
      fill: var(--text-color-secondary);
    }
  }
}
