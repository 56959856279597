.WorkspaceActionsDropdownMenu {
  .PencilIcon,
  .SwapPeopleIcon,
  .PersonsIcon {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .DeletePageIcon {
    path,
    g {
      stroke: var(--status-label-red-text-color);
      fill: var(--status-label-red-text-color);
    }
  }
}
