.TicketListPage {
  .TableLoadingIndicator {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .EmployeeSearch {
    min-width: 200px;
  }

  .white-bg-table {
    thead th, tbody td {
      padding: 1rem 1rem;
      border: none;
    }

    tr {
      border-bottom: 1px solid var(--base-layout-border-color);
    }
  }
}

.TicketListPage__header {
  border-bottom: 1px solid var(--base-layout-border-color);
  z-index: $zindex-two;
  position: relative;
}

.TicketStatusLabel {
  min-width: 90px;

  &.small {
    font-size: 0.8rem;
    max-width: 70px;
    padding-top: 0 !important;
  }
}
