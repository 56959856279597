.EventFilter {
  .EventFilterLink {
    font-weight: 400;
    cursor: pointer;

    &.isActive {
      font-weight: 600;
      color: var(--link-color);
      background-color: var(--dynamic-color-primary-color-lightest--highlight-color);
    }
  }
}
