.EmployeeAudienceSelector {
  .NumberInput {
    text-align: center;
    padding: 0 0.5rem;
    max-width: 70px;
  }

  .WhenTargetingChanges {
    .AudienceSelectorFields {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 1.5rem;
      grid-row-gap: 2.5rem;
    }
  }
}
