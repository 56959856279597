.UserImportsList.white-bg-table {
  table-layout: fixed;
  width: 100%;

  // status
  th:nth-child(2) {
    width: 10%;
  }

  // processed
  th:nth-child(4) {
    width: 9%;
  }

  // failed
  th:nth-child(5) {
    width: 9%;
  }

  // new
  th:nth-child(6) {
    width: 9%;
  }

  // changed
  th:nth-child(7) {
    width: 9%;
  }

  // unchanged
  th:nth-child(8) {
    width: 9%;
  }
}

.PreboardingCsvUploadButton {
  min-width: 220px;
}

.IntegrationSyncButton {
  min-width: 180px;
}

.DateFormatPicker {
  .SelectDateTooltipContainer {
    margin-top: -2px;
  }

  .InfoIcon {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--text-color-secondary);
    }
  }
}
