.JourneysEstimatedUsers {
  background-color: rgba(14, 0, 60, 0.04);
  border-radius: 4px;
  padding: 10px;
  @include up-to-medium-size {
    flex-direction: column;
    justify-content: flex-start;
    .RoundedAvatars {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}
