.PageFeedbackNav {
  width: var(--right-nav-width);
  transition: right 0.5s ease-in-out;
  background: var(--left-navbar-background-color);
  overflow-y: auto;
  gap: 10px;

  .CloseIcon {
    transform: scale(0.8);
    stroke: var(--mid-gray);

    &:hover {
      stroke: var(--dark-gray);
    }
  }
}
