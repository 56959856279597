main.PageAnalyticsPage {
  @include default-page-grid;

  row-gap: 30px;

  header {
    grid-column: span 12;
  }

  .ReadTime {
    grid-column: span 6;
  }

  .VideoAnalyticsWrapper {
    grid-column: span 12;
  }

  .ClearyReactSelect {
    min-width: 150px;
  }
}

main.PopularPagesPage {
  .ClearyReactSelect {
    min-width: 150px;
  }
}
