.AcknowledgementSection {
  background-color: var(--dynamic-color-link-color-lightest--link-color);
  border-radius: var(--square-border-radius);

  &.acknowledged {
    background-color: inherit;
  }

  &.overdue {
    background-color: var(--dynamic-color-danger-color-lightest--danger-color);
  }
}
