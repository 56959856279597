.RenameWorkspaceModal {
  .EmojiOrPlaceholder {
    width: 38px;
    line-height: 38px;
    font-size: 36px;

    svg {
      width: 38px;
      height: 38px;
      vertical-align: top;
    }
  }
}
