main.SearchTab {
  @include default-page-grid;

  @extend .mb-4;

  grid-column: span 12;
  row-gap: 30px;

  .SingleStatTile {
    grid-column: span 3;
  }

  .SearchTables {
    grid-column: span 6;

    @include up-to-medium-size {
      grid-column: span 12;
    }
  }

  .EmptyStateBody {
    svg {
      height: 190px;
      width: 172px;
    }
  }
}
