span.AdminBanner {
  color: var(--white-pure);

  a, .banner-link {
    color: var(--white-pure);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .banner-close {
    width: 18px;
    height: 18px;

    svg {
      width: 18px;
      height: 18px;
      fill: var(--white-pure);
    }
  }

  &.banner-red {
    background-color: var(--admin-banner-red-background-color);
  }

  &.banner-green {
    background-color: var(--admin-banner-green-background-color);
  }

  &.banner-orange {
    background-color: var(--admin-banner-orange-background-color);
  }

  &.banner-blue {
    background-color: var(--admin-banner-blue-background-color);
  }

  &.banner-yellow {
    color: var(--admin-banner-yellow-color);
    background-color: var(--admin-banner-yellow-background-color);

    a, .banner-link, .banner-close {
      color: var(--admin-banner-yellow-color);
    }
  }
}
