.ViewTicketSidebar {
  .Sidebar {
    padding: 0 !important;
    width: 1122px !important;
    max-width: 90vw;
  }

  .Sidebar__header {
    margin-bottom: 0 !important;
    height: 0 !important;

    .close-button {
      z-index: $zindex-dropdown;
    }
  }

  .RootSidebarContent {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }

  .ViewTicketSidebarLeftColumn {
    padding: 30px 24px;
  }

  .ViewTicketSidebarRightColumn {
    width: 365px;
    max-width: 40%;
    flex-shrink: 0;

    .CollapsibleCard {
      @extend .bordered-bottom;

      box-shadow: none;
      padding: 30px 24px !important;
      border-radius: 0;

      .CollapsibleCardContent,
      .CollapsibleCardHeader {
        padding: 0 !important;
      }

      h6 {
        @extend .text-small;
      }
    }
  }

  .SidebarCloseButton {
    right: 20px;
    top: 20px;
  }

  .TicketRequester,
  .TicketDetails,
  .TicketEmployee {
    @extend .bordered-bottom;

    padding: 30px 24px;
  }

  .TicketDropdown {
    right: 24px;
  }

  .MessagingIcon {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
