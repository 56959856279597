.DocumentsTable {
  .checkbox-column {
    padding-left: 10px;
    padding-right: 10px;
    width: 0;
  }

  .document-column {
    padding-left: 0px;
  }

  td.message {
    padding-left: 20px;
  }

  a {
    font-weight: normal;
  }

  tr:hover {
    background-color: var(--tile-background-color);

    a {
      color: var(--highlight-color);
    }
  }

  .external-link-icon {
    svg {
      height: 17px;
      position: relative;
      top: -2px;
    }

    &:hover {
      svg {
        fill: var(--link-color);
      }
    }
  }
}
