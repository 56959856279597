.SelectOnboardingBuddyPage {
  background-color: var(--background-color);

  header {
    background-color: var(--white-pure);
    box-shadow: var(--card-box-shadow);

    section {
      @include default-page-grid;

      h1, p {
        grid-column: span 12;
      }

      .NewHireSelect {
        grid-column: span 4;

        @include large-size {
          grid-column: span 5;
        }

        @include medium-size {
          grid-column: span 6;
        }

        @include small-size {
          grid-column: span 12;
        }
      }
    }
  }
}
