.MergeUsersModal {
  main {
    display: grid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    align-items: center;
  }

  .UserDetails {
    background-color: rgba(14, 0, 60, 0.04);
  }

  .UserField {
    height: 70px;
  }
}
