@mixin articleTypeColor($color: --text-color-secondary, $hover-color: --highlight-color) {
  color: var(#{$color});

  svg {
    stroke: var(#{$color});
    fill: var(#{$color});
  }

  &:hover {
    color: var(#{$hover-color});

    svg>g {
      stroke: var(#{$hover-color});
      fill: var(#{$hover-color});
    }

    svg {
      & line,
      & polygon,
      & polyline,
      & path,
      & rect {
        stroke: var(#{$hover-color});
      }
    }
  }
}

.Article-Type {
  // the svg element is injected inside of a span with the class '.isvg'
  // by the SVG component from the react-inlinesvg library
  .isvg {
    width: 1.2em;
    height: 1.2em;
  }

  svg {
    width: 1.2em;
    height: 1.2em;
  }

  @include articleTypeColor(--text-color-secondary, --highlight-color);

  .isvg {
    display: flex;
  }
}

.ClearyReactSelect__option--is-focused {
  .Article-Type {
    @include articleTypeColor(--highlight-color, --highlight-color);
  }
}

.ClearyReactSelect__option--is-selected {
  .Article-Type {
    @include articleTypeColor(--white-pure, --white-pure);
  }
}

.ClearyReactSelect__option--is-disabled {
  .Article-Type {
    @include articleTypeColor(--text-color-muted, --text-color-muted);
  }
}
