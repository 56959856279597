.ExternalSourcesPage {
  .AvailableSources {
    display: flex;
    flex-direction: row;
    gap: 14px;

    .ConnectExternalSource {
      height: 116px;
      width: 132px;
      padding: 24px 14px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--border-color);
      border-radius: var(--square-border-radius-large);

      &:hover {
        cursor: pointer;
        border-color: var(--btn-primary-color);
      }

      svg {
        width: 26px;
        height: 26px;
      }
    }
  }
}
