.PageDropdown {
  .Card-dropdownMenuItem {
    height: 38px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;

      fill: var(--text-color-primary);

      path {
        fill: var(--text-color-primary);
      }
    }

    &.disabled {
      div {
        @extend .text-muted;

        svg {
          fill: var(--text-color-muted);

          path {
            fill: var(--text-color-muted);
          }
        }
      }
    }

    .DeletePageIcon {
      svg {
        fill: var(--danger-color);

        path {
          fill: var(--danger-color);
        }
      }
    }

    .ChangeWorkspaceIcon {
      svg {
        fill: none;
        stroke: var(--text-color-primary);

        path {
          fill: none;
          stroke: var(--text-color-primary);
        }
      }
    }
  }

  .line-break {
    border-top: 1px solid var(--light-gray);
  }
}
