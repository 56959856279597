.QNASettings {
  // COMMON
  .ClearyToggleButtonGroup {
    &.isDisabled {
      .btn-group-toggle {
        cursor: not-allowed;
      }

      label.btn-toggle {
        pointer-events: none;
      }

      .btn-toggle.active {
        opacity: 0.5;
      }
    }

    label {
      flex: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.focus {
        box-shadow: none;
      }
    }

    .btn-group.btn-group-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5rem 0;
    }

    .btn-toggle {
      border: solid 1px var(--border-color) !important;
      color: var(--text-color-secondary);
    }

    .btn-toggle.active {
      background-color: var(--highlight-color) !important;
      border: solid 1px var(--highlight-color) !important;
      color: var(--white);
    }
  }

  // MODERATORS SECTION
  .Moderators {

  }
}
