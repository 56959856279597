div.QuestionCard {
  .card-title {
    margin-bottom: 0px;
  }

  .QuestionCardActions {
    .ClearyReactSelect {
      min-width: 200px;
    }
  }

  div.EditQuestionContent{
    border-top: 1px solid var(--light-gray);

    .advanced-settings-container {
      border-top: 1px solid var(--light-gray);

      svg {
        width: 28px;
        height: 28px;
        fill: var(--highlight-color);
      }
    }
  }

  .QuestionOption {
    .question-option-value-input {
      min-width: 500px;
    }
  }
}

.isDraggingQuestionOption {
  display: flex;
  pointer-events: auto !important;
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
  z-index: $zindex-modal-backdrop;
  width: 100%;

  input[type="text"] {
    min-width: 500px;
  }
}
