.TriggerOrDateSelect {
  display: flex;
  flex-direction: row;

  .TimezoneSelect {
    label {
      @extend .text-small, .text-secondary;
    }

    .ClearyReactSelect {
      min-width: 270px;
    }
  }

  @include up-to-large-size {
    display: flex;
    flex-direction: column;
  }
}

.task-schedule-grid {
  display: grid;
  grid-template-areas:
      "header header"
      "body sidebar";

  .task-schedule-grid-header {
    grid-area: header;
  }
  
  .task-schedule-grid-body {
    grid-area: body;
  }
  
  .task-schedule-grid-sidebar {
    grid-area: sidebar;
  }
}
