.ArticleStatsEmailEngagementPage {
  .email-status-select {
    min-width: 150px;
  }

  .white-bg-table {
    z-index: $zindex-zero;

    thead {
      th {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    tbody {
      .EmployeeMiniCard {
        background: none;
      }

      tr {
        &:hover {
          background-color: var(--background-color);
        }
      }
      td {
        padding: 0px 20px;
      }
    }
  }
}
