.TicketActivity {
  .TicketActivityItem__label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .TicketActivityItem__items {
    position: relative;
    padding-left: 22px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 9px;
      height: calc(100% - 13px);
      width: 1px;
      background-color: var(--border-color);
    }

    &__item {
      position: relative;
      padding-left: 6px;

      svg, isvg {
        width: 14px;
        height: 14px;
        display: block;
      }

      &::before {
        content: '';
        position: absolute;
        width: 13px;
        left: -13px;
        height: 10px;
        top: calc(50% - (10px / 2));
        border-left: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        border-bottom-left-radius: 8px;
        transform: translate(0, -50%);
      }
    }
  }

  .TicketActivityItemIcon {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 12px;
    }

    &.gray {
      color: var(--status-label-gray-text-color);
      background-color: var(--status-label-gray-background-color);

      svg {
        fill: var(--status-label-gray-text-color);
      }
    }

    &.red {
      color: var(--status-label-red-text-color);
      background-color: var(--status-label-red-background-color);

      svg {
        fill: var(--status-label-red-text-color);
      }
    }

    &.green {
      color: var(--status-label-green-text-color);
      background-color: var(--status-label-green-background-color);

      svg {
        fill: var(--status-label-green-text-color);
      }
    }

    &.blue {
      color: var(--status-label-blue-text-color);
      background-color: var(--status-label-blue-background-color);

      svg {
        fill: var(--status-label-blue-text-color);
      }
    }

    &.purple {
      background-color: var(--ask-ai-background-color);
    }
  }
}
