.stats-info-tooltip {
  svg {
    fill: var(--text-color-secondary);
  }
}

.stat {
  &--bordered {
    @extend .bordered, .p-3;
  }

  &--hoverable:hover {
    background: var(--lighter-background-color);
    box-shadow: var(--card-box-shadow);
  }

  .stat-label {
    @extend .text-small, .d-block, .text-secondary;
  }

  .stat-value {
    @extend .d-block;
  }

  .stat-icon {
    @extend .p-2;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--square-border-radius);
    background: var(--light-background-color);

    svg {
      width: 16.368px;
      height: 18.333px;
      flex-shrink: 0;
      color: var(--highlight-color);
    }
  }

  .legend-square {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: var(--highlight-color);
    margin-right: 10px;
    margin-top: 10px;
  }
}
