.CommentsWidget--Wrapper {
  width: 100%;

  .CommentsWidget--Toggle {
    &:hover {
      @extend .pill-button-active;
    }
  }

  .CommentsWidget--TiptapEditor {
    background-color: var(--comments-input-background-color);
    padding: 12px;

    .TiptapView {
      padding-right: 26px;
    }
  }

  .see-more-comments {
    font-size: 0.87em;
  }
}

.CommentsWidget--Comments {
  & > div {
    border-left: solid 2px var(--comments-input-background-color);
  }
}

.LikesAndComments {
  .CommentsWidget--Comments {
    @include small-size {
      // This is more than 100% when in small screen because we move to the right 40px
      // so it goes under the like widget. By doing this we make sure that the comments
      // still go to the end of the screen.
      width: calc(100% + var(--like-widget-width));
      right: var(--like-widget-width);
    }
  }
}
