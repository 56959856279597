@mixin compact-targeting-rules-selector {
  .RuleSelect {
    flex-wrap: wrap;
  }

  .RuleKeySelect {
    min-width: 120px;
  }

  .OperatorSelect {
    min-width: 80px;
  }

  .ClearyReactSelect__placeholder {
    font-size: 85%;
  }

  .ClearyReactSelect__single-value {
    font-size: 85%;
  }

  .ClearyReactSelect__option {
    font-size: 85%;
  }
}

.AudienceRulesSelector {
  .TrashIcon {
    width: auto;
    height: 20px;
  }

  .RuleKeySelect {
    min-width: 200px;
  }

  .OperatorSelect {
    min-width: 100px;
  }

  .or-rule-group {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: calc(100% + 10px);

    > * {
      margin-left: 5px;
      margin-right: 5px;
    }

    &:nth-child(even) {
      background-color: var(--lightest-gray);
    }
  }

  &.compact {
    @include compact-targeting-rules-selector;
  }

  @include up-to-medium-size {
    @include compact-targeting-rules-selector;
  }

  .DeleteRuleButton {
    &.disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }

  &.hasInlineAddRule {
    .RuleSelect {
      .AddMoreButton {
        visibility: hidden;
      }

      &:last-of-type {
        .AddMoreButton {
          visibility: visible;
        }
      }
    }

    &.noRuleSelected {
      .OperatorSelect, .FieldValuesSelect, .DeleteRuleButton, .AddMoreButton {
        display: none !important;
      }
    }
  }
}
