.Notifications-bell {
  cursor: pointer;
  position: relative;
  display: flex;

  .NotificationBellIcon {
    width: 26px;
  }

  svg {
    fill: var(--nav-text-active-color);
  }
}

.Notifications-popover {
  box-shadow: var(--card-box-shadow);
}

.Notifications-count {
  background-color: var(--danger-color);
  color: white;
  border-radius: 2em;
  font-size: .75em;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  right: -5px;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Notifications-list {
  width: 25em;
  border-top: 1px solid var(--border-color);
  max-height: 35em;
  overflow-y: scroll;

  @media (max-height: 600px) {
    max-height: 70vh;
  }
}